import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  BackIcon, HumidityIcon, ThermometerIcon,
  WaterLevelIcon
} from "../../assets/icons";
import { Sensor } from "../../assets/svg/index";
import { GlobalState } from "../../GlobalState";
import TodayReport from "../report/TodayReport";
import Banner from "../utils/banner";
import Loading from "../utils/loading";
import NotFound from "../utils/not_found";
import DashboardSensorBox from "./DashboadSensorBox";
import DoubleRangeSlider from "./DoubleRangeSlider";
import TankDetails from "./TankDetail";
import { editAccess } from "../utils/utils";
const Container = styled.div`
  margin: auto;
  width: 90%;

  @media (min-width: 61.313em) {
    width: 80%;
  }
`;
const SensorIconWrapper = styled.div`
  width: 35px;
  height: auto;
  margin-right: 8px;

  display: inline-block;
  @media (min-width: 61.313em) {
    width: 40px;
    margin: 0 20px;
  }
`;

// const IconWrapper = styled.div`
//   width: 30px;
//   height: 30px;
// `;
const BackIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    border: 2px solid var(--primary);
  }
  @media (min-width: 61.313em) {
    width: 55px;
    height: 55px;
  }
`;
// const Location = styled.div`
//   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #fff;
//   padding: 0.5rem 1rem;
//   border-radius: 20px;
// `;
const StyledInput = styled.input`
  width: 20%;
  padding: 0.6rem;
  font-size: 1rem;
  margin: 1rem 0;
  background-color: #e0e8e6;
  border-radius: 10px;
  @media (min-width: 61.313em) {
    width: 20%;
    margin: 0 1rem ;
  }
`;

const InputWrapper = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0.5rem 0.6rem;
  border-radius: 10px;
  margin: 1rem;
  @media (min-width: 61.313em) {
    flex-direction: row;
    width: 35%;
  }
`;
const StyledBtn = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--primary);
  padding: 0.6rem 1.3rem;
  border-radius: 20px;
  font-size: 1.2rem;
  margin: 0 0.5rem;
  color: var(--background);

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const DataWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  @media (min-width: 61.313em) {
  flex-direction: row;
  align-items: center;


  }
`;

export default function SensorData({ match }) {
  const state = useContext(GlobalState);
  const history = useHistory();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [ValidSensor, setValidSensor] = useState(true);
  const [values, setValues] = useState({ wait_time: "" });

  const sensor_id = match.params.sensor_id;
  const sensor_type = match.params.sensor_type;


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const submitMotorUpdate = async (e) => {
    const { wait_time } = values;

    const res = await axios.patch(
      `${process.env.REACT_APP_API}/sensor/${sensor_id}`,
      {
        wait_time: wait_time
      },
      {
        headers: { Authorization: state.token[0] },
      }
    );
    if (res.status === 200) {
      alert("Updated successfully.");
    }
  }

  useEffect(() => {
    async function fetchData(token) {
      try {
        const sensorData = await axios.get(`${process.env.REACT_APP_API}/sensor/data?sensor_id=${sensor_id}`,
          {
            headers: { Authorization: token[0] },
          }
        );

        if (sensorData) {
          setData(sensorData.data);
          setValues(
            { wait_time: sensorData.data.wait_time }
          )
          setLoading(false);
        }
      } catch (err) {
        setValidSensor(false);
        console.log(err);
      }
    }

    fetchData(state.token);
  }, [state.token, sensor_id]);


  if (!ValidSensor) return <NotFound />;

  if (loading) return <Loading />;
  return (
    <Container>
      <BackIconWrapper onClick={() => history.push("/")}>
        <BackIcon />
      </BackIconWrapper>

      <Banner
        title={
          <>
            <SensorIconWrapper>
              {data.type.includes("Temperature") ? (
                <ThermometerIcon />
              ) : data.type.includes("Water") || data.type.includes("Diesel") ? (
                <WaterLevelIcon />
              ) : (
                <HumidityIcon />
              )}
            </SensorIconWrapper>
            {sensor_type}
          </>
        }
        subtitle={"Here you can find the detailed info about the sensor."}
      >
        <Sensor />
      </Banner>

      <DataWrapper>

        {/* <div> */}
        {/* <Location>
            <IconWrapper>
              <LocationIcon />
            </IconWrapper>
            <h4>{data.location}</h4>
          </Location>

          {data.type.includes("Water") || data.type.includes("Diesel") ? (
            <WaterOperations sensor_id={sensor_id} token={state.token[0]} location={data.location} sensorType={data.type} />
          ) : null} */}

        {/* </div> */}
        {data.sensor_id && <DashboardSensorBox sensor_id={sensor_id} type={sensor_type} />}
        {/* <Container> */}
        {data.type.includes("Water Level") && <TankDetails

          dimension={data.dimension}
        />}
        {data.type.includes("Humidity") && (
          <>
            {sensor_type === 'Humidity' &&
              <DoubleRangeSlider
                name="Humidity Min/Max Setting"
                defautvalues={[
                  parseInt(data.lower_limit_2),
                  parseInt(data.upper_limit_2),
                ]}
                disabled={!editAccess(state.userAPI)}
                type={'humidity'}
                sensor_id={sensor_id}
              />}

            {
              sensor_type === 'Temperature' &&

              <DoubleRangeSlider
                name="Temperature Min/Max Setting"
                defautvalues={[
                  parseInt(data.lower_limit_1),
                  parseInt(data.upper_limit_1),
                ]}
                disabled={!editAccess(state.userAPI)}
                type={'temperature'}
                sensor_id={sensor_id}
              />
            }
          </>
        )}

        {data.type.includes("Water Level") && (
          <DoubleRangeSlider
            name="Min/Max Setting"
            defautvalues={[
              parseInt(data.lower_limit),
              parseInt(data.upper_limit),
            ]}
            disabled={!editAccess(state.userAPI)}
            sensor_id={sensor_id}
          />
        )}


        {sensor_type === 'Water Sense' &&
          (<InputWrapper onSubmit={(e) => {
            e.preventDefault();
            submitMotorUpdate(e);
          }
          }>
            <h4>Wait Time  (in Minutes)</h4>
            <StyledInput
              disabled={!editAccess(state.userAPI)}
              name="wait_time"
              value={values.wait_time}
              onChange={handleInputChange}
              required
              min={1}
              max={999}
              type="number"
            />
            <StyledBtn
              disabled={!editAccess(state.userAPI)}
              type="submit" value="Submit">
              Update
            </StyledBtn>
          </InputWrapper >)}



        {/* </Container> */}

      </DataWrapper>



      <TodayReport sensor_id={data.sensor_id} sensor_type={data.type} toShow={sensor_type} />
    </Container>
  );
}
