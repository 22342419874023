import axios from "axios";
import { default as React, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalState } from "../../GlobalState";
import UpdatePassword from "./UpdatePassword";
import UserData from "./UserData";
const Container = styled.div`
  @media (min-width: 61.313em) {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 30%;
    }
  }
`;

export default function Profile() {
  const state = useContext(GlobalState);
  const [loading, setLoading] = useState(true);
  const [userData, setuserData] = useState(false);

  async function fetchData(token) {
    try {
      const user = await axios.get(`${process.env.REACT_APP_API}/user/data`, {
        headers: { Authorization: token[0] },
      });

      setuserData(user.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchData(state.token);
  }, [state.token]);

  return (
    <Container>
      <UserData
        loading={loading}
        userData={userData}
      />
      <UpdatePassword
        loading={loading}
        userData={userData}
      />
    </Container>
  );
}
