import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Logo } from "../../assets/svg/index";
import { Link } from "react-router-dom";

import Burger from "./Burger";
import Menu from "./Menu";

import { useEffect } from "react";
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 2rem;
`;
const LogoWrapper = styled.div`
  width: 85px;
  height: auto;
`;
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default function HeaderMobile() {
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
      <Link to="/">
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Link>
    </Container>
  );
}
