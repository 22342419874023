import moment from "moment";
import React, { useMemo, useState } from "react";
import TodayLineGraph from "./TodayLineGraph";


function LineGraph({ timestamps, data, unit, id, type }) {



  const [clickedElement, setClickedElement] = useState({
    date: " ",
    time: "",
    value: ""
  });

  const datas = useMemo(() => {
    return {
      labels: timestamps,
      datasets: [
        {
          label: "",
          data: data,
          borderColor: "#339989",
        },
      ],
    }
  }, [])


  return <>
    {/* <Label clickedElement={clickedElement}
      unit={unit}
      type={type}
    /> */}
    <div style={{
      width: "100%",
      height: "300px"
    }}>
      <TodayLineGraph
        setClickedElement={setClickedElement}
        id={id}
        unit={unit}
        type={type}
        timestamps={timestamps}
        datas={datas} />
    </div>

  </>

}

const Label = ({ clickedElement, unit, type }) => {
  return (
    <h5>Date: {clickedElement.date}
      <br />
      Time: {clickedElement.time}
      <br />

      {type}: {clickedElement.value} {unit === 'percent' ? "%" : unit === 'celsius' ? '°C' : unit}
    </h5>

  )
}

export default LineGraph;
