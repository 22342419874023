import React from "react";
import Thermometer from "react-thermometer-component";
export default function ThermometerAnim({ value }) {
  return (
    <Thermometer
      theme="light"
      value={value}
      max="60"
      format="°C"
      height="110"
    />
  );
}
