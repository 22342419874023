import React from 'react';

const ProgressBar = ({ progress }) => {
    const progressBarStyle = {
        width: '100%',
        backgroundColor: '#F1ECE0',
        borderRadius: '5px',
        overflow: 'hidden',
        height: '20px',
        marginTop: '20px'
    };

    const progressStyle = {
        width: `${progress}%`,
        backgroundColor: '#BE6F72',
        height: '100%',
        transition: 'width 0.3s ease-in-out'
    };

    return (
        <div style={progressBarStyle}>
            <div style={progressStyle}></div>
        </div>
    );
};

export default ProgressBar;
