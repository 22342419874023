import React from "react";

function NotFound() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 634.88 322.85"
    >
      <defs>
        <linearGradient
          id="prefix__linear-gradient"
          x1={385.54}
          y1={338.47}
          x2={388.18}
          y2={134.91}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#e8d197" />
          <stop offset={1} stopColor="#fef5da" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-2"
          x1={384.92}
          y1={235.9}
          x2={388.39}
          y2={330.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-3"
          x1={402.3}
          y1={152.15}
          x2={402.96}
          y2={175.28}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-4"
          x1={188.1}
          y1={114.12}
          x2={190.08}
          y2={165.01}
          xlinkHref="#prefix__linear-gradient-3"
        />
        <linearGradient
          id="prefix__linear-gradient-5"
          x1={513.75}
          y1={317.21}
          x2={491.94}
          y2={129.35}
          xlinkHref="#prefix__linear-gradient"
        />
        <linearGradient
          id="prefix__linear-gradient-6"
          x1={118.08}
          y1={518.47}
          x2={81.32}
          y2={201.87}
          xlinkHref="#prefix__linear-gradient"
        />
        <linearGradient
          id="prefix__linear-gradient-7"
          x1={255.31}
          y1={330.74}
          x2={253.99}
          y2={236.45}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#2f1829" />
          <stop offset={1} stopColor="#3b223c" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-8"
          x1={380.64}
          y1={328.98}
          x2={379.31}
          y2={234.69}
          xlinkHref="#prefix__linear-gradient-7"
        />
        <linearGradient
          id="prefix__linear-gradient-9"
          x1={505.46}
          y1={327.23}
          x2={504.13}
          y2={232.94}
          xlinkHref="#prefix__linear-gradient-7"
        />
        <linearGradient
          id="prefix__linear-gradient-10"
          x1={339.07}
          y1={161.4}
          x2={350.3}
          y2={197.09}
          xlinkHref="#prefix__linear-gradient-7"
        />
        <linearGradient
          id="prefix__linear-gradient-11"
          x1={633.34}
          y1={324.54}
          x2={475.38}
          y2={214.17}
          xlinkHref="#prefix__linear-gradient-3"
        />
        <linearGradient
          id="prefix__linear-gradient-12"
          x1={625.28}
          y1={334.76}
          x2={625.14}
          y2={327.05}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fecba6" />
          <stop offset={1} stopColor="#d17878" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-13"
          x1={587.73}
          y1={288.32}
          x2={612.29}
          y2={264.97}
          xlinkHref="#prefix__linear-gradient-12"
        />
        <linearGradient
          id="prefix__linear-gradient-14"
          x1={626.69}
          y1={297.41}
          x2={627.02}
          y2={270.75}
          xlinkHref="#prefix__linear-gradient-12"
        />
        <linearGradient
          id="prefix__linear-gradient-15"
          x1={610.34}
          y1={254.84}
          x2={616.04}
          y2={256.08}
          xlinkHref="#prefix__linear-gradient-12"
        />
        <linearGradient
          id="prefix__linear-gradient-16"
          x1={608.46}
          y1={338.96}
          x2={610.88}
          y2={327.34}
          xlinkHref="#prefix__linear-gradient-12"
        />
        <linearGradient
          id="prefix__linear-gradient-17"
          x1={-1610.24}
          y1={324.54}
          x2={-1768.19}
          y2={214.17}
          gradientTransform="matrix(-1 0 0 1 -1476.69 0)"
          xlinkHref="#prefix__linear-gradient-3"
        />
        <linearGradient
          id="prefix__linear-gradient-18"
          x1={153.69}
          y1={251.84}
          x2={151.76}
          y2={262.47}
          xlinkHref="#prefix__linear-gradient-12"
        />
        <linearGradient
          id="prefix__linear-gradient-19"
          x1={182.27}
          y1={278.96}
          x2={171.11}
          y2={282.14}
          xlinkHref="#prefix__linear-gradient-12"
        />
        <style>
          {
            ".prefix__cls-2{fill:#2f1829}.prefix__cls-10,.prefix__cls-12,.prefix__cls-14,.prefix__cls-27,.prefix__cls-7{mix-blend-mode:overlay}.prefix__cls-13,.prefix__cls-7{fill:#fff}.prefix__cls-12,.prefix__cls-14,.prefix__cls-27,.prefix__cls-7{opacity:.25}.prefix__cls-11{fill:#f3475c}.prefix__cls-15{fill:#ffca51}.prefix__cls-16{fill:#982245}.prefix__cls-25{fill:#2f172b}.prefix__cls-27,.prefix__cls-28,.prefix__cls-40{fill:none}.prefix__cls-27,.prefix__cls-28{stroke:#fff;stroke-width:.46px;stroke-linecap:round;stroke-linejoin:round}.prefix__cls-31{fill:#4c3146}.prefix__cls-32{fill:#1f3247}.prefix__cls-40{stroke-miterlimit:10;stroke:#1f3247;stroke-width:.93px}.prefix__cls-41{fill:#ffadae}"
          }
        </style>
      </defs>
      <title>{"2480260"}</title>
      <g
        style={{
          isolation: "isolate",
        }}
      >
        <g id="prefix__OBJECTS">
          <path
            className="prefix__cls-2"
            d="M159.28 343.15s9.82 17.42 80.54 25.35 106.4 34.37 151.34 42.3 100.46-44.28 148.71-36.35 106.4-22.28 111-31.3z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M669.9 339.69h-10.46l-460.62.09s-4.66-3.68-11.92-10c-3.11-2.71-6.7-5.9-10.62-9.5q-2.11-1.95-4.37-4.07c-13.72-12.9-30.32-30-43.8-48.11-14.2-19.12-25-39.47-25.26-57.5v-1.21c.15-17.44 3.59-36.25 12.08-53.71A105 105 0 01135.34 127c22.85-22.76 59.82-38.7 117.2-38.24 66.92.53 136.32 25.48 199 60 13.11 7.22 25.92 14.86 38.36 22.78q13.8 8.79 26.94 18a788.56 788.56 0 0166.69 52.15c45.09 39.43 76.75 76.72 86.37 98z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient)"
          />
          <path
            d="M669.9 339.69h-10.46l-460.62.09s-4.66-3.68-11.92-10c-3.11-2.71-6.7-5.9-10.62-9.5q-2.11-1.95-4.37-4.07c-13.72-12.9-30.32-30-43.8-48.11-14.2-19.12-25-39.47-25.26-57.5 5.73-3 19.14-9.12 30.23-6.34 14.54 3.63 17.65 20.91 17.65 20.91s10.42-8.4 21.33-4.41 12.57 15.55 12.57 15.55 12.87-6.64 24.45-3.81 20.87 14 8.46 21.32-26.15 1.64-26.48 1.51c.17.22 5 6.76-3.79 14.81-9 8.18 25.47 15.28 25.47 15.28S214 263 237.18 265.51s41.78 15 41.78 15 1.17-34.31 19.68-40.58 28 13.87 28 13.87 41-26.54 54.92-1.8 14.49 29.43 14.49 29.43 11.86-65 49.08-62 44.13 28.78 43.48 31.41 10.69-16.68 21.88-6.2 17.68 25.11 17.68 25.11l58.89-7s-6-11.62-3.56-21.15c45.12 39.52 76.78 76.81 86.4 98.09z"
            transform="translate(-58.32 -88.77)"
            style={{
              mixBlendMode: "overlay",
            }}
            opacity={0.45}
            fill="url(#prefix__linear-gradient-2)"
          />
          <path
            d="M489.89 171.55H315.82c21.81 0 26.11-7.93 39-12.22.62-.21 1.23-.39 1.84-.54 12-3 22 3.82 29.71 3.51 8.1-.33 17-8.92 26.27-11.9 3.15-1 6.4-.68 9.74.11 6.5 1.53 13.31 4.84 20.24 3.53 6-1.14 6.48-3.58 8.9-5.27a705.917 705.917 0 0138.37 22.78z"
            transform="translate(-58.32 -88.77)"
            style={{
              mixBlendMode: "hard-light",
            }}
            fill="url(#prefix__linear-gradient-3)"
          />
          <path
            d="M264.51 155.69H114.93a105.15 105.15 0 0120-28.26l.41-.41a71.42 71.42 0 0111.36-1.4c23.73-1 28.75 16.85 28.75 16.85s6.21-8.26 17.44-4.29 7.28 8.92 16.2 11.56a9.84 9.84 0 003.65.32c8.5-.74 19.24-10.05 28.73-6.6 10.91 3.97 3.79 12.23 23.04 12.23z"
            transform="translate(-58.32 -88.77)"
            style={{
              mixBlendMode: "hard-light",
            }}
            fill="url(#prefix__linear-gradient-4)"
          />
          <path
            className="prefix__cls-2"
            d="M689.82 346.52H133.48a3.37 3.37 0 01-3.37-3.37 3.37 3.37 0 013.37-3.37h556.34a3.37 3.37 0 013.37 3.37 3.37 3.37 0 01-3.37 3.37z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-7"
            d="M422.38 150.51c-9.92 2.54-21.1 13.3-28.35 14.88-9.14 2-19.72-2.65-30-5.23a51.08 51.08 0 00-7.41-1.37c12-3 22 3.82 29.71 3.51 8.1-.33 17-8.92 26.27-11.9 3.19-1.01 6.4-.68 9.78.11zM212.74 150.06c-8.93 4.66-13.06-5.95-20.84-9.43-8.92-4-15.2 8.45-17.84 6.3s-3.8-11.4-13.88-16.19c-5.72-2.72-16.77-3.31-25.25-3.31l.41-.41a71.42 71.42 0 0111.36-1.4c23.73-1 28.75 16.85 28.75 16.85s6.21-8.26 17.44-4.29 7.28 8.92 16.2 11.56a9.84 9.84 0 003.65.32z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M452.93 128.35s62.46 55.76 87.24 48.16-130.94-81.33-87.24-48.16z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-5)"
          />
          <path
            d="M93 185s-21.24 37.4 15.9 76.82c8.59 9.12 9.59 26.07-7.6 27s-43.95-41-43-74.73S98.75 165.14 93 185z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-6)"
          />
          <path
            className="prefix__cls-2"
            d="M519 383.7s-36 7.93-45.21 20.16c-4.53 6 13.44 5.08 28.73-1.49 15.94-6.86 29.1-19.51 16.48-18.67zM258.16 381.39s14-.33 26.33 4.46 15 19.49 2.75 21.31-40.07-14.48-38.5-21.15c1.16-4.9 9.42-4.62 9.42-4.62z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-10"
            d="M194.3 301.66l-7.91 27.43c-3.12-2.71-6.71-5.9-10.62-9.5l-1.72-3.19s3-35.17-6.93-49.26c9.52 6.65 14.07 33.25 14.07 33.25l-1.48 2h1.48a37.08 37.08 0 013.27 11.14c6.09-31.64.17-46.94 0-47.35a47.63 47.63 0 017.81 15.66c2.92 9.78 1.83 15.94 1.83 15.94l-2.37 3.9z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-11"
            d="M434.1 328.64a41 41 0 004.49-4.78q.51-.66.93-1.32l1.17 1.43-.4-2.25s8.06-2.82 11-10.58c-1.77 8.55-5.18 14.87-9.19 17.83a8.54 8.54 0 01-5.53 1.83c-6.28-.34-2.47-2.16-2.47-2.16z"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M451.25 311.13a49.78 49.78 0 01-2.49 6.48 39.19 39.19 0 01-3.44 6 20.17 20.17 0 01-4.84 5 10.58 10.58 0 01-6.65 2 .25.25 0 01-.22-.25.24.24 0 01.25-.22 10.18 10.18 0 006.43-1.83 20.09 20.09 0 004.84-4.85 39.26 39.26 0 003.53-6 50.5 50.5 0 002.59-6.33z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-14"
            d="M434.1 328.64a41 41 0 004.49-4.78 13 13 0 003.47 5.11 8.54 8.54 0 01-5.53 1.83c-6.24-.34-2.43-2.16-2.43-2.16z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-10"
            d="M371.48 313.19s-18.63-17.45-15.62-40.81c1.92 10.82 9.79 23.73 9.79 23.73l-1 2.91 3.5-.68s4.41-26.65-5.51-39.47c13.38 13.78 13.22 34.3 13.22 34.3l-2.5 3.83 2.52 1.1s-2.93 10 5.78 6.14 14.22-36.91 6.35-45.32c9.44 8.11 7.87 36 7.87 36.93s-4.09 4.67-4.09 4.67l3.14 2.26s-1.93 18.83-23.45 10.41zM456.79 338s-6.47-6.06-5.42-14.18a29.29 29.29 0 003.4 8.25l-.34 1 1.21-.24s1.53-9.26-1.91-13.71c4.65 4.79 4.59 11.92 4.59 11.92l-.87 1.31.87.38s-1 3.46 2 2.13 4.94-12.82 2.21-15.74c3.28 2.82 2.73 12.5 2.73 12.83s-1.42 1.62-1.42 1.62l1.09.79s-.68 6.64-8.14 3.64z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-15"
            d="M432.05 329.13l-5.26.36c3.79-3.4 3.23-6.67 2.66-8.16a5.06 5.06 0 00-.41-.86l2.48-.4-1.39-1.89s4.5-5.2 6.19-19.05a23.57 23.57 0 01.44 23.24 28.37 28.37 0 01-4.71 6.76z"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M436.32 299.13a49 49 0 011 7.81 45.16 45.16 0 01-.26 7.88 33.13 33.13 0 01-2 7.64 23.9 23.9 0 01-4.26 6.67.23.23 0 11-.34-.31 23.59 23.59 0 004.27-6.48 32.89 32.89 0 002.05-7.54 45.79 45.79 0 00.37-7.85 47.8 47.8 0 00-.83-7.82z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-14"
            d="M432.05 329.13l-5.26.36c3.79-3.4 3.23-6.67 2.66-8.16a9.51 9.51 0 007.31 1 28.37 28.37 0 01-4.71 6.8z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-16"
            d="M466.32 339.08h-85.26L394.72 328s18.2-9.36 25.79-7.41 6.37 8.34 6.37 8.34a10.17 10.17 0 011.24-1.11c1.68-1.31 4.88-3.1 8.13-1.17 4.62 2.76 3.89 6.39 3.86 6.57.15-.15 2.92-2.84 5.17-2a6.14 6.14 0 013.21 3.42s3-4.15 5.49-2.63a12 12 0 014 4.61s4.02-4.01 8.34 2.46z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M449.87 339.08h-68.81L394.72 328s18.2-9.36 25.79-7.41 6.37 8.34 6.37 8.34a10.17 10.17 0 011.24-1.11 16.49 16.49 0 010 4.21s3.85-1.45 6.39.64 1.76 4 1.76 4a8 8 0 017.27 0 54.93 54.93 0 006.33 2.41z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M295.34 279.06V185.5h-35.55v.32L217 254.64l-18.7 30.11 2.11 21.73h59.38v32.6h35.55v-32.6H311v-27.42zm-35.55 0h-27.63l25.42-43.67 2.21-3.58z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-7)"
          />
          <path
            d="M419.17 198.27q-14-17-39.55-17t-39.5 16.88q-14 16.88-14 48v28.06q0 30.9 14 47.89t39.66 17q25.43 0 39.4-16.88t14-48v-27.96q.02-31.02-14.01-47.99zm-57.38 41q.31-15.39 4.49-23t13.34-7.6q9.38 0 13.71 8.18t4.32 24.74v38q-.11 16.56-4.42 24.31t-13.4 7.75q-9.39 0-13.71-8-4.08-7.51-4.31-23.23v-1.93z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-8)"
          />
          <path
            d="M545.53 279.06V185.5H510v.32l-61.5 98.93 2.11 21.73H510v32.6h35.54v-32.6h15.62v-27.42zm-35.54 0h-27.64l25.42-43.67 2.22-3.58z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-9)"
          />
          <path
            className="prefix__cls-7"
            d="M288.73 185.5c-4.76 6.08-21.07 3.91-24.74 4.3-4.16.44-33.06 51.33-37 57.79-2.58 4.19-7.1 6.17-9.95 7l42.77-68.82v-.32zM545.53 185.5v70.84c-9.1-7.55-4.08-63.37-5.52-65.22s-20.49-.66-24-1.76a12.2 12.2 0 01-5.23-3.86z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M387.12 320.44c-28.64 1.32-25.33-40-25.33-40q.22 15.72 4.31 23.23 4.32 8 13.71 8 9.06 0 13.4-7.75t4.42-24.31v-38q0-16.58-4.32-24.74t-13.71-8.18s17.72-2.16 24.95 24.06 11.21 86.37-17.43 87.69z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M349.45 198.15s-11.9-8.6-13.22-22c8.59 2.42 13.22 12.85 13.22 12.85s-2.2-9.58 0-16.12c6.39 9.18 4.63 16.67 0 25.27z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-10)"
          />
          <path
            d="M587.09 276s0 .28-.34.68a4.69 4.69 0 01-1.7 1c-5.44 2.07-16.54-3-22.57-6.36s-23.23-.6-34.81-2.17-16.49-7.85-26.91-10.41-49.24 8.63-51.4-.07 41.44-93.37 45.17-87.46 16.65 6.29 25.7 11 16.82 28 26.88 30.33a16.17 16.17 0 014 1.52c8 4.28 13.8 15 14 23.84.24 10.21 14.18 18.45 19.29 23.94s2.69 14.16 2.69 14.16z"
            transform="translate(-58.32 -88.77)"
            style={{
              mixBlendMode: "hard-light",
            }}
            fill="url(#prefix__linear-gradient-11)"
          />
          <path
            className="prefix__cls-7"
            d="M587.09 276s0 .28-.34.68c1.81-11.16-8.52-16.46-18-24.32-9.74-8.1-5.64-14.21-7.63-22.47C560 225 555 218.46 551.11 214c8 4.28 13.8 15 14 23.84.24 10.21 14.18 18.45 19.29 23.94s2.69 14.22 2.69 14.22z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M627.91 329.41v3.3a23.41 23.41 0 00-3.24.16l-1.89.19-.3-2.8 1.3-.2z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-12)"
          />
          <path
            className="prefix__cls-2"
            d="M586.87 274.48l-2.58 2.35a.65.65 0 00.25 1.09l2.18.7 5 5.52a.6.6 0 00.85 0l.83-.75a.6.6 0 000-.85l-5-5.52-.49-2.23a.65.65 0 00-1.04-.31z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M606.71 278c-.66.86-1.52 2-2.41 3.11-.33.42-.67.84-1 1.24-1.38 1.7-2.64 3.11-3 3.18-1 .16-6.56-3-7.39-3s-2.68 1.36-3.18 1.24-3.22-2.64-3.14-3.26 2.52-2.77 3.72-3.1a5.49 5.49 0 012 .21h.13c2.35.52 5.72 1.58 6.24 1.31a7.59 7.59 0 001.42-2.08c.21-.39.43-.79.64-1.2s.53-1 .77-1.49l.61-1.24a27.68 27.68 0 002.15 2.69 12.87 12.87 0 002.44 2.39z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-13)"
          />
          <path
            d="M629.43 284.56c0 1.83 0 3.57-.14 5-.44 5.65-.88 10.28-2.81 10.39s-2.31-15.31-2.21-16.25c0-.18 0-.57.05-1.07 0-1.68.07-4.69.1-6a5.87 5.87 0 010-.61l.36-.22 4.21-2.69s.24 3.65.36 7.8v1.73c0 .58.08 1.29.08 1.92z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-14)"
          />
          <path
            className="prefix__cls-14"
            d="M629.43 284.56a7.18 7.18 0 01-4.17 2c-2.64 0 1.23-5.36 2.1-5.49.35 0 1.15-.08 2-.12v1.73c0 .58.07 1.25.07 1.88z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-2"
            d="M629.41 282.66a8 8 0 01-4.41.22 2.47 2.47 0 01-.68-.25c-1.62-.91-1-3.59.1-6 .12-.29.25-.56.38-.83l4.21-2.69s.31 4.73.4 9.55z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-25"
            d="M620.48 252.17a5.14 5.14 0 01-.78 2 9.59 9.59 0 01-2 2.37 2.19 2.19 0 01-1.49.65c-1.35-.19-3-2.37-3.06-2.78s1.44-.57 1.25-1.78-1-1.44-1.54-1.21a3.1 3.1 0 00-1.25 2.66c-.39 0-1.12-1.51-2.48-2.35a3.56 3.56 0 00-1.27-.5 3.91 3.91 0 00-2.07.18c-.35-4.38 2.29-6.31 4.33-5.54 0 0-.74-1.45-1.82-1.76a3.74 3.74 0 013.09 2.45 4.55 4.55 0 013.82-3.33c3.14-.38 3.75 3.75 3.75 3.75a8 8 0 00.37-3.23 9.52 9.52 0 01-.12 4.3 2.89 2.89 0 011.87 2.37c-1.27-1-1.35-.89-1.35-.89a3.2 3.2 0 01.75 2.64z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M617.7 256.53c-.25.68-.48 1.33-.59 1.56-.25.58-3.47 3.28-5.77.17a12.14 12.14 0 01-2.21-6.54c1.36.84 2.09 2.3 2.48 2.35a3.1 3.1 0 011.25-2.66c.58-.23 1.35 0 1.54 1.21s-1.28 1.37-1.25 1.78 1.71 2.59 3.06 2.78a2.19 2.19 0 001.49-.65z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-15)"
          />
          <path
            className="prefix__cls-25"
            d="M619.3 243.65a1.9 1.9 0 00-.23-.45 1.2 1.2 0 01.23.45z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-15"
            d="M630.44 272.58a14 14 0 01-1 1.61l-.33.43a11.12 11.12 0 01-1.85 1.86 1.13 1.13 0 010 .12c0 .09 0 .23.07.43.08.45.19 1.15.31 2l.06.42c0 .4.1.81.14 1.24 0 .25.06.5.08.75s0 .28 0 .42c.06.67.09 1.35.1 2v.42a9.39 9.39 0 01-.19 2 3.44 3.44 0 01-.14.42 1.26 1.26 0 01-.22.4 1.2 1.2 0 01-.49.34c-1.45.65-5.32.68-9.15.48-3.31-.18-6.59-.54-8.25-.82a3.53 3.53 0 01-1.19-.4 2 2 0 01-.54-.42 1.6 1.6 0 01-.23-.3 3.64 3.64 0 01-.36-2.11 12.48 12.48 0 01.34-1.88v-.11c0-.14.07-.28.11-.42.17-.67.36-1.34.51-2 0-.1 0-.2.07-.3v-.12c.16-.8.27-1.46.34-2a3.8 3.8 0 000-.43 4.93 4.93 0 000-1.65s-.34.84-.76 1.65c-.08.14-.16.29-.25.43-.31.54-.65 1-.92 1a.41.41 0 01-.16 0 6.12 6.12 0 01-1.17-1l-.43-.43-.9-1c-.29-.32-.58-.66-.85-1l-.35-.43c-.37-.46-.7-.9-1-1.27a4.78 4.78 0 01-.43-.71.86.86 0 01-.1-.3.66.66 0 010-.13 11.69 11.69 0 01.65-2c0-.14.11-.28.17-.43.25-.6.54-1.28.87-2l.21-.42c.31-.65.65-1.32 1-2l.24-.42c.39-.69.8-1.36 1.23-2l.3-.42a13.24 13.24 0 011.7-2 4.93 4.93 0 01.52-.43l.29-.21a12.85 12.85 0 012.35-1.17 13.81 13.81 0 015-.92 10.49 10.49 0 013.9.76 12 12 0 012.69 1.54l.55.43a15.54 15.54 0 012 2l.35.42a21.93 21.93 0 011.41 2c.09.14.18.28.26.42a22.06 22.06 0 011.07 2l.21.42c.3.64.58 1.3.85 2 0 .14.11.28.16.43.24.63.47 1.29.68 2 0 .13.08.27.13.41.27.15.3.27.34.4z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-7"
            d="M623.21 260.16h-15.28a4.93 4.93 0 01.52-.43h14.21zM625.56 262.56h-19.63l.3-.42h19zM627.23 265h-22.77l.24-.42H627c.06.11.15.25.23.42zM628.51 267.38h-25.28l.21-.42h24.86zM629.52 269.8h-27.33c0-.14.11-.28.17-.43h27c.05.14.11.28.16.43zM630.33 272.21h-28.7a.86.86 0 01-.1-.3.66.66 0 010-.13h28.67c0 .13.08.27.13.41zM629.43 274.19l-.33.43h-25.74l-.35-.43zM605.11 276.6H608c-.08.14-.16.29-.25.43h-2.25zM627.34 277h-18.61a3.8 3.8 0 000-.43h18.49c.06.12.09.26.12.43zM627.71 279.43h-19.42c0-.1 0-.2.07-.3v-.12h19.26zM628 281.84h-20.3c0-.14.07-.28.11-.42h20.15c-.02.14.04.28.04.42zM628.07 283.83v.42h-20.8a3 3 0 010-.42zM627.88 286.24a3.44 3.44 0 01-.14.42h-19.31a2 2 0 01-.54-.42z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-2"
            d="M628.32 326.66c-.91.58-7 1.24-7 1.24l-.19-1.11-1.13-6.66-2.65-15.75-2.7 19.62-.78 5.79s-7.52.41-7.52-1.41v-2.28c.22-8 1.06-34.25 1.26-40.17a2.67 2.67 0 002 1.12l.29.05c3.83.61 14.47 1.47 17.12.29.12 1 .23 2.48.33 4.38.42 7.64.72 21.36.86 29.12.08 3.48.11 5.77.11 5.77z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M620 320.1l-2.65-15.75s.72-6.33 1-7.05 2.21-.16 3-.71c.44 1.05-2.32 2.37-2.54 5.67-.15 2.38.69 12.41 1.19 17.84z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-27"
            d="M609.14 289.36s-.25 3.59 2.68 5.32"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path className="prefix__cls-28" d="M551.45 205.4l-.04.69" />
            <path
              strokeDasharray="1.39 1.39"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#fff"
              strokeWidth={0.46}
              fill="none"
              d="M551.35 207.48l-1.41 29.74"
            />
            <path className="prefix__cls-28" d="M549.91 237.91l-.04.7" />
          </g>
          <path
            style={{
              mixBlendMode: "overlay",
            }}
            strokeDasharray={1.39}
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#fff"
            strokeWidth={0.46}
            fill="none"
            opacity={0.25}
            d="M561.72 208.81l2.64 28.31"
          />
          <path
            className="prefix__cls-27"
            d="M622.27 289.93s-.3 3.46 0 4.12 1.59 1.23 1.87 1.37 1.53-1 1.59-1.69.14-3.66.14-3.66a11.79 11.79 0 00-3.6-.14z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M628.32 326.66c-.91.58-7 1.24-7 1.24l-.19-1.11c1.78-1 5.3-3.24 7.11-5.89.05 3.47.08 5.76.08 5.76z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-31"
            d="M628.68 329.29l-.77.12-5.43.85-.38.06a1.45 1.45 0 01-1.67-1.21v-.12a1.47 1.47 0 011.21-1.67l6.6-1a1.46 1.46 0 011.66 1.22v.12a1.46 1.46 0 01-1.22 1.63z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M614.65 324l-.78 5.79s-7.52.41-7.52-1.41v-2.28c1.82-.1 5.92-.59 8.3-2.1z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-31"
            d="M614.54 330.17v.13a1.46 1.46 0 01-1.59 1.31l-5.74-.53-.86-.08a1.47 1.47 0 01-1.32-1.59v-.12a1.46 1.46 0 011.58-1.32l6.65.62a1.45 1.45 0 011.28 1.58z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M627.36 291.78a22.84 22.84 0 01-17.45-4.67c3.83.61 14.47 1.47 17.12.29.12.96.23 2.48.33 4.38zM608.36 279.13c.71-3.3.44-4.18.44-4.18s-1.22 3-1.93 3.08c-.33 0-1.5-1.11-2.66-2.42a7 7 0 003.34-1.66c1.23-1.4 1.1-7.39 1.58-7.43s1.58 11.07-1.49 15.43c.23-.95.52-1.9.72-2.82zM630.44 272.58a12.39 12.39 0 01-3.19 3.9s-1.63-8.57-2.12-9.41c-.39-.66.16-1.35 1.06 1.57.69 2.26 2.51 4 4.14 3.55.04.13.07.26.11.39z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M627.52 287.06a1.2 1.2 0 01-.49.34c-1.45.65-5.32.68-9.15.48 2.62-.35 8.89-1.74 10-7.21.25 2.55.37 5.48-.36 6.39zM620 258.19c-.34.41-.91.59-1.86.37-1.88-.44-4.36.44-5.65.24a3.54 3.54 0 01-1.37-.45 13.81 13.81 0 015-.92 10.49 10.49 0 013.88.76z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-2"
            d="M606.71 278c-.66.86-1.52 2-2.41 3.11a16.45 16.45 0 01-4.26-4.25c.76-1.37 1.59-3.05 2-3.93a27.68 27.68 0 002.15 2.69 12.87 12.87 0 002.52 2.38z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M606.71 278c-.66.86-1.52 2-2.41 3.11-.33.42-.67.84-1 1.24a18.75 18.75 0 00-1.85-8.18l.61-1.24a27.68 27.68 0 002.15 2.69 12.87 12.87 0 002.5 2.38z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-7"
            d="M600.68 275.65a7.13 7.13 0 01-1.23 2.93c-.33.51-.06 2.79-.37 2.52s-.44-1.38-.85-1.76a50.74 50.74 0 00-5.85-1.72c2.35.52 5.72 1.58 6.24 1.31a7.59 7.59 0 001.42-2.08c.21-.39.43-.79.64-1.2z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M619.7 254.16a9.59 9.59 0 01-2 2.37 2.19 2.19 0 01-1.49.65c-1.35-.19-3-2.37-3.06-2.78s1.44-.57 1.25-1.78-1-1.44-1.54-1.21a3.1 3.1 0 00-1.25 2.66c-.39 0-1.12-1.51-2.48-2.35a3.56 3.56 0 00-1.27-.5 4.49 4.49 0 012.12.11c1.24.52 1.28 1 1.28 1a2.58 2.58 0 012.16-2.16c1.69-.29 3.02 4.3 6.28 3.99z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-32"
            d="M613.87 339.08h-13.14a3.22 3.22 0 01-.14-.94 2 2 0 011.27-2l1.19-.53c1.65-.78 3.42-1.81 3.71-2.41l.26.12a10.42 10.42 0 001.55.56 3.77 3.77 0 00.89.15c.73 0 2.16-.38 3.06-.49.41-.06.71-.05.79.08a32.14 32.14 0 01.49 4.51c.04.57.07.95.07.95z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M612.9 331.61c-.11.61-.24 1.35-.38 1.94-.65.08-1.58.31-2.33.42a4.23 4.23 0 01-.73.07 7.74 7.74 0 01-2.44-.71c0-.65.08-1.63.14-2.25l2.25.2z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-16)"
          />
          <path
            className="prefix__cls-32"
            d="M629.62 339.08h-7.93a2.49 2.49 0 01-.13-.94 3.36 3.36 0 01.32-1.32c.57-1.27.27-3.33.55-3.72h.35a41.86 41.86 0 015.13-.35h.19c.53.18 1.14 3.64 1.4 5.4.06.44.11.78.12.93z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M613.8 338.14c0 .56.07.94.07.94h-13.14a3.22 3.22 0 01-.14-.94zM629.62 339.08h-7.93a2.49 2.49 0 01-.13-.94h7.94c.06.45.11.79.12.94z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M608.57 333.89c-1.22.82-3.49 2.25-4.26 2.25a1.81 1.81 0 01-1.26-.52c1.65-.78 3.42-1.81 3.71-2.41l.26.12a10.42 10.42 0 001.55.56z"
            transform="translate(-58.32 -88.77)"
            fill="#122230"
          />
          <path
            className="prefix__cls-14"
            d="M612.9 331.61c-.11.61-.24 1.35-.38 1.94-.65.08-1.58.31-2.33.42l-.78-2.69zM627.91 329.41v3.3a23.41 23.41 0 00-3.24.16l-.89-2.81z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M179.79 276s-2.41-8.72 2.69-14.21 19-13.73 19.29-23.94 7.93-23 18-25.36 17.82-25.67 26.88-30.33 22-5.06 25.7-11 47.33 78.77 45.17 87.46-41-2.48-51.4.07-15.33 8.83-26.9 10.41-28.78-1.23-34.81 2.17-17.14 8.43-22.57 6.36c-2.2-.85-2.05-1.63-2.05-1.63z"
            transform="translate(-58.32 -88.77)"
            style={{
              mixBlendMode: "hard-light",
            }}
            fill="url(#prefix__linear-gradient-17)"
          />
          <path
            className="prefix__cls-2"
            d="M181.9 277.61l-2.46-2.47a.64.64 0 00-1.08.28l-.6 2.21-5.3 5.27a.61.61 0 000 .85l.79.8a.61.61 0 00.85 0l5.3-5.27 2.21-.59a.64.64 0 00.29-1.08z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M156.33 251.32s-.06 1-.23 2.23a12.46 12.46 0 01-1.32 4.68l-.08.11c-1.06 1.4-3.08 1.05-4.11-.69.61-.75.87-1.71.47-2.09-.85-.8-.62-3.06.59-2.79s1.4 3.53 1.84 3.45 2.09-2.9 2.84-4.9z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-18)"
          />
          <path
            className="prefix__cls-2"
            d="M156.66 249.93a4.54 4.54 0 01-.33 1.39c-.12.33-.27.68-.43 1-.85 1.82-2.12 3.81-2.41 3.87-.44.08-.63-3.17-1.84-3.45s-1.44 2-.59 2.79c.4.38.14 1.34-.47 2.09a2.08 2.08 0 01-2.77.74c-2-1.24-2.39-9-2.37-9.61a4 4 0 01.13-1.18 4.31 4.31 0 012.63-3c3-1.27 8.45-.99 8.45 5.36z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M172.43 282.33a1.69 1.69 0 01-.05.24c-.09.35-.2.59-.33.64a8.4 8.4 0 01-1.59-.13c-1.21-.16-3-.44-4.59-.75a23.1 23.1 0 01-4.39-1.11 8.69 8.69 0 01-2.11-2.53l-.61-1c-1-1.6-1.82-3.21-2-3.65l-.06-.11v.11a15.73 15.73 0 01-.71 3.65c-.12.33-.25.66-.4 1a7.37 7.37 0 01-.46.83 3.23 3.23 0 00-.07 2.81c0 .13.09.25.14.37.08.2.17.41.26.61.15.35.32.7.48 1a4.62 4.62 0 01.76 2.38.51.51 0 01-.16.23 2.59 2.59 0 01-.23.19 8.18 8.18 0 01-1.56.79c-3.91 1.63-12.66 3.5-16.46 0a1.57 1.57 0 01-.19-.18 1.07 1.07 0 01-.34-.76 2.4 2.4 0 01.49-1.18 25.08 25.08 0 001.62-2.46 8.2 8.2 0 00.44-1 7.52 7.52 0 00.43-1.77c.08-.63.14-1.26.19-1.87 0-.33.05-.66.06-1 .08-1.27.09-2.49.08-3.65v-1c0-1.29-.08-2.51-.13-3.64v-1a28.7 28.7 0 01.07-3.65 7.44 7.44 0 01.15-1 6.05 6.05 0 011.95-3.65l.12-.11c.39-.32.77-.61 1.12-.87s.8-.54 1.15-.76c2.36-1.42 3.56-1.22 3.56-1.22s.73 0 1.81.24a21 21 0 013.84 1l.5.21c.36.15.71.32 1.05.5s.47.26.69.4.55.37.82.58a6.77 6.77 0 011.66 1.84 28.889 28.889 0 011.48 2.81c.54 1.16 1.06 2.41 1.55 3.65.13.32.26.65.38 1 .51 1.31 1 2.57 1.33 3.64.12.35.23.68.34 1l.7 2.15c.13.41 1 .8 2.06 1.15.35.13.73.24 1.12.35.75.21 1.53.41 2.22.59.52.14 1 .27 1.35.39s.63.23.68.33a.52.52 0 01.07.15 9.7 9.7 0 01-.28 3.22z"
            transform="translate(-58.32 -88.77)"
            fill="#f5b539"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M156.06 259.43c.3.16.59.34.86.52h-13.31l.68-.52h11.77m.06-.23h-11.91c-.35.26-.73.55-1.12.87l-.12.11h14.66a10.29 10.29 0 00-1.51-1zM160.14 264.06l.25.52h-19.26c0-.19.05-.36.08-.52h18.93m.14-.23H141a7.44 7.44 0 00-.15 1h19.89c-.16-.34-.32-.67-.48-1zM162.15 268.69l.21.52h-21.31v-.52h21.11m.16-.23H140.8v1h21.86c-.12-.33-.25-.66-.38-1zM163.86 273.31l.12.37.05.15h-22.81v-.52h22.66m.16-.23H141v1h23.37c-.11-.3-.22-.63-.34-1zM155.61 277.94c-.07.19-.14.36-.21.52h-14.3v-.52h14.48m.34-.23h-15c0 .32 0 .65-.06 1h14.7c.15-.32.28-.65.4-1zM168.21 277.94l1.74.47h.19-10.64l-.33-.52h9m0-.23h-9.48l.61 1h12.44c-.35-.12-.83-.25-1.35-.39-.69-.18-1.47-.38-2.22-.59zM154.86 282.57v.22l.1.27h-14.9c.08-.18.16-.35.23-.52h14.47m.16-.24h-14.69a8.2 8.2 0 01-.44 1h15.63c-.09-.2-.18-.41-.26-.61 0-.12-.09-.24-.14-.37zM172.14 282.57a2.13 2.13 0 01-.15.41h-.09c-.22 0-.7-.05-1.41-.14s-1.24-.17-1.94-.28h3.59M172.44 282.35h-6.56c1.63.31 3.38.59 4.59.75a13.81 13.81 0 001.44.14h.15c.13 0 .24-.29.33-.64a1.69 1.69 0 00.05-.24zM155.76 287.19a9.43 9.43 0 01-1.09.52h-16.33l-.12-.11-.16.16.12-.2a1 1 0 01-.23-.37h17.81m.74-.23h-18.82a1.07 1.07 0 00.34.76 1.57 1.57 0 00.19.18h16.46a8.18 8.18 0 001.56-.79 2.59 2.59 0 00.23-.19z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-2"
            d="M157.93 330.29a2.17 2.17 0 01-.24.51c-1.09 1.89-4.1 2.08-5.21 1.72a.84.84 0 01-.33-.16c-.67-.58-1.24-5.37-4.8-32.55v.19c-.07 3.86-.87 10.72-1.73 17-.94 7-1.93 13.29-2.06 14.28a1.65 1.65 0 01-.42.87 3.4 3.4 0 01-5-.39 4.14 4.14 0 01-.34-.48 181.48 181.48 0 00-.13-29.58 54.32 54.32 0 01-.25-8.77 38.93 38.93 0 01.63-5.26c3.76 3.82 13 1.76 16.88.09a7.8 7.8 0 001.33-.7c1.08 4.45.67 17.35 1.67 43.23z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M155.07 258.7c-6.39.2-8.21 10.13-8.21 10.13l-1.5-10.39c2.36-1.42 3.56-1.22 3.56-1.22a21.32 21.32 0 016.15 1.48z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-32"
            d="M165.23 339h-12.72a13.69 13.69 0 01-.09-1.54v-.68c0-1.51 0-3.29.07-4.25a3.85 3.85 0 001.23.15 5.51 5.51 0 003.14-.94 3 3 0 00.85-.93 8.56 8.56 0 003.89 3.64c1.33.73 2.57 1.21 3.09 1.58a1.91 1.91 0 01.72 1.43 4.39 4.39 0 01-.18 1.54zM143.3 337.45c0 1-.11 1.54-.11 1.54h-7.26c.09-.52.2-1 .32-1.54a36.65 36.65 0 011.9-5.6 3.47 3.47 0 003.71 1.16 2.66 2.66 0 001.28-.77 36.63 36.63 0 01.16 5.21z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M179.22 280.64c-.14.8-3 3.31-3.86 3.06a2.62 2.62 0 01-.43-.18 5.07 5.07 0 00-2.55-.95 10.15 10.15 0 00.18-3.4l.56-.26A7.71 7.71 0 01176 278c.88.17 3.35 1.84 3.22 2.64z"
            transform="translate(-58.32 -88.77)"
            fill="url(#prefix__linear-gradient-19)"
          />
          <path
            className="prefix__cls-14"
            d="M156.66 286.73a1 1 0 01-.39.42c-2.29 1.63-13.89 5-18.21.61-.57-.59-.35-1.18.15-1.94a7.94 7.94 0 003.7 1.37c3.86.63 10.72-.42 14-2.8a4.62 4.62 0 01.75 2.34zM172.38 282.57c-.09.35-.2.59-.33.64a8.4 8.4 0 01-1.59-.13 8.64 8.64 0 000-4.78 6 6 0 012 .72.52.52 0 01.07.15 10.15 10.15 0 01-.15 3.4zM155.16 282.7c-1.48-1.44-.84-2.58 0-5.44s-.84-4.08-1.11-7.27 0-5 2-7.11a13.36 13.36 0 00.57 11.07 12.87 12.87 0 01-1.57 5.57 3.5 3.5 0 00.11 3.18zM155.9 252.35c-.85 1.82-2.12 3.81-2.41 3.87-.44.08-.63-3.17-1.84-3.45s-1.44 2-.59 2.79c.4.38.14 1.34-.47 2.09a2.08 2.08 0 01-2.77.74c-2-1.24-2.39-9-2.37-9.61a4 4 0 01.13-1.18c1 0 3.26.07 3.8 2.11a9.85 9.85 0 01.48 3.29 2 2 0 012.45-1.89c1.13.23 2.38 1.01 3.59 1.24z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-2"
            d="M147.64 249.62s-.82-1.73-3.47-1.24-3.84 3.89-2.64 9.38c0-4.87.74-6 .74-6s-1.11 3 .33 9.67 2.28 13.17-1.32 16c7.15-4 7.56-8.8 7.15-14.58s.87-10.85-.79-13.23z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M151.94 255.51a4.73 4.73 0 002.84 2.72l-.08.11-.13.15a21 21 0 00-3.84-1c.51-.72.7-1.55.33-1.9-.85-.8-.62-3.06.59-2.79s1.4 3.53 1.84 3.45 2.09-2.9 2.84-4.9c0 0-.06 1-.23 2.23a13.1 13.1 0 01-1.95 2.83c-.91.78-1.26-.19-1.51-.79s-.64-.62-.7-.11z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            d="M81.19 204.15s-.2 3.74.27 4.74a4.71 4.71 0 001.95 1.71s1.83-.77 2.31-1.87a20.79 20.79 0 00.42-4.86s-3.66-.95-4.95.28z"
            style={{
              mixBlendMode: "overlay",
            }}
            strokeMiterlimit={10}
            stroke="#fff"
            strokeWidth={0.46}
            fill="none"
            opacity={0.25}
          />
          <path
            className="prefix__cls-27"
            d="M155.16 289.93a5.35 5.35 0 01-2.49 4.62"
            transform="translate(-58.32 -88.77)"
          />
          <path className="prefix__cls-27" d="M96.21 203.81l2.13 37.06" />
          <path
            className="prefix__cls-14"
            d="M147.35 300c-.07 3.86-.87 10.72-1.73 17-.13-7.38-1.17-16.54-.83-18a3.66 3.66 0 002.56 1z"
            transform="translate(-58.32 -88.77)"
          />
          <path className="prefix__cls-27" d="M87.04 212.2L84 242.44" />
          <path
            className="prefix__cls-14"
            d="M154.94 287.85c-1.81 1.77-7.39 6.16-17.51 5.17a38.93 38.93 0 01.63-5.26c3.76 3.82 13.01 1.76 16.88.09zM136.25 337.45h7.05c0 1-.11 1.54-.11 1.54h-7.26c.07-.52.2-.99.32-1.54zM165.23 339H152.5c-.05-.35-.07-.9-.09-1.54h13a4.52 4.52 0 01-.18 1.54z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-7"
            d="M161.59 334.44c-2.28 1.11-3.93-1.19-4.73-2.72a3 3 0 00.85-.93 8.56 8.56 0 003.88 3.65z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M141.86 333c-.24 3.37-1.41 3.5-2 3.15s-.18-2.48 0-3.17a3.62 3.62 0 002 .02zM152.41 336.77c0-1.51 0-3.29.07-4.25a3.85 3.85 0 001.23.15c.15 1.09.29 3.58-1.3 4.1zM172.38 282.57a10.15 10.15 0 00.18-3.4l.56-.26a9.06 9.06 0 011.81 4.61 5.07 5.07 0 00-2.55-.95z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-7"
            d="M167.12 277.36c-2 .2-3.36 2.3-3.53 1.88s.9-1.53.77-2.19-3.21-8.59-4.9-12.47a34.08 34.08 0 00-2.65-5c.28.18.55.37.82.58a6.77 6.77 0 011.66 1.84 28.889 28.889 0 011.48 2.81c.54 1.16 1.06 2.41 1.55 3.65.13.32.26.65.38 1 .51 1.31 1 2.57 1.33 3.64.12.35.23.68.34 1l.7 2.15c.12.37.93.75 2.05 1.11z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-2"
            d="M650.38 341c-.66-.49-21.48-56.67 2.48-88.72a188.7 188.7 0 00-1.49 41.14l-3.14 3.14 3.47 1s2.3 31.44 3.47 35.07c-2.55-23.48 3.76-37.2 3.76-37.2s-1.12 16.67 1 22.79l-1.65 2.22 1.65-.34s-1.15 10.08 0 13.11c0 0 13.39-40 7.11-57.56 8.26 12.84 5.95 34.27 5.95 34.27l-2.15.85 1.32 1s-3.47 22.86-11.4 29.3"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-10"
            d="M510.11 334.41s-18.55-1.68-26 3.53c3.53-5 17.58-7.33 17.58-7.33l1 .78v-.78a19.7 19.7 0 015.89-1.69c-16.91-3.35-25-.13-25-.13a25.36 25.36 0 018.29-4.08 20.12 20.12 0 018.42-.92l2.05 1.26v-1.36l18 5.28"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-40"
            d="M581.54 335.42s-2-12.56 8.26-21.26c-.11 10.13-2.95 18.84-8.26 21.26z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-15"
            d="M512.5 327.15l-2 4.87c-1.35-4.91-4.53-5.86-6.12-6a4.43 4.43 0 00-.95 0l.75-2.4-2.32.4s-2.64-6.34-14.29-14a23.57 23.57 0 0121 9.95 28.52 28.52 0 013.93 7.18z"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M487.55 310a48.81 48.81 0 017.44 2.57 45.07 45.07 0 016.94 3.74 33 33 0 016 5.16 23.55 23.55 0 014.07 6.79.23.23 0 01-.13.3.24.24 0 01-.3-.13 23.52 23.52 0 00-3.9-6.71 33.24 33.24 0 00-5.84-5.2 45 45 0 00-6.85-3.82 47.75 47.75 0 00-7.43-2.7z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-14"
            d="M512.5 327.15l-2 4.87c-1.35-4.91-4.53-5.86-6.12-6a9.49 9.49 0 004.19-6.08 28.52 28.52 0 013.93 7.21z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-41"
            d="M589.8 303.8c-19.17 15.75-24.13 23.58-24.13 23.58l-3.41-1.49v.29c0 .85.08 3.33 0 4.16s-6.91 4.39-10.8 6.43l-2.64 1.37-3.2-3.2.33-.63 1.66-3.23a79.48 79.48 0 0111.85-12.86c7.45-6.53 17.97-13.32 30.34-14.42z"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M547.56 336.94a112.1 112.1 0 018.5-10.8 103.48 103.48 0 019.74-9.68 67.84 67.84 0 0111.16-8 39.68 39.68 0 0112.84-4.69 39.54 39.54 0 00-12.8 4.82 68.28 68.28 0 00-11.07 8 104.15 104.15 0 00-9.63 9.74 113.76 113.76 0 00-8.38 10.83.23.23 0 11-.38-.26z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-14"
            d="M562.26 330.34c-.08.71-6.91 4.39-10.8 6.43a51 51 0 01-5.51-2.46l1.66-3.23a79.48 79.48 0 0111.85-12.86 24.56 24.56 0 002.81 8c.02.78.08 3.29-.01 4.12z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-11"
            d="M577.74 293.45c-13.72 11.41-20 20.95-22.58 25.82a24.75 24.75 0 00-1.6 3.48l-3-1.1 1.22 2.75-4.36 8.81-1.91 3.86v-15.36c.59-1.13 1.39-2.61 2.42-4.31a70.6 70.6 0 018.07-10.92 52.67 52.67 0 015.14-5 34.66 34.66 0 0116.6-8.03z"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M545.31 327.82a64.69 64.69 0 015.39-10.91 66.33 66.33 0 017.27-9.72 52.61 52.61 0 019.07-8.06l2.52-1.7 2.66-1.46c.44-.24.88-.5 1.33-.73l1.4-.6 2.79-1.19-2.74 1.22-1.39.61c-.45.23-.88.49-1.33.74l-2.64 1.48-2.49 1.73a52.35 52.35 0 00-9 8.11 65.57 65.57 0 00-7.14 9.75 64.47 64.47 0 00-5.26 10.91.23.23 0 01-.44-.16z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-14"
            d="M553.56 322.75l-3-1.1 1.22 2.75-4.36 8.81-1.91.28v-11.78c.59-1.13 1.39-2.61 2.42-4.31a8.89 8.89 0 007.21 1.87 24.75 24.75 0 00-1.58 3.48z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-16"
            d="M595.19 339.08h-49.66v-7a6.14 6.14 0 012.74-2.7 6.65 6.65 0 012.23-.68 4.55 4.55 0 015 2.55s.91-2.65 4.62-3 5.81 5.32 5.95 5.65c0-.28-.28-3.41 2.48-3.61s2.85 3.71 2.85 3.71 2.32-2.51 5.29-2.35a4 4 0 013.63 3.06 4.89 4.89 0 015.29-1.62c3.64.84 4.05 5.99 9.58 5.99z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M579.68 339.08h-34.15v-7a6.14 6.14 0 012.74-2.7c1.49.11 4.11.59 5.2 2.61a14.22 14.22 0 011.69 5.62s3.33-4.77 6-3.95 4.06 3.95 4.06 3.95 2.8-3 5.16-2c1.17.56 5.4 2.08 9.3 3.47z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-2"
            d="M481.66 341c-.18-.14-6-15.93.7-24.95a53.17 53.17 0 00-.42 11.57l-.88.89 1 .27s.65 8.84 1 9.86c-.72-6.6 1.06-10.46 1.06-10.46s-.32 4.69.29 6.41l-.47.62.47-.09a13.33 13.33 0 000 3.68s3.76-11.24 2-16.18c2.32 3.61 1.67 9.63 1.67 9.63l-.61.24.38.27s-1 6.43-3.21 8.24"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-10"
            d="M255 335.9s-29.3-19.74-46.44-17.9c10.43-5.08 35.89 3.89 35.89 3.89l1 2.25.72-1.29a37 37 0 0111.32 2.57c-25-21-41.37-23.05-41.37-23.05a47.71 47.71 0 0117.49.8c10 2.21 14.81 6.16 14.81 6.16l2.26 4 1.25-2.24 25 25.19"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-40"
            d="M232.16 338.22s-12.36-2.64-20.16-11.89c13.26 1.32 19 6.11 20.16 11.89z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-41"
            d="M273.5 282.56c-1.06 24.79 1.46 33.7 1.46 33.7l-3.39 1.55.23.19c.65.55 2.54 2.16 3.09 2.78s-1.34 8.08-2.41 12.34c-.44 1.7-.75 2.88-.75 2.88l-4.52.24-.25-.66-1.29-3.39a79.2 79.2 0 01-1.67-17.41c.1-9.9 2.07-22.27 9.5-32.22z"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M270 336.14a111.16 111.16 0 01-2.37-13.54 103 103 0 01-.72-13.71 68 68 0 011.52-13.64 39.45 39.45 0 015.07-12.68 39.24 39.24 0 00-5 12.71 67.59 67.59 0 00-1.39 13.61 102.38 102.38 0 00.82 13.67 111.44 111.44 0 002.52 13.44.24.24 0 01-.17.28.23.23 0 01-.28-.17z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-14"
            d="M274.89 320.78c.48.54-1.34 8.08-2.41 12.34a51.77 51.77 0 01-5.52 2.46l-1.29-3.39a79.2 79.2 0 01-1.67-17.41 24.42 24.42 0 007.8 3.22c.65.55 2.54 2.16 3.09 2.78z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-11"
            d="M291 299.64c-6.12 10.68-7.88 18.37-8.38 22.14a17.94 17.94 0 00-.2 2.64h-2.19l1.45 1.49-.69 6.75-.3 2.95-3.69-9.93c.11-.87.27-2 .53-3.37a48.25 48.25 0 012.58-9 36.76 36.76 0 012.12-4.45 23.9 23.9 0 018.77-9.22z"
            transform="translate(-58.32 -88.77)"
          />
          <g className="prefix__cls-12">
            <path
              className="prefix__cls-13"
              d="M278.22 329.69a44.19 44.19 0 01.88-8.37 45.9 45.9 0 012.37-8.05 36.19 36.19 0 013.95-7.39l1.22-1.71 1.37-1.58c.23-.26.45-.53.69-.79l.76-.72 1.52-1.44-1.5 1.46-.75.73c-.23.26-.45.53-.68.8l-1.34 1.6-1.2 1.71a36.17 36.17 0 00-3.82 7.42 44.74 44.74 0 00-2.25 8 43.53 43.53 0 00-.75 8.28.24.24 0 01-.23.23.23.23 0 01-.24-.23z"
              transform="translate(-58.32 -88.77)"
            />
          </g>
          <path
            className="prefix__cls-14"
            d="M282.4 324.42h-2.19l1.45 1.49-.69 6.75-1.17.64-2.8-7.62c.11-.87.27-2 .53-3.37a6.15 6.15 0 005.11-.53 17.94 17.94 0 00-.24 2.64z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-16"
            d="M312 339h-12.42l-54 .05h-16s1.32-4.33 7.6-6.31 8.1 2.13 8.1 2.13-.34-8.22 7.1-11 10.74 6.59 10.74 6.59 1.49-4.64 9.42-5 7.11 5.8 7.11 5.8a14.49 14.49 0 0112.55-3.87c8.1 1.14 8.93 7.56 8.93 7.56s7.07-2.13 10.87 4.05z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-14"
            d="M299.58 339l-54 .05c2.79-2.49 7.35-5.71 12.21-5.76 7.93-.09 9.21 2.78 9.21 2.78s4.89-5.18 8.74-4.3 4 5 4 5 12.36-2.83 19.84 2.23z"
            transform="translate(-58.32 -88.77)"
          />
          <path
            className="prefix__cls-2"
            d="M320.92 342.45s-9-15.88-6.28-31.31a53.26 53.26 0 009.09 20.07l-2.64 2.09h2.64l5.62 10.7-7.61-1.56v.73z"
            transform="translate(-58.32 -88.77)"
          />
        </g>
      </g>
    </svg>
  );
}

export default NotFound;
