import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CaughtUp, DashboardSvg } from "../../assets/svg";
import { GlobalState } from "../../GlobalState";
import DashboardSensorBox from "../sensor/DashboadSensorBox";
import PumpStatus from "../sensor/PumpStatus";
import Banner from "../utils/banner";
import Loading from "../utils/loading";
// import InitNotifcation from "../notification/Init";
// import { HiOutlineRefresh } from "react-icons/hi"

const Container = styled.div`
  margin: auto;
  width: 90%;
`;

const ContentContainer = styled.div`
  margin: auto;
  display:flex;
  flex-direction: column;
`
const SensorType = styled.h3`
  color: #6a6a6a;
`;

// const RefreshWrapper = styled.span`
// display: flex;
// justify-content:flex-end;
// cursor:pointer;
// &:hover{
//   color:var(--primary);
// }

// `;

const SensorWrapper = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;
  @media (min-width: 61.313em) {
    flex-direction: row;
  }
  `
const SensorReadingContainer = styled.div`
  border-radius: 20px;
  margin: 0 auto;
  flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin: 0.5rem auto;
    @media (min-width: 61.313em) {
      flex-direction: row;
  }
`;
export default function Dashboard() {
  const state = useContext(GlobalState);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  // view_formatted_sensor_data
  const view_format = ['Water Level', 'Water Sense', 'Diesel Level', 'Temperature', 'Humidity']

  useEffect(() => {
    async function fetchData(token) {
      try {
        const sensor_list = await axios.get(`${process.env.REACT_APP_API}/user/sensor_list`, {
          headers: { Authorization: token[0] },
        });
        if (sensor_list) {

          // sorting list => 'Sump', ...others 
          if (sensor_list.data['Water Level']) {
            sensor_list.data['Water Level'].sort(function (a, b) {
              if (a.location.toLowerCase().includes('sump'))
                return -1
              return 0;
            });
          }
          setList(sensor_list.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData(state.token);
  }, [state.token]);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );


  return (
    <Container>
      <Banner
        title={`Hi ${state.userAPI.name}`}
        subtitle="You can see all the sensor readings here."
      >
        <DashboardSvg />
      </Banner>
      {/* 
      <RefreshWrapper onClick={handleRefresh}>
        <HiOutlineRefresh size={30} />
      </RefreshWrapper> */}


      <ContentContainer>

        {view_format.map((item, index) => list[item] && (

          <div key={index}>
            <SensorType>{item === 'Water Level' ? 'Water' : item}</SensorType>

            <SensorReadingContainer>

              {list[item].map((sensor, idx) => (
                <SensorWrapper key={idx}>
                  <DashboardSensorBox key={idx} sensor_id={sensor.sensor_id} type={item} />
                  {
                    sensor?.motors && sensor?.motors.map((motor, idx) => (
                      <PumpStatus
                        key={idx}
                        motor_id={motor.motor_id}
                        sensor_id={motor.sensor_id}
                        isBorewell={motor.isBorewell}
                      />))
                  }
                </SensorWrapper>
              ))}
            </SensorReadingContainer>
          </div>
        ))}

      </ContentContainer>

      {/* banner link */}
      <Link to="/report">
        <Banner
          title={`You are all caught up.`}
          subtitle="Click here for previous reports."
        >
          <CaughtUp />
        </Banner>
      </Link>
    </Container>
  );
}
