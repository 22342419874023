import React from "react";
import styled from "styled-components";
import { NotFoundSVG } from "../../assets/svg";
import { Link } from "react-router-dom";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SVGWrapper = styled.div`
  width: 80%;
  height: auto;
  @media (min-width: 61.313em) {
    width: 50%;
  }
`;
const GoBackBtn = styled.button`
  display: block;
  cursor: pointer;
  margin: 0.8rem auto;
  color: var(--background);
  background-color: var(--primary);
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 30px;
`;
function NotFound() {
  return (
    <Container>
      <SVGWrapper>
        <NotFoundSVG />
      </SVGWrapper>
      <h2>Opps! Page Not Found</h2>
      <Link to="/">
        <GoBackBtn>Go Back Home</GoBackBtn>
      </Link>
    </Container>
  );
}

export default NotFound;
