import React from "react";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import { Desktop, Mobile, Tablet } from "../utils/responsive";

export default function Header() {
  return (
    <>
      <Desktop>
        <HeaderDesktop />
      </Desktop>
      <Mobile>
        <HeaderMobile />
      </Mobile>
      <Tablet>
        <HeaderMobile />
      </Tablet>
    </>
  );
}
