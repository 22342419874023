import React from 'react'
import styled, { keyframes } from 'styled-components';

const flowAnimation = keyframes`
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
`;

const PipeContainer = styled.div`
  position: relative;
  margin: 20px auto;
  width: 200px;
  height: 16px;
  background-color: #ccc;
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
  overflow-x: hidden;
`;

const Water = styled.div`
  position: absolute;
  left: 0; /* Change from right to left */
  width: 0;
  height: 100%;
  background-color:  ${({ flowing }) => (flowing ? '#1AA3EC' : 'transparent')};
  animation: ${({ flowing }) => (flowing ? flowAnimation : 'none')} 2s infinite linear;
`;

const WaterSense = ({ value }) => {
  return (
    <PipeContainer>
      <Water flowing={value} />
    </PipeContainer>
  )
}

export default WaterSense