import React from "react";

export default function Humidity() {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <linearGradient
        id="New_Gradient_Swatch_27"
        gradientUnits="userSpaceOnUse"
        x1="110.147"
        x2="401.853"
        y1="436.586"
        y2="144.881"
      >
        <stop offset=".015" stopColor="#c9e4dd" />
        <stop offset="1" stopColor="#e4f2ee" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_9"
        gradientUnits="userSpaceOnUse"
        x1="175.351"
        x2="396.551"
        y1="433.94"
        y2="212.74"
      >
        <stop offset=".015" stopColor="#8c84bc" />
        <stop offset="1" stopColor="#e2e0ee" />
      </linearGradient>
      <g id="_22-humidity" dataname="22-humidity">
        <g id="gradient">
          <path
            d="m349.884 114.194c-42.06-61.085-83.462-104.655-85.2-106.48a12 12 0 0 0 -17.362 0c-1.741 1.825-43.143 45.4-85.2 106.48-57.148 82.994-86.122 154.928-86.122 213.806 0 99.252 80.748 180 180 180s180-80.748 180-180c0-58.878-28.974-130.812-86.116-213.806z"
            fill="url(#New_Gradient_Swatch_27)"
          />
          <path
            d="m304.9 328c-24.888 0-24.888-16-49.776-16s-24.889 16-49.778 16-24.888-16-49.776-16c-16.681 0-22.19 7.182-31.5 11.922-.042 1.37-.07 2.733-.07 4.078 0 72.785 59.215 132 132 132s132-59.215 132-132c0-1.063-.029-2.145-.055-3.224-10.445-4.676-15.559-12.776-33.275-12.776-24.887 0-24.887 16-49.77 16z"
            fill="url(#New_Gradient_Swatch_9)"
          />
          <g fill="#fff">
            <path d="m200 292a12 12 0 0 1 -8.485-20.485l112-112a12 12 0 0 1 16.97 16.97l-112 112a11.961 11.961 0 0 1 -8.485 3.515z" />
            <path d="m304 292a36 36 0 1 1 36-36 36.04 36.04 0 0 1 -36 36zm0-48a12 12 0 1 0 12 12 12.013 12.013 0 0 0 -12-12z" />
            <path d="m216 216a36 36 0 1 1 36-36 36.04 36.04 0 0 1 -36 36zm0-48a12 12 0 1 0 12 12 12.013 12.013 0 0 0 -12-12z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
