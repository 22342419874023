import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { GlobalState } from "../../GlobalState";
import { editAccess } from "../utils/utils";

const ShowFormToggle = styled.button`
  display: block;
  cursor: pointer;
  margin: 0.8rem auto;
  color: var(--background);
  background-color: var(--primary);
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 30px;
`;
const Container = styled.div`
  padding: 1rem;
`;
const Content = styled.div`
  margin: 0 auto;

  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: var(--secondary);
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.6rem 1.1rem;
  margin: 0.5rem auto;
`;
const StyledInput = styled.input`
  background-color: var(--background);
  padding: 0.6rem;
  font-size: 1rem;
  border-radius: 10px;
  width: 100%;
  &:focus {
    border: 2px solid var(--primary);
  }
`;
const ErrMessage = styled.p`
  font-size: 0.7rem;
  color: var(--primary);
`;
const StyledForm = styled.form`
  width: 100%;
`;
const StyledBtn = styled(ShowFormToggle)`
  width: 100%;
  font-size: 1rem;
`;

export default function UpdatePassword(
  {
    loading,
    userData,
  }
) {
  const initialValues = {
    old_password: "",
    new_password: "",
    retype_new_password: "",
  };
  const state = useContext(GlobalState);

  const [showForm, setshowForm] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [err, setErr] = useState("");

  const handleFormToggleClick = () => {
    setErr("");
    setshowForm(!showForm);
    reset();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const reset = () => {
    setValues({
      old_password: "",
      new_password: "",
      retype_new_password: "",
    });
  };

  const UpdatePasswordSubmit = async (e) => {
    e.preventDefault();
    let hasErr = 0;
    if (values.new_password !== values.retype_new_password) {
      setErr("Passwords do not Match");
      hasErr = 1;
    }

    if (values.new_password.length < 8) {
      setErr("Password should be atleast 8 characters long.");
      hasErr = 1;
    }

    if (!hasErr) {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API}/user/update_password`,
          {
            old_password: values.old_password,
            new_password: values.new_password,
          },
          {
            headers: { Authorization: state.token[0] },
          }
        );

        if (res.status === 200) {
          handleFormToggleClick();
        }
      } catch (err) {
        setErr(err.response.data.error);
      }
    }
  };

  if (!editAccess(userData)) {
    return null;
  }
  return (
    <>
      {showForm ? (
        <Container>
          <Content>
            <StyledForm onSubmit={UpdatePasswordSubmit}>
              <InputWrapper>
                <StyledInput
                  type="password"
                  name="old_password"
                  autoComplete="off"
                  placeholder="Old Password"
                  value={values.old_password}
                  onChange={handleInputChange}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInput
                  type="password"
                  name="new_password"
                  autoComplete="off"
                  placeholder="New Password"
                  onChange={handleInputChange}
                  value={values.new_password}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInput
                  type="password"
                  name="retype_new_password"
                  autoComplete="off"
                  placeholder="Confirm New Password"
                  onChange={handleInputChange}
                  value={values.retype_new_password}
                  required
                />
              </InputWrapper>
              <StyledBtn type="submit" value="Submit">
                Submit.
              </StyledBtn>
            </StyledForm>
            <ErrMessage>{err}</ErrMessage>
            <StyledBtn
              onClick={() => {
                setshowForm(!showForm);
              }}
            >
              Cancel.
            </StyledBtn>
          </Content>
        </Container>
      ) : (
        <div>
          <ShowFormToggle onClick={handleFormToggleClick}>
            Update Password
          </ShowFormToggle>
        </div>
      )}
    </>
  );
}
