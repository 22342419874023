import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { GlobalState } from "../../GlobalState";
import { FiEdit2 } from "react-icons/fi";

const StyledInput = styled.input`
  width: 100%;
  background-color: var(--background);
  padding: 0.6rem;
  font-size: 1rem;
  border-radius: 10px;
  &:focus {
    border: 2px solid var(--primary);
  }
`;
const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
`;
const Address = styled.p`
  margin: 0.3rem auto;
`;
const Contact = styled.h4`
  margin: 0.6rem auto;
`;

const UpdateBtn = styled.button`
  display: block;
  cursor: pointer;
  margin: 0.8rem auto;
  color: var(--background);
  background-color: var(--primary);
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 50%;
`;
const StyledBtn = styled(UpdateBtn)`
  border-radius: 20px;
  width: 100%;
`;
const CancelBtn = styled(StyledBtn)`
  border-radius: 20px;
`;
const StyledForm = styled.form`
  width: 100%;
`;
const InputWrapper = styled.div`
  margin: 0.5rem auto;
`;
const ErrMessage = styled.p`
  color: var(--primary);
`;
export default function UpdateUserData({ contact, address }) {
  const state = useContext(GlobalState);

  const [values, setValues] = useState({ contact, address });
  const [Err, setErr] = useState("");
  const [showForm, setshowForm] = useState(false);

  const sendUpdate = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API}/user/update_data`,
        {
          contact: values.contact,
          address: values.address,
        },
        {
          headers: { Authorization: state.token[0] },
        }
      );

      if (res.status === 200) {
        setshowForm(false);
      }
    } catch (err) {
      setErr(err.response.data.error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendUpdate();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  if (showForm) {
    return (
      <StyledForm onSubmit={handleSubmit}>
        <InputWrapper>
          <StyledInput
            type="text"
            name="address"
            placeholder="Address"
            onChange={handleInputChange}
            value={values.address}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <StyledInput
            type="tel"
            name="contact"
            placeholder="Contact"
            value={values.contact}
            onChange={handleInputChange}
            required
          />
        </InputWrapper>
        <ErrMessage>{Err}</ErrMessage>
        <StyledBtn type="submit" value="Submit">
          Submit.
        </StyledBtn>
        <CancelBtn
          onClick={() => {
            setValues({ contact, address });
            setshowForm(false);
          }}
        >
          Cancel
        </CancelBtn>
      </StyledForm>
    );
  }
  return (
    <>
      <Address>{values.address}</Address>
      <Contact>{values.contact}</Contact>
      <UpdateBtn onClick={() => setshowForm(true)}>
        <IconWrapper>
          <FiEdit2 size={23} />
        </IconWrapper>
      </UpdateBtn>
    </>
  );
}
