import React from "react";

export default function Sensor() {
  return (
    <svg viewBox="0 0 406 305" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          opacity="0.4"
          d="M342.661 117.261C348.034 117.261 352.389 112.907 352.389 107.537C352.389 102.167 348.034 97.8135 342.661 97.8135C337.288 97.8135 332.933 102.167 332.933 107.537C332.933 112.907 337.288 117.261 342.661 117.261Z"
          fill="#BE6F72"
        />
        <path
          opacity="0.4"
          d="M137.548 63.7654C145.207 63.7654 151.416 57.5595 151.416 49.9042C151.416 42.2488 145.207 36.043 137.548 36.043C129.889 36.043 123.68 42.2488 123.68 49.9042C123.68 57.5595 129.889 63.7654 137.548 63.7654Z"
          fill="#BE6F72"
        />
        <path
          opacity="0.4"
          d="M111.882 66.2479C117.255 66.2479 121.611 61.8945 121.611 56.5244C121.611 51.1542 117.255 46.8008 111.882 46.8008C106.51 46.8008 102.154 51.1542 102.154 56.5244C102.154 61.8945 106.51 66.2479 111.882 66.2479Z"
          fill="#BE6F72"
        />
        <path
          d="M350.809 216.271C343.122 224.683 342.843 236.935 342.843 236.935C342.843 236.935 355.026 235.551 362.713 227.139C370.4 218.728 370.679 206.475 370.679 206.475C370.679 206.475 358.495 207.86 350.809 216.271Z"
          fill="#3F3D56"
        />
        <path
          d="M355.151 218.692C352.825 229.844 343.047 237.24 343.047 237.24C343.047 237.24 337.041 226.555 339.367 215.403C341.693 204.251 351.47 196.854 351.47 196.854C351.47 196.854 357.477 207.539 355.151 218.692Z"
          fill="#BE6F72"
        />
        <path
          d="M345.167 103.614V236.561L300.83 273.169H74.0027V133.8L140.187 103.614H345.167Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M345.167 103.614V104.146L329.251 114.795L300.83 133.8H74.0027L140.187 103.614H345.167Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M345.167 103.614V236.561L300.83 273.169L301.151 133.479L329.251 114.795L344.911 103.614H345.167Z"
          fill="black"
        />
        <path
          d="M285.408 121.918H147.256L157.537 116.138H289.906L285.408 121.918Z"
          fill="#2F2E41"
        />
        <path
          d="M404.644 280.401C404.644 280.401 407.322 291.595 404.887 292.082C402.452 292.569 390.522 292.412 387.6 292.898C384.679 293.385 378.592 292.569 378.592 290.378C378.592 288.188 384.922 286.728 384.922 286.728C384.922 286.728 393.931 281.861 394.174 281.617C394.418 281.374 404.644 280.401 404.644 280.401Z"
          fill="#2F2E41"
        />
        <path
          d="M402.452 214.45C402.452 214.45 401.722 222.724 401.722 223.455C401.722 224.185 402.452 235.622 401.722 236.109C400.992 236.596 402.209 240.003 402.209 241.22C402.209 242.437 406.105 262.635 405.131 270.423C404.157 278.21 406.592 277.48 405.861 278.21C405.131 278.94 405.131 278.94 405.131 280.401C405.131 281.861 397.339 283.078 397.339 283.078L388.818 229.295L393.2 213.233L402.452 214.45Z"
          fill="#575A89"
        />
        <path
          opacity="0.1"
          d="M402.452 214.45C402.452 214.45 401.722 222.724 401.722 223.455C401.722 224.185 402.452 235.622 401.722 236.109C400.992 236.596 402.209 240.003 402.209 241.22C402.209 242.437 406.105 262.635 405.131 270.423C404.157 278.21 406.592 277.48 405.861 278.21C405.131 278.94 405.131 278.94 405.131 280.401C405.131 281.861 397.339 283.078 397.339 283.078L388.818 229.295L393.2 213.233L402.452 214.45Z"
          fill="black"
        />
        <path
          d="M401.235 283.808C401.235 283.808 403.67 292.569 401.235 293.055C398.8 293.542 386.87 292.498 383.948 292.985C381.026 293.471 374.939 293.542 374.939 291.352C374.939 289.162 381.27 287.701 381.27 287.701C381.27 287.701 390.278 282.834 390.522 282.591C390.765 282.348 401.235 283.808 401.235 283.808Z"
          fill="#2F2E41"
        />
        <path
          d="M404.644 204.716C404.644 204.716 407.565 211.773 404.157 215.424C400.748 219.074 396.852 226.375 396.852 226.862C396.852 227.348 403.67 275.777 402.696 278.941C401.722 282.104 401.965 284.051 402.209 284.538C402.452 285.024 399.774 287.701 396.852 287.701C393.931 287.701 389.548 285.511 389.061 283.564C388.574 281.617 389.792 264.826 389.548 261.662C389.305 258.498 388.087 253.874 387.6 252.901C387.113 251.928 384.435 250.954 383.948 248.034C383.461 245.114 379.565 207.88 380.783 206.663C382 205.446 404.644 204.716 404.644 204.716Z"
          fill="#575A89"
        />
        <path
          d="M386.139 148.743C390.711 148.743 394.418 145.039 394.418 140.469C394.418 135.899 390.711 132.195 386.139 132.195C381.567 132.195 377.861 135.899 377.861 140.469C377.861 145.039 381.567 148.743 386.139 148.743Z"
          fill="#FFB9B9"
        />
        <path
          d="M391.252 144.606C391.252 144.606 389.548 153.61 390.765 154.584C391.983 155.557 381.513 157.017 381.513 157.017C381.513 157.017 380.296 145.093 380.052 143.876C379.809 142.659 391.252 144.606 391.252 144.606Z"
          fill="#FFB9B9"
        />
        <path
          d="M380.052 156.287C380.052 156.287 382.974 147.77 394.905 150.203C394.905 150.203 397.096 150.933 396.365 151.664C395.635 152.394 394.905 152.394 395.879 153.124C396.852 153.854 398.07 153.124 397.339 153.854C396.609 154.584 403.913 158.964 403.426 167.239C402.939 175.513 405.131 187.194 403.426 189.141C401.722 191.088 404.644 199.605 404.157 201.309C403.67 203.012 407.079 205.203 405.618 205.933C404.157 206.663 377.861 211.043 377.131 205.933C376.4 200.822 376.887 204.473 376.157 203.256C375.426 202.039 375.183 199.849 375.67 197.172C376.157 194.495 377.374 187.924 376.887 185.49C376.4 183.057 376.887 162.615 378.348 160.181C379.809 157.748 380.052 156.287 380.052 156.287Z"
          fill="#BE6F72"
        />
        <path
          d="M385.165 215.667C385.165 215.667 388.818 229.539 384.191 229.539C379.565 229.539 379.809 215.18 379.809 215.18L385.165 215.667Z"
          fill="#FFB9B9"
        />
        <path
          d="M382.487 140.469C382.487 141.175 383.51 141.623 385.095 141.725H385.097C385.769 141.763 386.443 141.75 387.113 141.686C388.126 141.593 388.545 142.545 388.718 143.801C389 145.872 388.606 148.775 389.061 149.23C389.689 149.858 394.269 149.228 395.701 147.804C395.924 147.604 396.072 147.335 396.122 147.04C396.272 146.17 396.701 145.373 397.344 144.769C398.189 143.847 399.392 142.825 400.748 141.199C403.183 138.279 400.748 138.035 400.018 135.845C399.287 133.655 398.07 132.925 398.07 131.465C398.07 130.005 392.47 128.788 387.844 129.031C383.218 129.274 384.192 135.115 384.192 135.115C384.192 135.115 383.917 135.821 383.588 136.731C383.143 137.873 382.782 139.046 382.507 140.24C382.495 140.316 382.489 140.392 382.487 140.469Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M383.461 159.938C383.461 159.938 391.739 159.451 392.47 171.619C393.2 183.787 392.957 195.225 392.957 195.468C392.957 195.712 389.548 218.831 386.383 218.831C383.218 218.831 380.783 218.344 380.052 217.127C379.322 215.91 382 198.389 382 198.389C382 198.389 383.218 179.893 381.27 174.053C379.322 168.212 379.079 160.668 383.461 159.938Z"
          fill="black"
        />
        <path
          d="M382.244 158.721C382.244 158.721 390.522 158.234 391.252 170.402C391.983 182.57 391.739 194.008 391.739 194.251C391.739 194.495 388.331 217.614 385.165 217.614C382 217.614 379.565 217.127 378.835 215.91C378.105 214.694 380.783 197.172 380.783 197.172C380.783 197.172 382 178.676 380.052 172.836C378.105 166.995 377.861 159.451 382.244 158.721Z"
          fill="#BE6F72"
        />
        <path
          d="M244.972 39.7639C244.972 39.7639 241.364 29.547 238.759 32.7523C236.153 35.9576 242.767 41.3665 242.767 41.3665L244.972 39.7639Z"
          fill="#A0616A"
        />
        <path
          d="M262.008 29.7474L259.838 30.5513C259.838 30.5513 258.802 35.1563 259.202 35.9576C259.603 36.7589 259.804 36.1579 259.202 37.7606C258.601 39.3632 256.797 43.5702 256.597 44.1712C256.396 44.7721 244.972 40.3649 244.972 38.1612C244.972 38.1612 242.567 40.9659 241.364 41.5669C241.364 41.5669 254.593 51.1827 256.396 52.3847C258.2 53.5867 259.202 52.9857 260.806 51.5834C262.409 50.1811 269.224 38.5619 269.224 38.5619L262.008 29.7474Z"
          fill="#575A89"
        />
        <path
          d="M256.196 100.865C256.196 100.865 256.396 103.068 256.797 103.269C257.198 103.469 260.204 108.477 256.797 109.479C253.39 110.48 251.386 110.08 250.584 110.48C249.782 110.881 235.752 111.682 235.552 109.479C235.351 107.275 242.366 106.875 242.366 106.875C242.366 106.875 248.78 102.668 249.782 100.464C250.784 98.2603 256.196 100.865 256.196 100.865Z"
          fill="#2F2E41"
        />
        <path
          d="M296.883 104.871C296.883 104.871 301.693 113.085 300.491 114.086C299.288 115.088 287.576 118.738 285.859 118.894C283.655 119.095 282.051 117.292 283.855 116.29C285.659 115.288 289.467 111.082 289.467 109.679V106.073L296.883 104.871Z"
          fill="#2F2E41"
        />
        <path
          d="M301.493 51.1826C301.493 51.1826 305.702 56.3912 299.088 64.8051L288.064 78.8282C288.064 78.8282 294.879 89.6461 295.079 95.2553C295.279 100.865 296.682 99.6626 297.083 102.067C297.484 104.471 299.288 103.269 297.885 105.072C296.482 106.875 291.271 108.477 289.667 107.075C289.116 106.571 288.685 105.948 288.408 105.255C288.13 104.561 288.013 103.814 288.064 103.068C288.064 102.267 285.258 97.6593 285.058 96.257C284.857 94.8547 277.441 83.4358 277.441 83.4358C277.441 83.4358 275.036 76.6246 277.842 72.8183C280.648 69.012 282.502 63.6137 282.502 63.6137C282.502 63.6137 274.234 73.6196 268.422 76.0236C268.422 76.0236 266.217 76.4243 266.618 77.4259C267.019 78.4276 266.618 79.0286 266.017 80.0302C265.416 81.0319 261.207 92.6511 259.804 95.656C258.401 98.661 259.002 100.464 257.398 101.466C255.795 102.467 248.58 101.666 248.58 100.664C248.58 99.6626 249.582 97.0583 250.183 95.656C250.784 94.2537 255.595 78.0269 255.194 75.2223C254.793 72.4177 255.394 68.6114 258.401 67.0087C261.407 65.4061 276.64 52.3846 277.441 52.3846C278.243 52.3846 301.493 51.1826 301.493 51.1826Z"
          fill="#2F2E41"
        />
        <path
          d="M261.207 19.7308C265.856 19.7308 269.625 15.9637 269.625 11.3169C269.625 6.67002 265.856 2.903 261.207 2.903C256.558 2.903 252.789 6.67002 252.789 11.3169C252.789 15.9637 256.558 19.7308 261.207 19.7308Z"
          fill="#A0616A"
        />
        <path
          d="M268.021 12.1183C268.021 12.1183 270.627 16.7259 273.834 17.3269C277.04 17.9279 265.415 27.1431 265.415 27.1431C265.415 27.1431 262.609 18.3285 260.204 17.9279C257.799 17.5272 268.021 12.1183 268.021 12.1183Z"
          fill="#A0616A"
        />
        <path
          d="M259.804 30.9493C259.804 33.5536 267.62 40.9659 267.62 40.9659L275.036 47.1761C275.036 47.1761 275.237 49.3797 276.038 49.5801C276.84 49.7804 277.842 49.7804 277.041 50.9824C276.239 52.1844 274.235 54.7887 276.038 55.1893C276.582 55.272 277.13 55.3242 277.68 55.3456C278.161 55.3736 278.726 55.3997 279.362 55.4237C280.845 55.4758 282.701 55.5098 284.711 55.4958C285.282 55.4938 285.863 55.4871 286.455 55.4758C294.033 55.3255 302.812 54.4541 302.094 51.5834C300.891 46.7754 301.693 45.1728 299.088 42.5685C296.482 39.9642 292.273 30.9493 292.273 30.9493C292.273 30.9493 283.855 16.7259 276.84 16.7259L275.383 16.3432C274.706 16.165 273.99 16.2041 273.336 16.455C272.683 16.706 272.125 17.1561 271.741 17.7415C270.334 19.8831 268.115 23.1004 266.819 24.1381C264.814 25.7407 264.502 24.5908 264.502 24.5908C264.502 24.5908 259.804 28.345 259.804 30.9493Z"
          fill="#575A89"
        />
        <path
          d="M277.642 61.3995C277.642 61.3995 271.829 70.4144 275.437 72.017C279.045 73.6197 281.049 62.6015 281.049 62.6015L277.642 61.3995Z"
          fill="#A0616A"
        />
        <path
          d="M266.71 10.3666C266.387 10.3258 266.285 9.91838 266.23 9.59747C265.939 7.87899 264.658 6.2494 262.936 5.97198C262.278 5.88448 261.609 5.93838 260.973 6.13008C260.063 6.37977 259.208 6.80022 258.455 7.36902C258.087 7.70577 257.66 7.97333 257.197 8.15887C256.881 8.25158 255.54 8.84757 255.226 8.94841C254.537 9.17011 253.967 9.89091 253.257 9.7482C252.579 9.61166 252.321 8.79229 252.212 8.1088C251.963 6.55167 252.855 4.22829 253.741 2.92401C254.414 1.93425 255.553 1.36463 256.691 0.99405C258.025 0.589445 259.395 0.317309 260.782 0.181589C262.654 -0.0394379 264.594 -0.156704 266.388 0.421783C268.182 1.00027 269.816 2.41538 270.151 4.26991C270.221 4.65622 270.234 5.05191 270.32 5.43505C270.528 6.36972 271.147 7.15537 271.508 8.04246C271.768 8.69396 271.889 9.39278 271.863 10.0938C271.836 10.7949 271.663 11.4827 271.355 12.1128C270.855 13.1173 269.986 14.0827 270.173 15.1888L268.627 13.9699C268.403 13.8429 268.23 13.6432 268.136 13.4041C268.042 13.1649 268.033 12.9008 268.11 12.6557L268.335 10.72C268.413 10.3693 268.378 10.003 268.235 9.67347C267.547 8.4732 267.248 10.4345 266.71 10.3666Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M281.249 47.5768L279.361 55.4237C281.266 55.4918 283.785 55.5279 286.455 55.4758C287.128 53.4565 287.771 51.351 288.264 49.3798C290.068 42.1679 287.062 31.1497 285.458 25.9411C283.855 20.7325 279.846 19.3302 279.846 19.3302C278.203 19.9078 276.651 20.7173 275.237 21.7341C272.631 23.5371 281.249 47.5768 281.249 47.5768Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M279.646 47.1761L277.68 55.3455C278.161 55.3736 278.726 55.3996 279.361 55.4237C280.845 55.4757 282.701 55.5098 284.711 55.4958C285.434 53.3502 286.134 51.0865 286.661 48.979C288.465 41.7671 285.458 30.749 283.855 25.5404C282.252 20.3318 278.744 26.0412 278.744 26.0412C278.744 26.0412 280.147 22.6355 277.542 24.4385C274.936 26.2415 279.646 47.1761 279.646 47.1761Z"
          fill="black"
        />
        <path
          d="M279.045 18.9294C279.045 18.9294 283.054 20.3317 284.657 25.5403C286.26 30.7489 289.267 41.7671 287.463 48.979C285.659 56.1909 281.851 65.2058 281.851 65.2058C281.851 65.2058 278.043 62.4011 276.64 63.0021L280.448 47.176C280.448 47.176 271.83 23.1364 274.435 21.3334C275.849 20.3166 277.402 19.5071 279.045 18.9294Z"
          fill="#575A89"
        />
        <path
          d="M282.033 119.413L226.58 3.916L133.86 48.3888L167.961 119.413H282.033Z"
          fill="#F2F2F2"
        />
        <path
          d="M207.816 49.2514L158.569 72.8725L160.035 75.9277L209.282 52.3066L207.816 49.2514Z"
          fill="#3F3D56"
        />
        <path
          d="M220.854 76.4089L171.607 100.03L173.074 103.085L222.321 79.4641L220.854 76.4089Z"
          fill="#3F3D56"
        />
        <path
          d="M229.811 33.2721L217.584 39.1367L223.451 51.3576L235.678 45.493L229.811 33.2721Z"
          fill="#3F3D56"
        />
        <path
          d="M242.849 60.4296L230.623 66.2942L236.49 78.5151L248.717 72.6505L242.849 60.4296Z"
          fill="#3F3D56"
        />
        <path
          d="M230.653 24.8072L225.618 39.1271L218.83 36.7424L217.901 39.384L227.323 42.6936L233.296 25.7356L230.653 24.8072Z"
          fill="#57B894"
        />
        <path
          d="M243.692 51.9646L238.656 66.2845L231.869 63.9002L230.94 66.5413L240.361 69.8509L246.335 52.893L243.692 51.9646Z"
          fill="#57B894"
        />
        <path
          d="M5.35151 252.344L30.4043 246.995L25.0528 221.955L2.51404e-05 227.303L5.35151 252.344Z"
          fill="#F2F2F2"
        />
        <path
          d="M29.7074 215.264C29.7074 215.264 20.5054 221.834 23.5728 224.462C26.6401 227.089 32.7747 216.578 32.7747 216.578L29.7074 215.264Z"
          fill="#FFB9B9"
        />
        <path
          d="M72.2119 225.337C72.2119 225.337 70.4591 247.236 70.0209 250.302C69.5827 253.368 69.1445 280.96 68.2682 284.026C67.3918 287.092 66.5154 294.537 66.5154 294.537H61.2572C61.2572 294.537 61.6953 280.96 60.3808 275.704C59.0662 270.449 59.5044 255.996 59.5044 255.996C59.5044 255.996 56.8753 230.155 58.1899 226.651C59.5044 223.148 72.2119 225.337 72.2119 225.337Z"
          fill="#FFB9B9"
        />
        <path
          d="M65.2008 293.223C65.2008 293.223 67.3918 294.537 67.3918 295.413C67.3918 296.289 66.9536 298.479 67.3918 299.355C67.83 300.231 70.8973 303.735 69.1445 304.611C67.3918 305.487 59.5044 304.611 59.5044 304.611C59.5044 304.611 59.9426 298.479 60.3808 298.041C60.819 297.603 59.9426 293.223 59.9426 293.223H65.2008Z"
          fill="#2F2E41"
        />
        <path
          d="M38.7148 224.801C38.7148 224.801 38.1582 246.762 38.272 249.857C38.3858 252.952 35.9239 280.438 36.4734 283.579C37.023 286.72 37.1126 294.216 37.1126 294.216L42.3418 294.768C42.3418 294.768 43.3319 281.22 45.1911 276.131C47.0504 271.042 48.1324 256.623 48.1324 256.623C48.1324 256.623 53.4608 231.201 52.5214 227.579C51.582 223.956 38.7148 224.801 38.7148 224.801Z"
          fill="#FFB9B9"
        />
        <path
          d="M38.5579 293.047C38.5579 293.047 36.2411 294.124 36.1491 294.995C36.0571 295.866 36.2629 298.09 35.7351 298.915C35.2074 299.74 31.7891 302.903 33.4401 303.958C35.0912 305.013 43.027 304.969 43.027 304.969C43.027 304.969 43.2352 298.826 42.8454 298.344C42.4556 297.862 43.7871 293.599 43.7871 293.599L38.5579 293.047Z"
          fill="#2F2E41"
        />
        <path
          d="M66.0771 192.489C66.0771 192.489 69.5826 192.927 70.8971 200.373C72.2117 207.819 77.0318 226.651 73.0881 227.965C69.1444 229.279 57.3133 231.907 56.8751 231.031C56.4369 230.155 55.5606 227.965 55.5606 227.965C55.5606 227.965 56.8751 231.907 55.1224 231.907C53.3696 231.907 36.2803 229.279 36.7185 227.965C37.1567 226.651 38.033 217.016 38.033 217.016C38.033 217.016 40.6622 200.811 43.2913 198.183C44.0522 197.453 44.6429 196.564 45.0216 195.58C45.4002 194.596 45.5575 193.541 45.4822 192.489H66.0771Z"
          fill="#2F2E41"
        />
        <path
          d="M72.6499 188.986C72.6499 188.986 66.9535 187.672 66.0771 189.424C65.2007 191.176 68.2681 193.803 69.5826 193.803C70.8972 193.803 73.0881 192.49 73.0881 192.49L72.6499 188.986Z"
          fill="#FFB9B9"
        />
        <path
          d="M60.819 151.758C65.1751 151.758 68.7064 148.228 68.7064 143.874C68.7064 139.52 65.1751 135.991 60.819 135.991C56.4629 135.991 52.9316 139.52 52.9316 143.874C52.9316 148.228 56.4629 151.758 60.819 151.758Z"
          fill="#FFB9B9"
        />
        <path
          d="M59.5044 149.568C59.5044 149.568 58.1898 155.7 59.0662 155.7C59.9426 155.7 52.4934 159.641 52.4934 159.641L46.7969 158.765L45.0442 156.138C45.0442 156.138 54.2461 150.006 54.2461 146.502C54.2461 142.998 59.5044 149.568 59.5044 149.568Z"
          fill="#FFB9B9"
        />
        <path
          d="M55.5605 155.7C55.5605 155.7 56.875 154.824 57.3132 154.824H60.8187C61.2569 154.824 66.9534 156.138 66.9534 156.138L70.4589 175.846C70.4589 175.846 64.3242 185.92 68.7061 193.803C68.7061 193.803 67.3916 192.927 61.2569 193.365C55.1223 193.803 44.1676 195.117 43.7294 195.117C43.2912 195.117 43.2912 191.613 43.7294 190.737C44.1676 189.862 43.2912 190.3 43.2912 188.11C43.2912 185.92 41.9767 181.978 41.9767 181.978L35.842 159.641C35.842 159.641 43.7294 155.262 45.044 155.7C46.3586 156.137 50.3022 157.451 51.1786 157.013C52.055 156.575 55.5605 155.7 55.5605 155.7Z"
          fill="#BE6F72"
        />
        <path
          d="M65.6389 156.576L66.9535 156.138C66.9535 156.138 69.5826 155.7 72.6499 158.327C75.7172 160.955 87.1101 168.839 87.1101 168.839C87.1101 168.839 91.492 172.343 90.1774 176.284C88.8629 180.226 86.6719 189.862 80.0991 189.424C80.0991 189.424 76.1554 194.679 73.9645 193.803C71.7736 192.927 70.0208 189.424 71.3354 188.986C72.6499 188.548 76.1554 184.168 76.1554 184.168L71.7736 175.846H70.459L65.6389 173.219L65.6389 156.576Z"
          fill="#BE6F72"
        />
        <path
          d="M39.3477 159.203L35.8422 159.641C35.8422 159.641 32.7749 160.955 33.213 166.649C33.6512 172.343 30.5839 198.183 31.8985 201.249C31.8985 201.249 27.5166 216.14 28.393 216.578C29.2694 217.016 34.5276 219.644 34.0894 217.892C33.6512 216.14 38.9095 203.877 38.9095 203.877C38.9095 203.877 45.0441 197.307 42.415 188.11V180.664L39.3477 159.203Z"
          fill="#BE6F72"
        />
        <path
          d="M67.3833 151.496L61.8128 152.709L61.0969 149.968L61.3722 152.805L52.3857 154.762L51.9905 153.248L52.1425 154.815L46.3867 156.068L45.1554 144.255C43.8896 139.407 48.5095 134.24 53.3596 132.975L55.5112 132.414C59.0734 131.484 65.5485 132.329 66.4782 135.89C64.8509 142.097 62.077 148.903 67.3833 151.496Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="406" height="305" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
