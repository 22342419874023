import { unitSymobol } from "../utils/utils";
import React, { useMemo, useState } from "react";
import moment from "moment";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    TimeScale,
    LineElement,
    Title,
    Tooltip,
    Legend
)
function TodayLineGraph({
    datas,
    unit,
    timestamps,
    id,
    type,
    setClickedElement,
}) {
    const dateFormat = "DD MMM YYYY";
    const timeFormat = "LT"
    const maxCalculations = (type) => {
        if (type.toLowerCase().includes('temperature'))
            return 40
        else if (type.toLowerCase().includes('humidity'))
            return 100
        else if (type.toLowerCase().includes('water sense'))
            return 2
        else if (type.toLowerCase().includes('water'))
            return 120


        return 100
    }

    return (
        <Line id={id}

            data={datas}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: (item) => {
                                return item.raw + ' ' + (unit ? unitSymobol(unit) : "")
                            }
                        }
                    },
                },
                // onHover: (evt, elem) => {
                //     if (!elem.length) return;
                //     console.log({ evt, elem });
                //     let idx = elem[0].index

                //     setClickedElement({ date: moment(timestamps[idx]).format(dateFormat), time: moment(timestamps[idx]).format(timeFormat) })
                // },
                // hover: {
                //   mode: 'index',
                //   intersect: false,
                //   axis: 'x',

                //   // onHover: (evt, elem) => {
                //   //   if (!elem.length) return;
                //   //   let idx = elem[0]._index
                //   //   setClickedElement({ date: moment(timestamps[idx]).format(dateFormat), time: moment(timestamps[idx]).format(timeFormat), value: data[idx] })
                //   // }
                // },
                elements: {
                    point: {
                        radius: 0,
                    }
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                    },
                },
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    x: {
                        type: "time",
                        time: {
                            displayFormats: {
                                quarter: "MMM YYYY",
                            },
                            tooltipFormat: "lll",
                            unit: "hour",
                            minUnit: "hour",
                        },
                        ticks: {
                            font: {
                                weight: 'bold'
                            },
                            stepSize: 1,
                            padding: 10
                        }
                    },
                    y: {
                        position: 'right',
                        beginAtZero: true,
                        max: maxCalculations(type),
                        ticks: {
                            callback: function (value, index, values) {
                                return value + ' ' + (unit ? unitSymobol(unit) : "");
                            },
                        },
                    }
                },
            }}
        />
    )
}

export default TodayLineGraph