import React from "react";

export default function WaterLevel() {
  return (
    <svg
      enableBackground="new 0 0 497.41 497.41"
      viewBox="0 0 497.41 497.41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m488.415 296.418-9.405 7.237c-38.03 29.26-90.98 29.26-129 0l-14.21-10.93c-2.473-1.902-5.917-1.902-8.39 0l-14.21 10.93c-38.02 29.26-90.97 29.26-129 0l-14.2-10.93c-2.48-1.9-5.92-1.9-8.4 0l-14.2 10.93c-38.03 29.26-90.98 29.26-129 0l-9.405-7.237c-3.674-2.827-8.995-.208-8.995 4.428v94.599l31.815 50.807 127.539 15.716 308.27-3.627 29.786-62.896v-94.599c0-4.636-5.321-7.255-8.995-4.428z"
        fill="#d4f2f6"
      />
      <g>
        <path
          d="m41.022 397.027 44.323 67.516c1.654 2.519 5.346 2.519 7 0l44.323-67.516c1.828-2.784-.169-6.484-3.5-6.484h-23.128v-191.863c0-2.937-2.381-5.318-5.318-5.318h-31.754c-2.937 0-5.318 2.381-5.318 5.318v191.862h-23.128c-3.331 0-5.328 3.701-3.5 6.485z"
          fill="#e3817f"
        />
        <g>
          <path
            d="m71.448 416.888c-4.049-6.168.375-14.366 7.754-14.366 7.566 0 13.7-6.134 13.7-13.7v-178.162c0-2.937 2.381-5.318 5.318-5.318h11.821v-6.662c0-2.937-2.381-5.318-5.318-5.318h-31.755c-2.937 0-5.318 2.381-5.318 5.318v191.862h-23.128c-3.331 0-5.328 3.7-3.5 6.484l44.323 67.516c1.654 2.519 5.346 2.519 7 0l5.194-7.911z"
            fill="#dd6a66"
          />
        </g>
        <g id="XMLID_152_">
          <g>
            <path
              d="m497.41 395.446v81.519c0 5.12-4.151 9.271-9.271 9.271h-478.868c-5.12 0-9.271-4.151-9.271-9.271v-81.519l18.4 14.16c38.02 29.25 90.97 29.25 129 0l14.2-10.93c2.48-1.91 5.92-1.91 8.4 0l14.2 10.93c38.03 29.25 90.98 29.25 129 0l14.21-10.93c2.47-1.91 5.92-1.91 8.39 0l14.21 10.93c38.02 29.25 90.97 29.25 129 0z"
              fill="#85d6e4"
            />
          </g>
        </g>
        <path
          d="m92.345 464.543 25.575-38.958c-17.905 6.282-37.101 7.594-55.559 3.948l22.983 35.01c1.655 2.518 5.348 2.518 7.001 0z"
          fill="#2ed1e2"
        />
        <path
          d="m457.838 80.579-44.323-67.516c-1.654-2.519-5.346-2.519-7 0l-44.323 67.516c-1.828 2.784.169 6.484 3.5 6.484h23.128v191.862c0 2.937 2.381 5.318 5.318 5.318h31.755c2.937 0 5.318-2.381 5.318-5.318v-191.861h23.128c3.33 0 5.327-3.701 3.499-6.485z"
          fill="#c3e87d"
        />
        <g fill="#f1a940">
          <path d="m332.933 115.891c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-11.146v-55.064h11.146c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-18.646c-4.142 0-7.5 3.358-7.5 7.5v210.191c0 4.142 3.358 7.5 7.5 7.5h18.646c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-11.146v-55.064h11.146c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-11.146v-55.064h11.146z" />
          <path d="m176.573 200.862c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-18.646c-4.142 0-7.5 3.358-7.5 7.5v140.128c0 4.142 3.358 7.5 7.5 7.5h18.646c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-11.146v-55.064h11.146c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-11.146v-55.063h11.146z" />
        </g>
      </g>
    </svg>
  );
}
