import React, { useState } from "react";
import { unitSymobol } from "../utils/utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  LineElement,
  Title,
  Tooltip,
  Legend
)

function WeeklyGraph({ timestamps, data, unit, id, type }) {
  const borewellTrend = type.toLowerCase().includes('bore') || type.toLowerCase().includes('pump')

  const maxCalculations = (type) => {
    if (type.toLowerCase().includes('temperature'))
      return 40
    else if (type.toLowerCase().includes('humidity'))
      return 100
    else if (type.toLowerCase().includes('water sense') || type.toLowerCase().includes('pump') || type.toLowerCase().includes('bore'))
      return 1.5
    else if (type.toLowerCase().includes('water'))
      return 120


    return 100
  }



  const datas = {
    labels: timestamps,
    datasets: [
      {
        tension: borewellTrend ? 0 : undefined,
        label: "",
        data: data,
        fill: false,
        borderColor: "#339989DE",
      },
    ],
  };

  const options = {

    maintainAspectRatio: true,
    responsive: true,
    elements: {
      point: {
        radius: 0,
      },
    },
    // hover: {
    //   mode: 'nearest',
    //   intersect: false,
    //   onHover: (evt, elem) => {
    //     if (!elem.length) return;
    //     let idx = elem[0]._index
    //     setClickedElement({ date: moment(timestamps[idx]).format(dateFormat), time: moment(timestamps[idx]).format(timeFormat), value: data[idx] })
    //   }
    // },
    tooltips: {
      // enabled: false,
      mode: 'nearest',
      intersect: false,
      callbacks: {
        label: (item) => `${item.yLabel} ${unit ? unitSymobol(unit) : ""}`,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    legend: {
      display: false,
    },

    scales: {
      xAxes: [
        {

          //   scaleLabel: {
          //     display: true,
          //     labelString: "Time",
          //   },

          type: "time",
          time: {
            tooltipFormat: "DD MMM h:mm A",
            unit: "hour",
            stepSize: 6,

            displayFormats: {
              'hour': 'ddd ha',
            },
          },
          ticks: {
            padding: 10,
            fontWeight: "bold",
            fontColor: "#000",
            // callback: function (value, index, values) {
            //   return <b>
            //     {value}
            //   </b>
            // },
          }
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: false,
            // labelString: `( ${unit === 'percent' ? "%" : unit === 'celsius' ? '°C' : unit})`
          },
          ticks: {
            fontWeight: "bold",
            fontColor: "#000",
            beginAtZero: true,
            max: maxCalculations(type),
            // stepSize: 5,
            callback: function (value, index, values) {
              return value + ' ' + (unit ? unitSymobol(unit) : "")
            },
          },
        },
      ],
    },
  };


  return <div style={{
    width: "100%",
    height: "300px"
  }}>
    <Line
      id={id} data={datas} options={{
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: borewellTrend ? 'auto' : false,
            color: 'black',
            font: {
              weight: 'bold'
            },
            align: 'top',
            // anchor: 'end',
            // rotation: 90,
            formatter: function (value, context) {
              return moment(timestamps[context.dataIndex]).format('h:mm a')
            }
          },
          tooltip: {
            callbacks: {
              label: (item) => {
                if (borewellTrend) {
                  return item.raw === 1 ? 'ON' : 'OFF'
                }
                return item.raw + ' ' + (unit ? unitSymobol(unit) : "")
              }
            }
          },
        },
        interaction: {
          mode: 'index',
          intersect: false
        },
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },
        spanGaps: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0,
          },
        },
        scales: {
          x: {
            type: "time",
            time: {
              tooltipFormat: "DD MMM h:mm A",
              unit: "hour",
              minUnit: "hour",
              displayFormats: {
                'hour': 'ddd ha',
              },
            },
            ticks: {
              font: {
                weight: 'bold'
              },
              stepSize: borewellTrend ? 2 : 6,
              padding: 10
            }
          },
          y: {
            max: maxCalculations(type),
            beginAtZero: true,
            ticks: {
              font: {
                weight: 'bold'
              },
              stepSize: borewellTrend ? 1 : null,
              callback: function (value, index, values) {
                if (borewellTrend) {
                  return value === 1 ? 'ON' : value === 0 ? 'OFF' : ''
                }
                return value + ' ' + (unit ? unitSymobol(unit) : "")
              },
            },
          },
        },
      }} />
  </div>
}

export default WeeklyGraph;
