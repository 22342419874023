import React from "react";
import { Logo } from "../../assets/svg/index";
import styled from "styled-components";
import Loading from "./loading";
const Container = styled.div`
  background-color: var(--background);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LogoWrapper = styled.div`
  width: 30%;
  height: auto;
  margin: 1rem auto;
`;
export default function SplashScreen() {
  return (
    <Container>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Loading />
    </Container>
  );
}
