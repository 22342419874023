import React from "react";

export default function Thermometer() {
  return (
    <svg
      enableBackground="new 0 0 511.98 511.98"
      viewBox="0 0 511.98 511.98"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g fill="#4f667a">
          <path d="m315.494 61.06h75.774c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-75.774c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
          <path d="m315.494 95.689h37.887c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-37.887c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
          <path d="m391.269 115.689h-75.774c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h75.774c4.143 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
          <path d="m315.494 165.318h37.887c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-37.887c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
          <path d="m391.269 184.803h-75.774c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h75.774c4.143 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
          <path d="m315.494 234.432h37.887c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-37.887c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
          <path d="m391.269 254.569h-75.774c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h75.774c4.143 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
          <path d="m353.381 289.198h-37.887c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h37.887c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
        </g>
        <path
          d="m326.741 405.22c0 28.8-11.3 55.79-31.8 76.02-20.14 19.86-46.7 30.74-74.95 30.74-.51 0-1.02 0-1.53-.01-4.52-.07-8.99-.41-13.41-1.04-22.58-3.18-43.56-13.54-60.11-29.93-19.78-19.58-31.04-45.58-31.7-73.19-.89-37.42 17.88-72.49 49.24-92.56v-257.75c0-26.52 18.04-48.9 42.5-55.51 4.78-1.3 9.81-1.99 15-1.99 31.7 0 57.5 25.79 57.5 57.5v257.75c30.49 19.54 49.26 53.61 49.26 89.97z"
          fill="#e9f5ff"
        />
        <path
          d="m326.741 405.22c0 28.8-11.3 55.79-31.8 76.02-20.14 19.86-46.7 30.74-74.95 30.74-18.69 0-30 0-30 0 5.08 0 10.11-.35 15.06-1.05 22.54-3.16 43.38-13.4 59.89-29.69 20.51-20.23 31.8-47.22 31.8-76.02 0-36.36-18.77-40.25-49.26-59.79v-287.93c0-26.52-18.05-48.9-42.5-55.51 4.78-1.3 9.81-1.99 15-1.99 31.7 0 57.5 25.79 57.5 57.5v257.75c30.49 19.54 49.26 53.61 49.26 89.97z"
          fill="#cdeaf7"
        />
        <g>
          <path
            d="m296.741 405.37c0 20.7-8.12 40.11-22.87 54.65-14.47 14.28-33.61 22.11-53.96 22.11-.35 0-.69 0-1.03-.01-3.84-.05-7.64-.4-11.38-1.01-15.55-2.57-29.97-9.91-41.45-21.28-14.24-14.09-22.35-32.77-22.82-52.58-.66-27.97 13.94-54.11 38.1-68.23 6.87-4.01 11.15-11.46 11.15-19.42v-261.95c0-10.66 6.1-19.93 15-24.49 3.75-1.93 8-3.01 12.5-3.01 15.16 0 27.49 12.34 27.49 27.5v261.95c0 7.96 4.28 15.41 11.15 19.42 23.51 13.74 38.12 39.16 38.12 66.35z"
            fill="#ff6e6e"
          />
          <path
            d="m296.741 405.37c0 20.7-8.12 40.11-22.87 54.65-14.47 14.28-33.61 22.11-53.96 22.11-.35 0-.69 0-1.03-.01-3.84-.05-7.64-.4-11.38-1.01 15.57-2.54 29.93-9.8 41.37-21.09 14.75-14.54 22.87-33.95 22.87-54.65 0-27.19-14.61-52.61-38.12-66.35-6.87-4.01-11.15-11.46-11.15-19.42v-261.95c0-10.66-6.1-19.92-14.99-24.49 3.75-1.93 8-3.01 12.5-3.01 15.16 0 27.49 12.34 27.49 27.5v261.95c0 7.96 4.28 15.41 11.15 19.42 23.51 13.74 38.12 39.16 38.12 66.35z"
            fill="#f44e92"
          />
        </g>
        <path
          d="m247.471 57.65v65.54h-54.99v-65.54c0-10.66 6.1-19.93 15-24.49 3.75-1.93 8-3.01 12.5-3.01 15.16 0 27.49 12.34 27.49 27.5z"
          fill="#4f667a"
        />
        <path
          d="m247.471 57.65v65.54h-25v-65.54c0-10.66-6.1-19.92-14.99-24.49 3.75-1.93 8-3.01 12.5-3.01 15.16 0 27.49 12.34 27.49 27.5z"
          fill="#3a5366"
        />
      </g>
    </svg>
  );
}
