import React from "react";
import styled from "styled-components";
import { Profile } from "../../assets/svg";
import Loading from "../utils/loading";
import UpdateUserData from "./updateUserData";
import { editAccess } from "../utils/utils";
const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SvgWrapper = styled.div`
  width: 80%;
  z-index: 1;
`;

const Content = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: -2rem;
  background-color: var(--secondary);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Name = styled.h1`
  color: var(--primary);
  margin: 1rem auto;
`;
const Email = styled.h3`
  color: var(--primary);
  margin: 0.8rem auto;
`;

const StyledNote = styled.p`
  margin: 0.5rem auto;
  font-size: 0.7rem;
`;

export default function UserData({
  loading,
  userData,
}) {


  return (
    <Container>
      <SvgWrapper>
        <Profile />
      </SvgWrapper>
      {loading ? (
        <Loading />
      ) : (
        <Content>
          <Name>Hello, {userData.name}</Name>
          <Email>{userData.email}</Email>

          {editAccess(userData) && (
            <UpdateUserData
              contact={userData.contact}
              address={userData.address}
            />)}
        </Content>
      )}

      <StyledNote>Note: Please keep the Contact details updated.</StyledNote>
    </Container>
  );
}
