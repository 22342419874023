import React from "react";
import "./loading.css";
export default function Loading() {
  return (
    <div className="container">
      <div className="spinner">
        <div className="inner one"></div>
        <div className="inner two"></div>
        <div className="inner three"></div>
      </div>
    </div>
  );
}
