import React from "react";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
const Gauge = ({ radius = 20, value = 0, sensor_color, ...props }) => {
  const fillColor = color(sensor_color || "#000").toString();

  return (
    <LiquidFillGauge
      {...props}
      width={radius}
      height={radius}
      value={value}
      percent="%"
      textSize={0.8}
      textOffsetX={0}
      textOffsetY={0}
      textRenderer={({ value, width, height, textSize, percent }) => {

        const val = value

        value = Math.round((value + Number.EPSILON) * 100) / 100;
        const radius = Math.min(height / 2, width / 2);
        const textPixels = (textSize * radius) / 2;
        const valueStyle = {
          fontSize: textPixels,
        };
        const percentStyle = {
          fontSize: textPixels * 0.6,
        };
        if (val === value)
          return (
            <tspan>
              <tspan className="value" style={valueStyle}>
                {value}
              </tspan>
              <tspan style={percentStyle}>{percent}</tspan>
            </tspan>
          );
      }}
      riseAnimation
      waveAnimation
      waveFrequency={2}
      waveAmplitude={1}
      circleStyle={{
        fill: fillColor,
      }}
      waveStyle={{
        fill: fillColor,
      }}
      textStyle={{
        fill: color("#444").toString(),
        fontFamily: "montserrat-semi-bold",
      }}
      waveTextStyle={{
        fill: color("#fff").toString(),
        fontFamily: "montserrat-semi-bold",
      }}
    />
  );
};

export default function WaterFill({ value, color }) {
  return <Gauge radius={110} value={value} sensor_color={color} />;
}
