import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GoLocation } from "react-icons/go";
import { MdUpdate } from "react-icons/md";
import { RiSignalWifi3Fill, RiSignalWifiOffLine } from "react-icons/ri";
import styled, { css, keyframes } from "styled-components";

import { useHistory } from "react-router-dom";
import { GlobalState } from "../../GlobalState";
import Tooltip from "../utils/Tooltip";
import Loading from "../utils/loading";
import WaterSense from "../sensor_box/WaterSense";
import { literToKiloliter } from "../utils/utils";
// const Container = styled.div`
//   @media (min-width: 61.313em) {
//     display: flex;
//   }
// `;
const SensorWrapper = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;  
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem;

  border: 2px solid #fff;
  background-color: #fff;
  &:hover {
    border: 2px solid var(--primary);
  }
  width: 260px;
  margin: 0.3rem;
`;


const SensorValue = styled.h6`
  font-family: "montserrat-semi-bold";
  font-size: 1.2rem;
 
`;
const SensorUnit = styled.span`
  font-family: "montserrat-semi-bold";
  @media (min-width: 61.313em) {
    font-size: 1rem;
  }
`;

const SensorIconWrapper = styled.div`
  height: auto;
  margin: 0 auto;
  @media (min-width: 61.313em) {
    margin: 0.7rem auto;
  }
`;

const TimeElapsed = styled.p`
  font-size: 0.7rem;
  font-weight:bold;
  text-align: end;
  display: flex;
  align-items: center;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;
const LeftWrapper = styled.div`

display: flex;
flex-direction: column;
justify-content: space-between;
`

const RippleAnimation = keyframes`
  0% {box-shadow: 0 0 0px 0px #1AA3EC66;}
  100% {box-shadow: 0 0 0px 15px rgba(101, 255, 120, 0);}
`

const WaterSenseLight = styled.div`
width: 25px;
height: 12px;
border-radius:20%;
background-color: ${props => props.pumpState === 1 ? `#1AA3EC` : `#d9594c`};
  ${props => props.pumpState === 1 && css`
    {animation: ${RippleAnimation} 0.7s linear infinite;}`
  }
`

const SensorPercentage = styled.span`
  font-family: "montserrat-semi-bold";
  color: ${props => props.color};
  margin: 0 0.2rem;
`;

const LocationName = styled.h5`
  word-wrap: break-word;
  max-width: 90%;
`;
const WaterTankWrapper = styled.div`
  border: 1.5px solid #808080;
  border-radius: 5px;
  padding: 0.6rem 0.3rem;
  display: ${props => props.type?.includes("water-level") ? 'flex' : 'none'};
  width: 100px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.color};
  ${props => props.location === 'Terrace' && css`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  `}

  ${props => props.value === 0 && css`
    display: none;
`}

`;

const WaterFlowWrapper = styled.div`
display: flex;
align-items: flex-end;
`

export default function DashboadSensorBoxV2({ sensor_id, type }) {
  const state = useContext(GlobalState);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  // const sensor_i = sensor_id || match.params.sensor_id
  useEffect(() => {


    async function fetchData(token, sensor_id) {
      try {
        const sensorReading = await axios.get(`${process.env.REACT_APP_API}/sensor/reading?sensor_id=${sensor_id}&type=${type}`,
          {
            headers: { Authorization: token[0] },
          }
        );

        if (sensorReading) {
          setList(sensorReading.data);
          setLoading(false);
        }

      } catch (err) {
        console.log(err.message);
      }
    }
    fetchData(state.token, sensor_id);

    const interval = setInterval(() => {
      fetchData(state.token, sensor_id);
    }, 8000);

    return () => clearInterval(interval);
  }, [state.token, sensor_id, type]);
  if (loading)
    return (
      <Loading />
    );

  return (
    <SensorWrapper
      onClick={() => {
        history.push(`/sensor/${sensor_id}/${type}`)
      }}
    >

      <LeftWrapper>
        <div style={{ display: 'flex', }}>
          {list.sensor_status === "online" ? (
            <RiSignalWifi3Fill size={20} color={'green'} />
          ) : (
            <Tooltip
              content={
                <>
                  Sensor is offline.
                </>
              }
              direction="right"
              delay="0"
            >
              <RiSignalWifiOffLine size={20} color={'red'} />
            </Tooltip>
          )} &nbsp;<LocationName>{list.location_name ?? list.location}</LocationName>
        </div>
        <TimeElapsed><MdUpdate /> &nbsp; {list.date} &bull; {list.time}</TimeElapsed>
      </LeftWrapper>



      <WaterTankWrapper
        type={list?.name}
        color={list?.volume_color}
        location={list?.location}
        value={parseFloat(list.value)}
      >
        <div>
          {list?.volume && !(list?.volume == 0) && <SensorPercentage color={"#fff"}> {parseFloat(list?.volume).toFixed(0)}%</SensorPercentage>}
        </div>

        <SensorValue style={{ color: "#fff" }}>
          <SensorUnit>
            {list.unit === "celsius"
              ? parseFloat(list.value).toLocaleString() + "°C"
              : list.unit === "litre"
                ? literToKiloliter(list.value) : list.unit === "percent" ?
                  parseFloat(list.value).toLocaleString() + "%" : parseFloat(list.value).toLocaleString() + ""}

          </SensorUnit>
        </SensorValue>

      </WaterTankWrapper>

      {
        (list?.name === "temperature" || list?.name === "humidity") && (
          <SensorValue style={{ "color": list?.color }}>
            <SensorUnit>
              {list.unit === "celsius"
                ? parseFloat(list.value).toLocaleString() + "°C"
                : list.unit === "percent" ?
                  parseFloat(list.value).toLocaleString() + "%" : parseFloat(list.value).toLocaleString() + ""}
            </SensorUnit>
          </SensorValue>
        )}
      <WaterFlowWrapper>
        {list?.name === "water-sense" &&
          <WaterSenseLight pumpState={parseInt(list?.value ?? 0)} />
        }
      </WaterFlowWrapper>
    </SensorWrapper >
  );
}
