import moment from "moment";
import React from "react";
import { literToKiloliter } from "../utils/utils";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    BarController,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarController,
    TimeScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
)

const bgColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    // 'rgba(255, 99, 132, 0.2)',

]
const borderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    // 'rgba(255, 99, 132, 1)',
]
function WeeklyBarChart({ dates, water_data, id, hourly = false }) {

    let dates_array = dates && !hourly ? dates.map(date => moment(date).format("DD MMM")) : dates;

    // console.log({ water_data, dates_array });

    const datas = {
        labels: dates_array,
        datasets: [
            {
                // label: "",
                data: water_data,
                backgroundColor: new Array(50).fill(bgColor).flat(),
                borderColor: new Array(50).fill(borderColor).flat(),
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            callbacks: {
                label: (item) => literToKiloliter(item.value),
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 0,
            },
        },
        legend: {
            display: false,
        },
        scales: {
            // xAxes: [
            //     {
            //         type: "time",
            //         time: {
            //             tooltipFormat: "ll",
            //             unit: "day",
            //             displayFormats: {
            //                 day: "dddd",
            //             },
            //             parser: "yyyy-mm-dd hh:mm:ss"
            //         },
            //     },
            // ],
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: `Litre`
                    },
                    ticks: {
                        beginAtZero: true,
                        // max: 100
                    },
                },
            ],
        },
    };

    return <div style={{
        width: "100%",
        height: "300px"
    }}>
        <Bar
            id={id}
            data={datas}
            options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: (item) => {
                                return literToKiloliter(item.raw);
                            }
                        }
                    },
                    datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter:
                            function (value, context) {
                                return literToKiloliter(value);
                            },
                        font: {
                            weight: 'bold'
                        }

                    }
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0,
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            font: {
                                weight: 'bold'
                            },
                        },
                        beginAtZero: true,
                    },
                },
            }} />
    </div>
}

export default WeeklyBarChart;
