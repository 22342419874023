import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GoLocation } from "react-icons/go";
import { MdUpdate } from "react-icons/md";
import { RiSignalWifiOffLine, RiSignalWifi3Fill } from "react-icons/ri";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { HumidityIcon } from "../../assets/icons";
import { GlobalState } from "../../GlobalState";
import ThermometerAnim from "../sensor_box/Thermometer";
import WaterFill from "../sensor_box/WaterFill";
import Loading from "../utils/loading";
import Tooltip from "../utils/Tooltip";
import WaterSense from "../sensor_box/WaterSense";
import { literToKiloliter } from "../utils/utils";
// const Container = styled.div`
//   @media (min-width: 61.313em) {
//     display: flex;
//   }
// `;
const SensorWrapper = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;  
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;

  border: 2px solid #fff;
  background-color: #fff;
  &:hover {
    border: 2px solid var(--primary);
  }
  min-width: 260px;
  margin-right: 1rem;
`;


const SensorValue = styled.h6`
  font-family: "montserrat-semi-bold";
  font-size: 1.2rem;
 
`;
const SensorUnit = styled.span`
  font-family: "montserrat-semi-bold";
  @media (min-width: 61.313em) {
    font-size: 1rem;
  }
`;

const SensorIconWrapper = styled.div`
  height: auto;
  margin: 0 auto;
  @media (min-width: 61.313em) {
    margin: 0.7rem auto;
  }
`;

const TimeElapsed = styled.p`
  margin: 0.5rem;
  font-size: 0.7rem;
  font-weight:bold;
  text-align: end;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;
const Footer = styled.div`

display: flex;
justify-content: space-between;
align-items: center;
`


export default function DashboardSensorBox({ sensor_id, type }) {
  const state = useContext(GlobalState);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  // const sensor_i = sensor_id || match.params.sensor_id
  useEffect(() => {


    async function fetchData(token, sensor_id) {
      try {
        const sensorReading = await axios.get(`${process.env.REACT_APP_API}/sensor/reading?sensor_id=${sensor_id}&&type=${type}`,
          {
            headers: { Authorization: token[0] },
          }
        );

        if (sensorReading) {

          setList(sensorReading.data);
          setLoading(false);
        }

      } catch (err) {
        console.log(err.message);
      }
    }
    fetchData(state.token, sensor_id);

    const interval = setInterval(() => {
      fetchData(state.token, sensor_id);
    }, 8000);

    return () => clearInterval(interval);
  }, [state.token, sensor_id, type]);
  if (loading)
    return (
      <Loading />
    );

  return (
    <SensorWrapper
      onClick={() => {
        history.push(`/sensor/${sensor_id}/${type}`)
      }}
    >

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>

        <div style={{ display: 'flex' }}>
          <GoLocation /> &nbsp;<h5 >{list.location_name ?? list.location}</h5>
        </div>
        <div>
          {list.sensor_status === "online" ? (
            <RiSignalWifi3Fill size={20} color={'green'} />
          ) : (
            <Tooltip
              content={
                <>
                  Sensor is offline.
                </>
              }
              direction="left"
              delay="0"
            >
              <RiSignalWifiOffLine size={20} color={'red'} />
            </Tooltip>
          )}
        </div>
      </div>

      <SensorIconWrapper>
        {
          list.name.includes("temperature") ? (
            <ThermometerAnim value={parseFloat(list.value)} />
          ) : list.name.includes("water-level") || list.name.includes("diesel-level") ? (
            <WaterFill
              value={parseFloat(list.volume).toFixed(0)}
              color={list.volume_color}
            />
          ) : list.name.includes("humidity") ? (

            <div style={{
              minHeight: '100px',
              minWidth: '100px',
            }}>

              <HumidityIcon />
            </div>

          ) : list.name.includes("water-sense") ? (
            <>
              <WaterSense value={parseInt(list.value)} />
            </>
          ) : (
            <></>
          )
        }

      </SensorIconWrapper>

      <Footer><TimeElapsed><MdUpdate /> {list.date} &bull; {list.time}</TimeElapsed>

        {list.name.includes("water-sense") && (<></>)}
        {list.name.includes("water-level") ? (<SensorValue style={{ color: `${list.color} ` }}>
          {literToKiloliter(list.value)}
        </SensorValue>) :
          <SensorValue style={{ color: `${list.color} ` }}>
            {parseFloat(list.value).toLocaleString()}
            &nbsp;
            <SensorUnit>
              {list.unit === "celsius"
                ? "°C"
                : list.unit === "litre"
                  ? "L" : list.unit === "percent" ?
                    "%" : ""}
            </SensorUnit>
          </SensorValue>}

      </Footer>
    </SensorWrapper>
  );
}
