import React, { useState, useContext } from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import axios from "axios";
import { GlobalState } from "../../GlobalState";
import Handle from "./Handle";
import Track from "./Track";
import styled from "styled-components";

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  margin: 1rem auto;
  padding: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 61.313em) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const sliderStyle = {
  position: "relative",
  width: "100%",
};
const UpdateBtn = styled.button`
  margin-top: 5rem;
  cursor: pointer;
  color: var(--background);
  background-color: var(--primary);
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.3rem 1rem;
  border-radius: 20px;
  @media (min-width: 61.313em) {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }

  &:disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
  }
`;
const SliderWrapper = styled.div`
  width: 100%;

  margin-top: -1.5rem;
  @media (min-width: 61.313em) {
    width: 80%;
  }
`;
const railStyle = {
  position: "absolute",
  width: "100%",
  height: 7.5,
  marginTop: 35,
  borderRadius: 5,
  backgroundColor: "#C4C4C4",
};

export default function DoubleRangeSlider({
  defautvalues,
  sensor_id,
  ...props
}) {

  const onChange = (values) => {
    setvalues({ values });
  };
  const state = useContext(GlobalState);

  const handleSubmit = async (e) => {
    e.preventDefault();

    seterr("updating....")

    const updates = props.type ? props.type.toLowerCase().includes('temperature')
      ? { lower_limit_1: values.values[0], upper_limit_1: values.values[1] }
      : props.type.toLowerCase().includes('humidity')
        ? { lower_limit_2: values.values[0], upper_limit_2: values.values[1] }
        : { lower_limit: values.values[0], upper_limit: values.values[1] }
      : { lower_limit: values.values[0], upper_limit: values.values[1] };

    const res = await axios.put(
      `${process.env.REACT_APP_API}/sensor/update_limit?sensor_id=${sensor_id}`,
      updates,
      {
        headers: { Authorization: state.token[0] },
      }
    );
    if (res.status === 200) {
      seterr(res.data.message)
    }

    setTimeout(() => {
      seterr("")
    }, 3000);
  };
  const [values, setvalues] = useState(defautvalues);
  const [err, seterr] = useState(null)
  return (
    <>
      <Container>
        <SliderWrapper>
          {props.name}
          <Slider
            disabled={props.disabled}
            rootStyle={sliderStyle}
            domain={[0, 100]}
            step={1}
            mode={2}
            onChange={onChange}
            values={defautvalues}
          >
            <Rail>
              {({ getRailProps }) => (
                <div style={railStyle} {...getRailProps()} />
              )}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
        </SliderWrapper>
        <UpdateBtn
          disabled={props.disabled}
          onClick={handleSubmit}>{err ? err : "Update"}</UpdateBtn>

      </Container>
    </>
  );
}
