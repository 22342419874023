import React from 'react'
import styled from "styled-components";
import { literToKiloliter, capitalizeFirstLetter } from '../utils/utils';

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 61.313em) {
    width: 60%;
    justify-content: space-between;
  }
`;
const LineItems = styled.div`
  margin: 0.8rem 0;
  display: flex;
  justify-content: space-between;
`;

const ValuleStyle = styled.span`
  font-weight: bold;
`;

const TankDetails = ({ dimension }) => {
  console.log(dimension);
  return (
    <Container>
      <h5>Tank Details</h5>

      <LineItems>
        Tank Shape
        <ValuleStyle>{capitalizeFirstLetter(dimension?.tank_shape ?? "")}</ValuleStyle>
      </LineItems>
      <hr />
      <LineItems>
        Tank Capacity
        <ValuleStyle>{literToKiloliter(dimension?.tank_volume || 0)}</ValuleStyle>
      </LineItems>

    </Container>
  )
}

export default TankDetails