import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import {
  BackIcon
} from "../../assets/icons";
import { Sensor } from "../../assets/svg/index";
import Banner from "../utils/banner";
import PumpStatus from "./PumpStatus";
import { GlobalState } from "../../GlobalState";
import Loading from "../utils/loading";
import SingleRangeSlider from "./SingleRangeSlider";
import TodayReport from "../report/TodayReport";
import WeeklyReportV2 from "../report/WeeklyReportV2";
import moment from "moment";
import { editAccess } from "../utils/utils";
const Container = styled.div`
  margin: auto;
  width: 90%;

  @media (min-width: 61.313em) {
    width: 80%;
  }
`;



const BackIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    border: 2px solid var(--primary);
  }
  @media (min-width: 61.313em) {
    width: 55px;
    height: 55px;
  }
`;

const RuntimeFormWrapper = styled.div`
margin: 1rem;
width: 80%;
@media (min-width: 61.313em) {
  width: 30%;
}
`

const DataWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 61.313em) {
  flex-direction: row;
  align-items: center;


  }
`;

const StyledInput = styled.input`
  width: 30%;
  // margin-top: 0.5rem;
  margin : 0.2rem auto;
  padding: 0.6rem;
  font-size: 1rem;
  background-color: #eaeaea;
  border-radius: 10px;
  display: flex;
  align-items: center;
  @media (min-width: 61.313em) {
    width: 60%;
  }
`;

const InputWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.3rem;
  width: 100%;

  @media (min-width: 61.313em) {
   flex-direction: row;

  }
 
`;
const StyledBtn = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--primary);
  color: var(--background);
  padding: 0.6rem 1.3rem;
  border-radius: 20px;
  font-size: 1.2rem;
  margin: 0 0.5rem;

  &:disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
  }
`;

const DivWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

const ControlsWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  width: 100%;
  @media (min-width: 61.313em) {
    width: 60%;
  }
 `;


export default function MotorData({ match }) {
  const history = useHistory();
  const state = useContext(GlobalState);

  const sensor_id = match.params.sensor_id;
  const motor_id = match.params.motor_id;
  const sensor_type = match.params.sensor_type;

  const [values, setValues] = useState({ start_time: "", end_time: "", runtime: "", water_limit: "" });
  const [sensorData, setSensorData] = useState({})

  const [loading, setLoading] = useState(true);
  const [motorData, setMotorData] = useState(false);

  async function fetchData(token) {
    try {
      const motor = await axios.get(`${process.env.REACT_APP_API}/sensor/motor?sensor_id=${sensor_id}`, {
        headers: { Authorization: token[0] },
      });

      setLoading(false);

      if (motor.data) {
        setValues({
          start_time: motor?.data?.data?.preferences?.start_time,
          end_time: motor?.data?.data?.preferences?.end_time,
          runtime: motor?.data?.data?.runtime,
          water_limit: motor?.data?.data?.water_limit
        })
      }

      setMotorData(motor.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSensorData(token, sensor_id) {
    try {
      const sensorData = await axios.get(`${process.env.REACT_APP_API}/sensor/data?sensor_id=${sensor_id}`,
        {
          headers: { Authorization: token[0] },
        }
      );
      if (sensorData) {
        setSensorData(sensorData.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchSensorData(state.token, sensor_id);
    fetchData(state.token);
  }, [state.token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const submitMotorUpdate = async (e) => {
    const { start_time, end_time, runtime, water_limit } = values;


    if ((start_time || end_time) && (start_time > end_time)) {
      alert("Start time cannot be greater than end time.");
      return;
    }

    const res = await axios.patch(
      `${process.env.REACT_APP_API}/sensor/motor?motor_id=${motor_id}`,
      {
        preferences: start_time || end_time ? {
          start_time: start_time,
          end_time: end_time,
        } : undefined,
        runtime: runtime || undefined,
        water_limit: water_limit || undefined
      },
      {
        headers: { Authorization: state.token[0] },
      }
    );
    if (res.status === 200) {
      alert("Updated successfully.");
    }
  }

  if (loading) return <Loading />;
  return (
    <Container>
      <BackIconWrapper onClick={() => history.push("/")}>
        <BackIcon />
      </BackIconWrapper>

      <Banner
        title={
          <>
            {sensor_type}
          </>
        }
        subtitle={"Here you can find the detailed info about the borewell pump."}
      >
        <Sensor />
      </Banner>

      <DataWrapper>
        <PumpStatus
          isBorewell={true}
          sensor_id={sensor_id}
          motor_id={motor_id}
        />
        <ControlsWrapper>

          <InputWrapper onSubmit={(e) => {
            e.preventDefault();
            submitMotorUpdate(e);
          }
          }>
            <RuntimeFormWrapper>
              <h4>Runtime (In Minutes)</h4>
              <StyledInput
                disabled={!editAccess(state.userAPI)}
                name="runtime"
                placeholder="Enter in minutes"
                value={values.runtime}
                onChange={handleInputChange}
                required
                min={1}
                max={999}
                type="number"
              />
            </RuntimeFormWrapper>


            <SingleRangeSlider
              name="% Water Limit"
              defautvalues={
                motorData?.data?.water_limit ? [motorData.data.water_limit] : [45]}
              sensor_id={sensor_id}
              value={values.water_limit}
              onSubmit={(e) => {
                submitMotorUpdate(e);
              }}
              disabled={!editAccess(state.userAPI)}
              onChange={(e) => {
                setValues({ ...values, water_limit: e });
              }}
              type="motor" />


            {/* {
              sensorData?.type === 'Water Sense' &&
              (<>
                <DivWrapper>
                  <h4>Start Time</h4>
                  <StyledInput
                    name="start_time"
                    placeholder="Start Time"
                    value={values.start_time}
                    onChange={handleInputChange}
                    required
                    type="time"
                  />
                </DivWrapper>
                <DivWrapper>
                  <h4>End Time</h4>
                  <StyledInput
                    name="end_time"
                    placeholder="End Time"
                    value={values.end_time}
                    onChange={handleInputChange}
                    required
                    type="time"
                  />
                </DivWrapper>
              </>

              )
            } */}
            <StyledBtn
              disabled={!editAccess(state.userAPI)}
              type="submit" value="Submit">
              Update
            </StyledBtn>
          </InputWrapper >


        </ControlsWrapper>
      </DataWrapper>


      <WeeklyReportV2
        sensor_id={sensor_id}
        motor_id={motor_id}
        sensor_type={`Borewell Trend`}
        location={""}
        startDate={
          moment().format('YYYY-MM-DD')
        }
        endDate={
          moment().format('YYYY-MM-DD')
        }
        reportType={'Trend'}
      />

    </Container >
  );
}
