import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Footer from "../Footer/Footer";
import { Logo } from "../../assets/svg/index";
import { AiOutlineMail, AiFillLock } from "react-icons/ai";
import SplashScreen from "../utils/SplashScreen";

const Container = styled.div`
  padding: 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const LogoWrapper = styled.div`
  width: 150px;
  height: auto;
  margin:0 auto;

  @media (min-width: 61.313em) {
  margin:0;
  }
`;
const Main = styled.main`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Heading = styled.h1`
  margin: 1rem;
  font-family: "montserrat-semi-bold";
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #e0e8e6;
  padding: 0.6rem 1.1rem;
  margin: 1rem auto;
  border-radius: 10px;
  &:focus {
    border: 2px solid green;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.6rem;
  font-size: 1rem;
`;

const StyledBtn = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #ffd037;
  padding: 0.6rem 1.3rem;
  border-radius: 20px;
  font-size: 1.2rem;
`;
const FooterWrapper = styled.div`
  margin-top: auto;
`;
const ServerErr = styled.p`
  margin: 1rem auto;
  color: red;
`;
const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 61.313em) {
    width: 25%;
  }
`;
export default function Login() {
  const [Loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [err, seterr] = useState("");

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);


    try {

      // post email and password to server
      const res = await axios.post(`${process.env.REACT_APP_API}/user/login`, {
        ...user,
        mobileLogin: window && window?.flutter_inappwebview
      });

      if (res.status === 200) {
        // set token
        localStorage.setItem("accessToken", res.data.accesstoken);

        setLoading(false);
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);
      // set error
      err.response.data.message && seterr(err.response.data.message);
    }

  };

  useEffect(() => {
    // check if user is logged in
    const accessToken = localStorage.getItem("accessToken");

    // if user is logged in, redirect to home page
    if (accessToken) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, []);

  if (Loading) {
    return <SplashScreen />;
  }

  return (
    <Container>



      <LogoWrapper>
        <Logo />
      </LogoWrapper>

      <Main>
        <Heading>Login.</Heading>
        <StyledForm onSubmit={loginSubmit}>
          <InputWrapper>
            <AiOutlineMail size={30} />
            <StyledInput
              type="email"
              name="email"
              required
              placeholder="Email"
              value={user.email}
              onChange={onChangeInput}
            />
          </InputWrapper>
          <InputWrapper>
            <AiFillLock size={30} />
            <StyledInput
              type="password"
              name="password"
              required
              autoComplete="on"
              placeholder="Password"
              value={user.password}
              onChange={onChangeInput}
            />
          </InputWrapper>
          <StyledBtn type="submit" value="Submit">
            Submit.
          </StyledBtn>
        </StyledForm>
        <ServerErr> {err}</ServerErr>
      </Main>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Container>
  );
}
