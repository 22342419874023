import { createGlobalStyle } from "styled-components";

import MontserratRegular from "./Montserrat-Regular.ttf";
import MontserratBold from "./Montserrat-Bold.ttf";
import MontserratLight from "./Montserrat-Light.ttf";
import MontserratMedium from "./Montserrat-Medium.ttf";
import MontserratSemiBold from "./Montserrat-SemiBold.ttf";
import MontserratItalic from "./Montserrat-Italic.ttf";

const GlobalFonts = createGlobalStyle`
	@font-face {
		font-family: 'montserrat-bold';
		src: url('${MontserratBold}') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: 'montserrat-light';
		src: url('${MontserratLight}') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: 'montserrat-medium';
		src: url('${MontserratMedium}') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
    @font-face {
		font-family: 'montserrat-regular';
	    src: url('${MontserratRegular}') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
    @font-face {
		font-family: 'montserrat-semi-bold';
		src: url('${MontserratSemiBold}') format('truetype');
		font-weight: normal;
		font-style: normal;
	} @font-face {
		font-family: 'montserrat-italic';
		src: url('${MontserratItalic}') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
`;

export default GlobalFonts;
