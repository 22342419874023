import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { RiSignalWifiOffLine, RiSignalWifi3Fill } from "react-icons/ri";
import styled, { css, keyframes } from "styled-components";
import pump_image from "../../assets/images/water-pump.png";
import borewell_image from "../../assets/images/borewell_image.png";
import { GlobalState } from "../../GlobalState";
import ToggleButton from "../utils/ToggleButton";
import Tooltip from "../utils/Tooltip";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { editAccess, formatDate } from "../utils/utils";
import { GoLocation } from "react-icons/go";
import { MdUpdate } from "react-icons/md"
const PumpImg = styled.img`
width:50px;
height:100%;
max-height:50px;
object-fit:contain;
`

const BorewellPumpImg = styled.img`
width:30px;
height:100%;
max-height:50px;
margin-bottom:0.5rem;
`

const RippleAnimation = keyframes`
  0% {box-shadow: 0 0 0px 0px rgba(101, 255, 120, 0.5);}
  100% {box-shadow: 0 0 0px 30px rgba(101, 255, 120, 0);}
`

const PumpLight = styled.div`
width:12px;
height: 12px;
border-radius:50%;
background-color: ${props => props.pumpState ? `#00e8a3` : `#d9594c`};
  ${props => props.pumpState && css`
    {animation: ${RippleAnimation} 0.7s linear infinite;}`
    }
`

const PumpStateWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
/* margin: 0.5rem; */
`

const Container = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;  
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;

  border: 2px solid #fff;
  background-color: #fff;
  &:hover {
    border: 2px solid var(--primary);
  }
  min-width: 260px;
  margin: 0.3rem;
`;


const SensorStatusIndicator = styled.div`
width:100%;
display: flex;
justify-content: space-between;
`



const TimeElapsed = styled.p`
  margin: 0.5rem;
  font-size: 0.7rem;
  font-weight:bold;
  text-align: end;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;

const LocationText = styled.p`

    font-size: 1.2rem;
    font-weight:bold;
    text-align: center;
    margin: 0.1rem 0;
    @media (min-width: 61.313em) {
        text-align: end;
    }
`;

const ToggleWrapper = styled.div`
    margin : 0 auto;
`

const LocationName = styled.h5`
  word-wrap: break-word;
  max-width: 90%;
`;

const DummyWrapper = styled.div`
position: absolute;
z-index: 100;
top: 0%;
background-color: transparent;
width: 60%;
height: 100%

`;

export default function PumpStatusV2({ sensor_id, motor_id, isBorewell }) {
    const history = useHistory();
    // get path
    const location = useLocation();
    const isDetailPage = location.pathname.includes('/motor/')
    const state = useContext(GlobalState);

    const [pumpState, setPumpState] = useState(false)
    const [pumpData, setPumpData] = useState({})
    const [sensorStatus, setSensorStatus] = useState('online')
    const [pumpExist, setPumpExist] = useState(true)

    const locationName = !pumpData?.motor_status?.location_name ? 'Borewell' : pumpData?.motor_status?.location_name;

    async function togglePumpStatus(motor_id) {
        try {
            setPumpState(!pumpState)

            const pumpStatus = await axios.get(`${process.env.REACT_APP_API}/sensor/v2/toggle_pump_status?motor_id=${motor_id}`,
                {
                    headers: { Authorization: state.token[0] },
                }
            );

            // setPumpState(pumpStatus.data)

        } catch (err) {
            console.log(err.response.data.message);
        }
    }


    async function fetchData(token, motor_id) {
        try {

            const pumpStatus = await axios.get(`${process.env.REACT_APP_API}/sensor/v2/pump_status?motor_id=${motor_id}`,
                {
                    headers: { Authorization: token[0] },
                }
            );
            setPumpData(pumpStatus.data)
            setPumpState(parseInt(pumpStatus.data.motor_status.motor_on))
            setSensorStatus(pumpStatus.data.sensor_status)

        } catch (err) {
            if (err.response.status === 404) {
                setPumpExist(false)
            }

            console.log(err.response.data.message);
        }
    }

    useEffect(() => {
        fetchData(state.token, motor_id);
        const interval = setInterval(() => {
            fetchData(state.token, motor_id);
        }, 5000);

        return () => clearInterval(interval);
    }, [state.token, motor_id]);


    if (!pumpExist) return <></>

    return (
        <Container

        >
            <DummyWrapper
                className="test"
                onClick={
                    (e) => {
                        if (!isBorewell) return
                        // /motor/:motor_id/sensor/:sensor_id/:sensor_type
                        history.push(`/motor/${motor_id}/sensor/${sensor_id}/Pump - ${locationName}`)
                    }
                }
            >

            </DummyWrapper>
            <SensorStatusIndicator>
                <div style={{ display: 'flex' }}>
                    {sensorStatus === "online" ? (
                        <RiSignalWifi3Fill size={20} color={'green'} />

                    ) : (
                        <Tooltip
                            content={
                                <>
                                    Motor is offline.
                                </>
                            }
                            direction="right"
                            delay="0"
                        >

                            <RiSignalWifiOffLine size={20} color={'red'} />
                        </Tooltip>
                    )} &nbsp;<LocationName
                        onClick={
                            (e) => {
                                if (!isBorewell) return
                                // /motor/:motor_id/sensor/:sensor_id/:sensor_type
                                history.push(`/motor/${motor_id}/sensor/${sensor_id}/Pump - ${locationName}`)
                            }
                        }
                    >{locationName}</LocationName>
                </div>


            </SensorStatusIndicator>


            <TimeElapsed>
                <div>

                    <MdUpdate /> &bull; {formatDate(pumpData?.motor_status?.updatedAt?.split(" IST")[0])}
                </div>

                {
                    !isDetailPage && (
                        <ToggleButton
                            disabled={!editAccess(state.userAPI)}
                            id={motor_id}
                            checked={Boolean(pumpState)}
                            onChange={() => togglePumpStatus(motor_id)}
                        />
                    )
                }
            </TimeElapsed>
        </Container>
    )
}
