import { useState, useEffect } from "react";
import axios from "axios";

function UserAPI(token) {
  const [isLogged, setIsLogged] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (token) {
      const getUser = async () => {
        try {
          const user = await axios.get(`${process.env.REACT_APP_API}/user/data`, {
            headers: { Authorization: token },
          });
          setIsLogged(true);
          localStorage.setItem('org_id', user.data.org_id);
          setUserData({
            name: user.data.name,
            org_id: user.data.org_id,
            customer_admin: user.data?.customer_admin,
            email: user.data?.email,
            role: user.data?.role,
          });

        } catch (err) {
          localStorage.clear();
          window.location.reload();
          console.log(err.response.data.message);
        }
      };

      getUser();
    }
  }, [token]);

  return {
    isLogged: [isLogged, setIsLogged],
    ...userData,
  };
}

export default UserAPI;
