import React from "react";
import { Handles, Rail, Slider, Tracks } from "react-compound-slider";
import styled from "styled-components";
import Handle from "./Handle";
import Track from "./Track";

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  margin: 1rem auto;
  padding: 1.4rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 61.313em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const sliderStyle = {
  position: "relative",
  width: "100%",
};
const UpdateBtn = styled.button`
  margin-top: 5rem;
  cursor: pointer;
  color: var(--background);
  background-color: var(--primary);
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.3rem 1rem;
  border-radius: 20px;
  @media (min-width: 61.313em) {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }
`;
const SliderWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;
const railStyle = {
  position: "absolute",
  width: "100%",
  height: 8,
  // marginTop: 35,
  borderRadius: 5,
  backgroundColor: "#C4C4C4",
};

const handleStyle = {
  position: "absolute",
  width: "100%",
  marginTop: -35,
}

export default function SingleRangeSlider({
  defautvalues,
  sensor_id,
  water_limit,
  name,
  disabled,
  onSubmit,
  ...props
}) {

  const onChange = (values) => {
    props?.onChange(values?.[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      <Container>
        <h4>
          {name}
        </h4>
        <SliderWrapper>
          <Slider
            rootStyle={sliderStyle}
            domain={[0, 100]}
            step={1}
            mode={1}
            disabled={disabled}
            onChange={onChange}
            values={defautvalues}
          >
            <Rail>
              {({ getRailProps }) => (
                <div style={railStyle} {...getRailProps()} />
              )}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div style={handleStyle}>
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
        </SliderWrapper>
      </Container>
    </>
  );
}
