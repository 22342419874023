import React from "react";

export default function HomeIcon() {
  return (
    <svg viewBox="0 0 55 55" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16616 55C6.73514 55 4.40369 54.201 2.68471 52.7788C0.965718 51.3566 0 49.4277 0 47.4164V22.1376C0 22.0516 0.00611112 21.9657 0.0152773 21.8848H0C0 20.4262 0.699683 19.0283 1.94323 18.0019L21.0211 2.22039C22.74 0.798674 25.071 0 27.5015 0C29.9321 0 32.2631 0.798674 33.982 2.22039L53.0568 18.0019C54.3003 19.0308 55 20.4287 55 21.8822H54.9847C54.9939 21.9657 55 22.0516 55 22.135V47.4138C55 49.4251 54.0343 51.3541 52.3153 52.7763C50.5963 54.1985 48.2649 54.9975 45.8338 54.9975H9.16616V55ZM29.6586 5.79481L48.8862 21.7028V47.4189C48.8862 48.0893 48.5643 48.7323 47.9913 49.2064C47.4183 49.6804 46.6411 49.9468 45.8308 49.9468H36.6646V37.3074C36.6646 35.2961 35.6989 33.3671 33.9799 31.9449C32.2609 30.5227 29.9295 29.7237 27.4985 29.7237C25.0675 29.7237 22.736 30.5227 21.017 31.9449C19.298 33.3671 18.3323 35.2961 18.3323 37.3074V49.9468H9.16616C8.35582 49.9468 7.57867 49.6804 7.00567 49.2064C6.43268 48.7323 6.11077 48.0893 6.11077 47.4189V21.7028L25.3383 5.79481C25.9113 5.32091 26.6883 5.05468 27.4985 5.05468C28.3087 5.05468 29.0857 5.32091 29.6586 5.79481Z"
      />
    </svg>
  );
}
