import React from "react";
import styled from "styled-components";
const BannerWrapper = styled.div`
  @media (min-width: 61.313em) {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin: 0 auto;
  }
`;
const BannerContent = styled.div`
  background-color: var(--secondary);
  border-radius: 25px;
  padding: 1rem 2rem;
  margin: 1rem auto;
  @media (min-width: 61.313em) {
    width: 100%;
  }
`;

const SvgWrapper = styled.div`
  width: 120px;
  height: auto;
  margin: 0.2rem auto -2rem auto;
  @media (min-width: 61.313em) {
    margin: auto;
    width: 180px;
    position: absolute;
    bottom: 4%;
    right: 2%;
    transform: translate(2%, 4%);
  }
`;
const StyledTitle = styled.h1`
  font-family: "montserrat-medium";
  color: var(--primary);
  margin: 0.5rem auto;
  font-size: 1.2rem;
`;

export default function Banner({ title, subtitle, children }) {
  return (
    <BannerWrapper>
      <SvgWrapper>{children}</SvgWrapper>
      <BannerContent>
        <StyledTitle>{title}</StyledTitle> {subtitle}
      </BannerContent>
    </BannerWrapper>
  );
}
