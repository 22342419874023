import moment from "moment";

export const unitSymobol = (unit) => {
    switch (unit) {
        case 'percent':
            return '%';
        case 'celsius':
            return '°C';
        case 'litre':
            return 'L';
        default:
            return unit;
    }
}

const reportTimePeriodList = [
    {
        name: 'Today',
        value: 'today'
    },
    // {
    //     name: 'This Week',
    //     value: 'thisWeek'
    // },
    // {
    //     name: 'This Week + Last Week',
    //     value: 'thisWeekLastWeek'
    // },
    // {
    //     name: 'This Month',
    //     value: 'thisMonth'
    // },
    // {
    //     name: 'Last Month',
    //     value: 'lastMonth'
    // },
    {
        name: 'Custom Date',
        value: 'custom'
    },
    {
        name: 'Custom Week',
        value: 'custom-week'
    },
    {
        name: 'Custom Month',
        value: 'custom-month'
    }
    // {
    //     name: 'Custom Time',
    //     value: 'custom-time'
    // }

]


export const reportTimePeriod = (reportType) => {
    if (reportType === 'Trend') {
        return reportTimePeriodList.slice(0, 2)
    }
    return reportTimePeriodList
}


export const formatDate = (date) => {
    if (!date) return;
    return moment(date).format("DD MMM, h:mm a");
}



export const literToKiloliter = (value) => {
    if (value < 1000) {
        return value + ' L';
    }
    return (value / 1000).toFixed(1) + ' kL';
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function editAccess(userAPI) {
    if (!userAPI) return false;
    if (userAPI?.role === "CUST_MANAGER") return true;
    return userAPI?.customer_admin === userAPI?.email
}


export const sumList = (list) => {
    if (list?.length > 0) {
        return list.reduce((acc, curr) => {
            return Number(acc) + Number(curr);
        }, 0);
    }
    return 0;
}