import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { DataProvider } from "./GlobalState";
import GlobalStyles from "./design/index";
import Pages from "./components/Pages";
import "./index.css";
import "chartjs-adapter-moment";



function App() {

  return (
    <DataProvider>


      <Router>
        {/* GlobalStyles */}
        <GlobalStyles />

        {/* Pages */}
        <Pages />
      </Router>
    </DataProvider>
  );
}

export default App;
