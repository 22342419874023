import React from "react";

export default function Profile() {
  return (
    <svg viewBox="0 0 370 243" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M241.479 106.104C241.053 108.341 240.542 110.555 239.945 112.746C239.881 113 239.808 113.251 239.736 113.505H231.441C230.735 113.506 230.058 113.787 229.558 114.285C229.059 114.783 228.778 115.459 228.777 116.164V139.754C228.526 140.221 228.271 140.684 228.016 141.147C226.698 143.54 225.328 145.906 223.927 148.241C201.085 186.199 169.227 217.963 131.165 240.729L133.419 235.656C132.592 235.599 131.763 235.542 130.937 235.485C112.951 234.258 94.9037 232.899 77.3402 228.866C58.9659 224.647 40.9914 217.329 26.5911 205.196C12.1869 193.059 1.62758 175.727 0.162015 156.975C-0.326673 150.531 0.326664 144.051 2.09206 137.832C5.46835 125.863 12.8456 115.093 23.4888 108.721C40.2072 98.7142 62.015 100.753 79.6394 109.078C97.26 117.398 111.603 131.171 125.608 144.706C111.468 120.517 91.7813 100.017 68.1626 84.8875C60.3363 79.89 52.0304 75.4394 45.3765 68.9646C38.7264 62.4937 33.8197 53.4215 34.996 44.2278C36.332 33.7998 44.8931 25.8859 53.4692 19.7833C56.8951 17.3453 60.4314 15.0326 64.0782 12.906C74.3672 6.8908 85.4977 2.3528 97.2523 0.670508C113.175 -1.61179 130.373 1.85153 142.581 12.3022C156.334 24.0783 161.975 43.0695 161.439 61.2367C169.912 48.948 182.98 39.5417 197.738 39.1429C213.06 38.7328 227.624 48.3898 235.297 61.6279C242.968 74.8621 244.346 91.0851 241.479 106.104Z"
        fill="#E6E6E6"
      />
      <path
        d="M144.541 157.141C153.515 156.352 161.805 155.431 170.501 152.976C178.93 150.597 187.041 146.614 193.041 140.11C199.495 133.114 202.979 124.029 205.437 114.985C206.808 109.943 207.934 104.83 208.996 99.7153C210.149 94.1659 211.173 88.592 212.069 82.9936C213.852 71.8499 215.125 60.6405 215.888 49.3654C215.98 47.9968 216.065 46.6277 216.142 45.2581C216.189 44.4133 214.872 44.416 214.825 45.2581C214.198 56.3721 213.071 67.4427 211.443 78.47C209.836 89.3043 207.78 100.092 205.172 110.732C202.963 119.746 200.102 128.9 194.436 136.404C189.122 143.44 181.447 148.038 173.16 150.788C164.727 153.585 155.836 154.702 147.024 155.533C145.943 155.635 145.623 155.732 144.541 155.827C143.704 155.9 143.696 157.215 144.541 157.141L144.541 157.141Z"
        fill="white"
      />
      <path
        d="M2.85152 138.802C14.1736 139.428 25.3806 141.398 36.2354 144.67C47.0949 147.936 57.5305 152.467 67.3261 158.171C76.7911 163.687 85.5778 170.287 93.5076 177.837C101.204 185.168 108.063 193.327 113.959 202.164C119.883 211.024 124.792 220.518 128.594 230.468C129.058 231.686 129.504 232.91 129.934 234.141C130.212 234.934 131.484 234.593 131.204 233.791C127.647 223.63 122.957 213.9 117.223 204.783C111.49 195.666 104.762 187.21 97.1623 179.571C89.4405 171.814 80.8467 164.972 71.5516 159.182C52.0349 147.053 29.9128 139.716 7.00199 137.773C5.61997 137.657 4.23648 137.562 2.85153 137.488C2.00457 137.442 2.00717 138.756 2.85153 138.802L2.85152 138.802Z"
        fill="white"
      />
      <path
        d="M64.4334 14.4463C71.6726 16.7431 78.5344 20.0883 84.7991 24.3746C91.2006 28.7843 97.0389 33.9567 102.184 39.7773C107.607 45.9002 112.428 52.5283 116.581 59.5702C120.986 67.0055 124.813 74.767 128.027 82.7864C131.337 91.0195 134.122 99.4536 136.363 108.038C138.641 116.724 140.421 125.533 141.693 134.421C142.959 143.215 143.725 152.073 143.988 160.954C144.252 169.445 144.001 177.945 143.234 186.406C142.533 194.3 141.253 202.132 139.404 209.839C137.761 216.8 135.447 223.586 132.493 230.101C131.808 231.58 131.082 233.038 130.314 234.476C129.916 235.222 131.053 235.887 131.452 235.139C134.716 228.916 137.323 222.371 139.23 215.609C141.37 208.082 142.92 200.401 143.869 192.635C144.92 184.209 145.432 175.724 145.401 167.233C145.382 158.313 144.85 149.401 143.807 140.542C142.764 131.571 141.214 122.667 139.167 113.871C137.124 105.06 134.533 96.3854 131.409 87.8964C128.397 79.6786 124.792 71.6898 120.62 63.993C116.647 56.6483 112.017 49.6768 106.787 43.1634C101.819 36.9616 96.1482 31.355 89.8869 26.4558C83.8493 21.7459 77.1776 17.9066 70.0679 15.051C68.3305 14.3611 66.569 13.7372 64.7836 13.1793C63.9732 12.9249 63.6264 14.193 64.4334 14.4463H64.4334Z"
        fill="white"
      />
      <path
        d="M142.558 243C194.59 243 236.77 241.3 236.77 239.203C236.77 237.105 194.59 235.405 142.558 235.405C90.5256 235.405 48.3452 237.105 48.3452 239.203C48.3452 241.3 90.5256 243 142.558 243Z"
        fill="#E6E6E6"
      />
      <path
        d="M225.796 120.261C225.749 120.466 225.718 120.674 225.704 120.884L209.352 130.295L205.378 128.012L201.141 133.546L209.643 139.592L228.299 124.91C229.059 125.191 229.888 125.234 230.674 125.033C231.46 124.833 232.166 124.399 232.699 123.788C233.232 123.178 233.565 122.42 233.656 121.615C233.746 120.811 233.589 119.998 233.205 119.285C232.821 118.572 232.228 117.993 231.506 117.624C230.784 117.255 229.967 117.114 229.162 117.22C228.358 117.326 227.605 117.674 227.004 118.217C226.402 118.76 225.981 119.473 225.796 120.261Z"
        fill="#FFB8B8"
      />
      <path
        d="M188.555 126.035L201.43 135.189L207.188 127.987L194.672 117.968C194.134 117.537 193.513 117.22 192.847 117.037C192.182 116.854 191.486 116.808 190.802 116.902C190.118 116.997 189.461 117.23 188.87 117.587C188.28 117.943 187.769 118.417 187.368 118.978C186.587 120.07 186.274 121.428 186.496 122.751C186.719 124.075 187.459 125.256 188.555 126.035Z"
        fill="#CA7070"
      />
      <path
        d="M161.977 230.492L166.491 231.671L173.202 214.862L166.539 213.12L161.977 230.492Z"
        fill="#FFB8B8"
      />
      <path
        d="M174.099 238.129L159.911 234.421L161.347 228.952L170.054 231.228C171.508 231.608 172.751 232.548 173.509 233.843C174.268 235.137 174.48 236.679 174.099 238.129Z"
        fill="#2F2E41"
      />
      <path
        d="M202.107 233.698L206.701 232.879L205.722 214.812L198.941 216.02L202.107 233.698Z"
        fill="#FFB8B8"
      />
      <path
        d="M216.323 235.556L201.884 238.129L200.888 232.564L209.748 230.985C210.48 230.855 211.232 230.869 211.958 231.028C212.685 231.188 213.374 231.488 213.984 231.912C214.595 232.337 215.116 232.877 215.517 233.502C215.918 234.128 216.192 234.826 216.323 235.556Z"
        fill="#2F2E41"
      />
      <path
        d="M193.061 100.827C194.193 101.102 195.114 102.252 195.667 103.586C196.177 104.986 196.522 106.44 196.696 107.92C197.131 110.676 197.548 113.659 196.649 116.212C195.596 119.203 192.788 120.77 190.353 120.033C187.918 119.297 185.982 116.533 185.32 113.352C184.718 110.138 185.106 106.816 186.433 103.826C187.125 102.255 188.078 100.806 189.346 100.097C189.967 99.7221 190.708 99.5958 191.419 99.7436C192.13 99.8915 192.758 100.302 193.178 100.894"
        fill="#2F2E41"
      />
      <path
        d="M199.466 150.721L208.212 228.871L200.989 231.608L187.666 179.202L169.117 227.118L161.21 227.556C161.21 227.556 172.059 152.999 180.099 151.305L199.466 150.721Z"
        fill="#2F2E41"
      />
      <path
        d="M192.119 110.8C197.283 110.8 201.469 106.624 201.469 101.473C201.469 96.3219 197.283 92.146 192.119 92.146C186.956 92.146 182.77 96.3219 182.77 101.473C182.77 106.624 186.956 110.8 192.119 110.8Z"
        fill="#FFB8B8"
      />
      <path
        d="M196.132 114.713L186.029 113.398C186.029 113.398 179.44 121.287 179.879 134.872C180.318 148.457 178.781 152.62 178.781 152.62L199.846 152.999C196.801 148.822 209.539 124.831 196.132 114.713Z"
        fill="#CA7070"
      />
      <path
        d="M201.56 154.898C201.349 154.9 201.139 154.918 200.931 154.952L188.006 141.226L189.324 136.844L182.954 133.995L179 143.636L197.596 158.393C197.496 159.196 197.644 160.011 198.019 160.728C198.395 161.446 198.98 162.032 199.698 162.41C200.415 162.787 201.231 162.938 202.037 162.841C202.842 162.745 203.599 162.406 204.207 161.87C204.815 161.334 205.245 160.626 205.44 159.841C205.634 159.055 205.585 158.229 205.297 157.472C205.01 156.715 204.498 156.063 203.831 155.604C203.163 155.144 202.371 154.898 201.56 154.898Z"
        fill="#FFB8B8"
      />
      <path
        d="M187.403 120.053L181.417 134.652L189.763 138.596L196.675 124.149C196.972 123.527 197.139 122.852 197.166 122.163C197.192 121.475 197.077 120.789 196.829 120.146C196.58 119.504 196.202 118.919 195.719 118.427C195.236 117.935 194.657 117.547 194.018 117.286C193.401 117.035 192.741 116.907 192.075 116.909C191.409 116.912 190.75 117.046 190.136 117.303C189.522 117.56 188.964 117.935 188.495 118.407C188.026 118.879 187.655 119.438 187.403 120.053Z"
        fill="#CA7070"
      />
      <path
        d="M189.674 109.953C191.139 109.11 191.295 107.076 191.096 105.4C190.897 103.725 190.564 101.857 191.552 100.487C192.73 98.8521 195.097 98.8196 197.115 98.7662C199.132 98.7128 201.59 98.1234 202.112 96.1785C202.458 94.8933 201.719 93.5142 200.658 92.7075C199.597 91.9008 198.271 91.5441 196.981 91.2052C194.096 90.4469 191.117 89.6823 188.162 90.099C185.236 90.5118 182.591 92.0573 180.798 94.4012C179.006 96.745 178.21 99.6987 178.584 102.623C179.062 106.364 179.756 107.998 179.301 109.647C178.453 112.719 174.797 114.693 174.724 117.879C174.667 120.371 176.874 122.293 179.026 123.558C180.734 124.562 182.577 125.414 184.544 125.649C186.512 125.883 188.631 125.434 190.096 124.103C191.987 122.387 192.455 119.52 191.913 117.028C191.37 114.536 189.98 112.323 188.604 110.174"
        fill="#2F2E41"
      />
      <path
        d="M366.574 112.746H231.441C230.533 112.747 229.662 113.108 229.02 113.748C228.378 114.389 228.017 115.258 228.015 116.164V202.746C228.017 203.652 228.378 204.521 229.02 205.162C229.662 205.802 230.533 206.163 231.441 206.164H366.574C367.482 206.163 368.353 205.802 368.995 205.162C369.637 204.521 369.999 203.652 370 202.746V116.164C369.999 115.258 369.637 114.389 368.995 113.748C368.353 113.108 367.482 112.747 366.574 112.746ZM369.239 202.746C369.238 203.451 368.957 204.127 368.457 204.625C367.958 205.124 367.281 205.404 366.574 205.405H231.441C230.735 205.404 230.057 205.124 229.558 204.625C229.058 204.127 228.777 203.451 228.776 202.746V116.164C228.777 115.459 229.058 114.783 229.558 114.285C230.057 113.786 230.735 113.506 231.441 113.505H366.574C367.281 113.506 367.958 113.786 368.457 114.285C368.957 114.783 369.238 115.459 369.239 116.164V202.746Z"
        fill="#3F3D56"
      />
      <path
        d="M260.285 155.846H238.968C238.111 155.845 237.288 155.504 236.682 154.899C236.075 154.294 235.734 153.474 235.733 152.618V131.352C235.734 130.496 236.075 129.676 236.682 129.071C237.288 128.465 238.111 128.125 238.968 128.124H260.285C261.143 128.125 261.965 128.465 262.572 129.071C263.179 129.676 263.52 130.496 263.521 131.352V152.618C263.52 153.474 263.179 154.294 262.572 154.899C261.965 155.504 261.143 155.845 260.285 155.846Z"
        fill="#CA7070"
      />
      <path
        d="M359.536 178.445H238.487C237.831 178.445 237.202 178.184 236.738 177.722C236.274 177.259 236.013 176.631 236.013 175.976C236.013 175.322 236.274 174.694 236.738 174.231C237.202 173.768 237.831 173.508 238.487 173.508H359.536C360.193 173.508 360.822 173.768 361.286 174.231C361.75 174.694 362.011 175.322 362.011 175.976C362.011 176.631 361.75 177.259 361.286 177.722C360.822 178.184 360.193 178.445 359.536 178.445Z"
        fill="#CCCCCC"
      />
      <path
        d="M359.617 166.482H238.569C237.912 166.482 237.283 166.222 236.819 165.759C236.355 165.297 236.094 164.669 236.094 164.014C236.094 163.359 236.355 162.732 236.819 162.269C237.283 161.806 237.912 161.546 238.569 161.546H359.617C360.274 161.546 360.903 161.806 361.367 162.269C361.831 162.732 362.092 163.359 362.092 164.014C362.092 164.669 361.831 165.297 361.367 165.759C360.903 166.222 360.274 166.482 359.617 166.482Z"
        fill="#CCCCCC"
      />
      <path
        d="M299.854 150.149H281.964C281.307 150.149 280.678 149.889 280.214 149.426C279.75 148.964 279.489 148.336 279.489 147.681C279.489 147.026 279.75 146.399 280.214 145.936C280.678 145.473 281.307 145.213 281.964 145.213H299.854C300.511 145.213 301.14 145.473 301.604 145.936C302.068 146.399 302.329 147.026 302.329 147.681C302.329 148.336 302.068 148.964 301.604 149.426C301.14 149.889 300.511 150.149 299.854 150.149Z"
        fill="#CCCCCC"
      />
      <path
        d="M359.618 138.001H281.964C281.307 138.001 280.678 137.741 280.214 137.278C279.75 136.815 279.489 136.187 279.489 135.533C279.489 134.878 279.75 134.25 280.214 133.787C280.678 133.325 281.307 133.064 281.964 133.064H359.618C360.274 133.064 360.903 133.325 361.367 133.787C361.831 134.25 362.092 134.878 362.092 135.533C362.092 136.187 361.831 136.815 361.367 137.278C360.903 137.741 360.274 138.001 359.618 138.001Z"
        fill="#CCCCCC"
      />
      <path
        d="M296.809 190.596H238.487C237.831 190.596 237.202 190.336 236.738 189.873C236.274 189.411 236.013 188.783 236.013 188.128C236.013 187.473 236.274 186.846 236.738 186.383C237.202 185.92 237.831 185.66 238.487 185.66H296.809C297.465 185.66 298.095 185.92 298.559 186.383C299.023 186.846 299.283 187.473 299.283 188.128C299.283 188.783 299.023 189.411 298.559 189.873C298.095 190.336 297.465 190.596 296.809 190.596Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}
