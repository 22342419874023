import React, { createContext, useState, useEffect } from "react";
import UserAPI from "./api/UserAPI";
import axios from "axios";

export const GlobalState = createContext();

export const DataProvider = ({ children }) => {

  const [token, setToken] = useState(false)

  useEffect(() => {
    axios.defaults.withCredentials = true

    const token = localStorage.getItem('accessToken')
    if (token) {
      setToken(token)
    }

  }, [])


  const state = {
    token: [token, setToken],
    userAPI: UserAPI(token),
    // loading: [loading, setLoading],
  };

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};
