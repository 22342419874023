import React from "react";

export default function Handle({
  handle: { id, value, percent },
  getHandleProps,
}) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: -15,
        marginTop: 25,
        zIndex: 2,
        width: 25,
        height: 25,
        border: 0,
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "50%",
        backgroundColor: "#BE6F72",
        color: "#000",
      }}
      {...getHandleProps(id)}
    >
      <div style={{ fontWeight: "bold", fontSize: 15, marginTop: -25 }}>
        {value}
      </div>
    </div>
  );
}
