import React from "react";

export default function Logo() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 463.176 282"
      enableBackground="new 0 0 463.176 282"
    >
      <g>
        <g>
          <g>
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="270.434,72.172 270.915,73.131 271.393,73.61 
               271.872,74.09 272.352,74.569 272.833,75.048 273.311,76.006 273.79,76.487 274.266,77.445 274.745,78.403 275.223,79.837 
               275.704,81.753 276.182,84.629 276.182,89.417 276.178,91.874 273.311,91.335 271.393,90.854 268.04,90.854 268.04,91.335 
               268.518,91.814 268.999,92.293 269.477,92.772 270.434,93.251 270.915,93.727 271.872,94.206 272.833,94.685 273.311,95.166 
               274.266,95.645 274.745,96.124 275.704,96.603 276.182,97.082 277.141,97.561 277.62,98.04 278.579,98.519 279.057,98.998 
               280.016,99.477 280.495,99.958 280.975,100.437 281.93,100.913 282.409,101.392 282.887,101.871 283.846,102.35 284.325,102.829 
               284.803,103.308 285.764,103.789 286.243,104.267 286.721,104.746 287.68,105.225 288.159,105.704 288.635,106.184 
               289.116,106.663 290.073,107.142 290.551,107.621 291.032,108.098 291.51,108.577 291.989,109.056 292.467,109.535 
               292.948,110.014 293.907,110.493 294.385,110.972 294.866,111.451 295.344,111.93 295.821,112.409 296.299,112.89 
               296.778,113.369 297.258,113.848 297.737,114.327 298.217,114.806 298.696,115.285 299.174,115.761 299.653,116.24 
               300.133,117.2 300.612,117.679 301.09,118.158 301.571,118.637 302.049,119.116 302.53,120.073 303.004,120.553 303.485,121.032 
               303.963,121.512 304.442,122.47 304.922,122.946 305.401,123.904 305.879,124.383 306.358,125.343 306.838,126.301 
               307.319,126.78 307.784,127.804 306.358,127.259 304.442,126.78 303.004,126.301 302.049,125.822 300.612,125.343 
               299.174,124.863 297.737,124.383 296.299,123.904 295.344,123.425 293.907,122.946 292.467,122.47 291.51,121.991 
               290.073,121.512 289.116,121.032 287.68,120.553 286.243,120.073 285.286,119.594 283.846,119.116 282.887,118.637 
               281.452,118.158 280.016,117.679 279.057,117.2 277.62,116.72 276.663,116.24 275.223,115.761 273.79,115.285 272.352,114.806 
               271.393,114.327 269.956,113.848 268.04,113.369 267.081,113.369 267.081,114.327 267.559,114.806 268.518,115.285 
               269.477,115.761 269.956,116.24 270.915,116.72 271.872,117.2 272.833,117.679 273.311,118.158 274.266,118.637 274.745,119.116 
               275.704,119.594 276.182,120.073 277.141,120.553 277.62,121.032 278.1,121.512 278.579,121.991 279.057,122.47 279.536,122.946 
               280.016,123.425 280.495,123.904 280.975,124.383 281.452,125.343 281.93,126.301 282.409,129.175 282.409,131.568 
               282.409,142.107 282.887,144.501 283.368,145.938 283.846,146.897 284.325,147.376 284.803,147.855 285.286,148.335 
               285.764,148.813 286.243,149.292 286.721,149.771 287.68,150.251 288.159,150.729 289.116,151.209 290.073,151.687 
               291.032,152.165 291.989,152.645 293.426,153.123 294.385,153.603 295.821,154.081 297.258,154.561 298.696,155.039 
               300.133,155.52 300.612,155.999 300.133,156.478 299.653,156.957 299.174,157.437 298.217,157.915 297.737,158.395 
               297.258,158.87 296.778,159.349 296.299,159.83 295.344,160.309 294.866,160.787 294.385,161.746 294.385,162.704 
               296.778,162.704 298.696,162.225 298.217,163.662 297.737,164.622 297.258,165.579 296.778,166.534 296.299,168.452 
               296.299,170.847 296.778,171.806 297.737,172.284 298.217,172.764 299.174,173.241 300.133,173.72 301.571,174.198 
               303.004,174.678 304.922,175.157 307.319,175.636 309.713,176.115 313.065,176.594 317.854,177.074 325.997,177.554 
               327.913,178.032 329.35,178.512 330.309,178.99 330.79,179.47 331.747,179.949 332.225,180.425 332.708,180.904 333.186,181.385 
               333.661,181.863 334.141,182.342 334.62,183.301 335.098,184.737 335.577,188.568 336.057,190.965 336.536,191.444 
               337.014,191.444 337.495,190.486 337.973,189.047 338.454,188.089 338.932,186.655 339.411,185.697 339.891,184.737 
               340.37,184.259 340.846,183.301 341.325,182.821 341.803,182.342 342.282,181.863 342.762,181.385 343.243,180.904 
               343.721,179.949 344.2,179.47 344.68,178.512 345.159,177.554 345.159,175.636 344.68,174.678 344.2,174.198 343.721,173.72 
               343.243,173.241 342.762,172.764 341.803,172.284 340.846,171.806 339.411,171.326 338.454,170.847 336.536,170.368 
               334.62,169.89 331.268,169.41 316.42,168.931 314.022,168.452 313.065,167.972 312.586,167.492 312.586,164.143 313.065,162.704 
               313.543,161.267 314.022,160.309 314.502,159.83 314.981,159.349 315.461,158.87 315.94,158.395 316.42,157.915 316.899,157.437 
               317.854,156.957 318.333,156.478 319.292,155.999 320.249,155.52 321.688,155.039 322.645,154.561 324.563,154.081 
               326.956,153.603 329.35,153.603 331.268,154.081 331.747,154.561 332.225,156.478 332.708,157.915 333.186,158.87 
               333.661,159.83 334.141,160.309 334.62,161.267 335.098,161.746 335.577,162.225 336.057,163.183 336.536,163.662 
               337.014,164.143 337.495,164.622 337.973,165.101 338.454,165.579 338.932,166.056 339.411,166.534 339.891,167.014 
               340.37,167.492 341.325,167.972 341.803,168.452 342.282,168.931 343.243,169.41 343.721,169.89 344.68,170.368 345.637,170.847 
               349.467,170.847 350.426,170.368 351.385,169.89 351.864,169.41 352.823,168.931 353.301,168.452 354.26,167.972 
               354.739,167.492 355.217,167.014 356.174,166.534 356.653,166.056 357.612,165.579 359.049,165.101 360.487,164.622 
               362.401,164.143 363.836,163.662 365.276,163.183 365.754,162.704 366.713,162.225 367.192,161.746 367.67,160.787 
               368.149,158.87 368.149,152.165 367.67,149.292 367.192,147.855 366.713,146.897 366.235,146.417 365.754,145.459 
               365.276,144.979 365.276,144.024 367.67,144.024 369.11,144.501 370.543,144.979 371.981,145.459 372.94,145.938 
               373.899,146.417 375.334,146.417 375.334,145.459 374.856,144.979 374.377,144.501 373.899,144.024 373.418,143.546 
               372.94,143.067 372.459,142.587 371.981,142.107 371.502,141.628 371.022,141.149 370.543,140.67 370.065,139.712 
               369.586,139.233 369.11,138.754 368.629,138.275 368.149,137.794 367.67,137.315 367.192,136.839 366.713,136.36 
               366.235,135.881 365.754,135.403 365.276,134.923 364.797,134.444 364.317,133.965 363.836,133.485 363.358,133.006 
               362.879,132.527 362.401,132.048 361.924,131.568 361.446,131.089 360.965,130.61 360.487,130.132 360.008,129.654 
               359.528,129.175 359.049,128.696 358.569,128.217 358.09,127.738 357.612,127.259 357.131,126.78 356.653,126.301 
               356.174,125.822 355.696,125.343 355.217,124.863 354.739,124.383 354.26,123.904 353.78,123.425 353.301,122.946 
               352.823,122.47 352.344,121.991 351.864,121.512 351.385,121.032 350.426,120.553 349.948,120.073 348.989,119.594 
               348.032,119.116 346.596,118.637 345.637,118.158 343.721,117.679 342.282,117.2 340.846,116.72 339.891,116.24 338.932,115.761 
               338.454,115.285 337.973,114.806 337.014,114.327 336.536,113.848 336.057,112.89 335.577,112.409 335.098,111.93 
               334.62,111.451 334.141,110.493 333.661,110.014 333.186,109.535 332.708,109.056 332.225,108.577 331.747,108.098 
               330.309,107.621 328.872,107.621 328.393,108.098 327.913,108.577 327.913,110.493 328.393,111.451 328.872,111.93 
               329.35,112.409 330.79,112.89 332.225,113.369 332.708,113.848 331.268,113.848 329.831,113.369 328.872,112.89 327.913,112.409 
               327.434,111.93 326.956,111.451 326.477,110.972 325.997,110.014 325.518,108.577 325.518,105.704 325.997,105.225 
               328.393,104.746 328.393,103.789 327.913,103.308 326.956,102.829 326.477,102.35 325.997,101.871 325.518,101.392 
               325.042,100.913 324.563,100.437 324.084,99.958 323.604,99.477 323.125,98.998 322.645,98.519 322.167,97.561 321.688,97.082 
               321.208,97.082 321.208,98.998 321.688,100.913 322.167,104.746 322.645,107.142 323.125,108.577 323.604,110.014 
               324.084,110.972 324.563,111.451 325.042,112.409 325.518,112.89 325.997,113.369 326.477,113.848 326.956,114.327 
               325.997,114.327 325.042,113.848 324.563,113.369 324.084,112.89 323.604,112.409 323.125,111.451 322.645,110.972 
               322.167,110.014 321.688,109.535 321.208,109.056 320.727,108.577 320.249,108.098 319.77,107.621 319.292,107.142 
               318.813,106.663 318.333,106.184 317.375,105.704 316.899,105.225 315.94,104.746 314.502,104.267 313.543,103.789 
               312.586,103.308 311.147,102.829 309.713,102.35 308.754,101.871 307.319,101.392 305.879,100.913 303.963,100.437 
               302.53,99.958 300.612,99.477 299.174,98.998 297.258,98.519 294.866,98.04 292.948,97.561 290.551,97.082 288.086,96.866 
               289.116,96.603 293.907,96.603 297.737,97.082 299.653,97.561 301.571,98.04 303.485,98.519 304.922,98.998 306.358,99.477 
               307.319,99.958 308.754,100.437 310.19,100.913 311.627,101.392 312.586,101.871 314.022,102.35 314.981,102.829 316.42,103.308 
               317.375,103.789 318.813,103.789 318.813,99.958 318.333,97.082 317.854,95.166 317.375,93.727 316.899,92.293 316.42,91.335 
               316.899,91.814 317.854,92.293 318.813,92.772 319.292,93.251 319.77,93.727 320.727,94.206 321.208,94.685 321.688,95.166 
               322.645,95.645 323.125,96.124 323.604,96.603 324.084,97.082 325.042,97.561 325.518,98.04 325.997,98.519 326.477,98.998 
               326.956,99.477 327.434,99.958 327.913,100.437 328.872,100.913 329.35,101.392 329.831,101.871 330.309,102.829 330.79,103.308 
               331.268,103.789 331.747,104.267 332.225,105.225 332.708,105.704 333.186,106.184 333.661,107.142 334.141,107.621 
               334.62,108.098 335.098,109.056 335.577,109.535 336.057,110.014 336.536,110.493 337.014,111.451 337.495,111.93 
               337.973,112.409 338.454,112.89 338.932,113.369 339.411,113.848 340.37,114.327 340.846,114.806 342.282,115.285 
               347.555,115.285 348.032,114.806 348.51,114.327 348.989,113.848 349.467,112.89 349.467,111.451 348.989,110.014 
               348.51,109.535 348.032,108.577 347.555,108.098 346.596,107.621 346.116,107.142 345.637,106.663 345.159,106.184 
               344.2,105.704 343.721,105.225 342.762,104.746 342.282,104.267 341.325,103.789 340.846,103.308 340.37,102.829 339.411,102.35 
               338.932,101.871 338.454,101.392 337.973,100.913 337.014,100.437 336.536,99.958 336.057,99.477 335.577,98.998 335.098,98.519 
               334.62,98.04 334.141,97.561 333.661,97.082 333.186,96.603 332.708,96.124 332.225,95.645 331.747,95.166 331.268,94.685 
               330.79,94.206 330.309,93.727 329.831,93.251 329.35,92.293 328.872,91.814 328.393,91.335 327.913,90.854 327.434,90.375 
               326.956,89.896 326.477,89.417 325.997,88.459 325.518,87.981 325.042,87.501 324.563,87.022 324.084,86.543 323.604,86.067 
               323.125,85.588 322.645,85.108 322.167,84.629 321.688,84.15 320.727,83.67 320.249,83.191 319.77,82.712 318.813,82.232 
               318.333,81.753 317.375,81.274 316.899,80.795 315.94,80.316 314.981,79.837 314.022,79.358 313.543,78.882 312.586,78.403 
               311.147,77.924 310.19,77.445 309.235,76.966 308.276,76.487 306.838,76.006 305.879,75.527 304.442,75.048 303.004,74.569 
               302.049,74.09 300.612,73.61 298.696,73.131 297.258,72.651 295.821,72.172 293.907,71.694 293.426,71.694 293.426,73.131 
               293.907,74.09 294.385,75.048 294.866,76.006 295.344,76.487 295.821,77.445 296.299,78.403 296.778,79.358 297.258,80.316 
               297.737,81.753 298.217,82.712 298.696,83.67 299.174,85.108 299.629,87.094 298.696,86.543 297.737,86.067 296.778,85.588 
               296.299,85.108 295.344,84.629 294.866,84.15 293.907,83.67 293.426,83.191 292.467,82.712 291.989,82.232 291.032,81.753 
               290.551,81.274 289.594,80.795 289.116,80.316 288.635,79.837 287.68,79.358 287.2,78.882 286.243,78.403 285.764,77.924 
               284.803,77.445 283.846,76.966 283.368,76.487 282.409,76.006 281.93,75.527 280.975,75.048 280.016,74.569 279.057,74.09 
               278.579,73.61 277.62,73.131 276.663,72.651 275.223,72.172 274.266,71.694 271.872,71.215 270.434,71.215 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="316.42,91.335 315.858,91.255 316.42,90.854 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="328.393,122.946 327.833,122.869 328.393,122.47 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="328.393,122.946 328.954,123.027 328.393,123.425 			
               "
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="330.309,124.383 329.75,124.306 330.309,123.904 			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M330.79,124.863l-0.48-0.48L330.79,124.863z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M331.268,125.343l-0.479-0.48L331.268,125.343z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M331.747,125.822l-0.479-0.479L331.747,125.822z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M332.225,126.301l-0.479-0.479L332.225,126.301z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M332.708,126.78l-0.482-0.479L332.708,126.78z"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="333.661,127.259 334.141,127.738 334.62,128.217 
               335.098,128.696 335.577,129.175 336.057,129.654 336.536,130.132 337.014,130.61 337.495,131.089 337.973,131.568 
               338.932,132.048 339.411,132.527 339.891,133.006 340.37,133.485 340.846,133.965 341.325,134.444 341.803,134.923 
               342.282,135.403 342.762,135.881 343.243,136.36 343.721,136.839 344.2,137.315 344.68,137.794 345.159,138.275 345.637,138.754 
               346.116,139.233 346.596,139.712 347.077,140.191 347.555,140.67 348.032,141.149 348.51,141.628 348.989,142.107 
               349.467,142.587 349.948,143.067 350.426,143.546 350.905,144.024 351.385,144.501 351.864,144.979 352.344,145.459 
               352.823,146.417 353.301,146.897 353.78,147.376 354.26,147.855 354.739,148.335 355.217,148.813 355.696,149.292 
               355.217,149.771 353.78,149.771 353.301,148.813 352.823,148.335 352.344,147.855 351.864,147.376 351.385,146.897 
               350.905,145.938 350.426,145.459 349.948,144.979 349.467,144.501 348.989,144.024 348.51,143.546 348.032,143.067 
               347.555,142.107 347.077,141.628 346.596,141.149 346.116,140.67 345.637,140.191 345.159,139.712 344.68,139.233 344.2,138.754 
               343.721,138.275 343.243,137.315 342.762,136.839 342.282,136.36 341.803,135.881 341.325,135.403 340.846,134.923 
               340.37,134.444 339.891,133.965 339.411,133.485 338.932,133.006 338.454,132.527 337.973,132.048 337.495,131.568 
               337.014,131.089 336.536,130.61 336.057,130.132 335.577,129.654 335.098,129.175 334.62,128.696 334.141,128.217 
               333.661,127.738 333.186,127.259 332.708,126.78 			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M325.518,128.217"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="327.913,130.132 327.354,130.052 327.913,129.654 			
               "
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M328.393,130.61l-0.48-0.479L328.393,130.61z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M328.872,131.089l-0.479-0.479L328.872,131.089z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M329.831,131.568l-0.959-0.479L329.831,131.568z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M330.309,132.048l-0.479-0.479L330.309,132.048z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M330.79,132.527l-0.48-0.479L330.79,132.527z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M331.268,133.006l-0.479-0.479L331.268,133.006z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M331.747,133.485l-0.479-0.479L331.747,133.485z"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="332.708,133.965 333.186,134.444 333.661,134.923 
               334.141,135.403 334.62,135.881 335.098,136.36 335.577,136.839 336.057,137.315 336.536,137.794 337.014,138.275 
               337.973,138.754 338.454,139.233 338.932,139.712 339.411,140.191 339.891,140.67 340.37,141.149 340.846,141.628 
               341.325,142.107 341.803,142.587 342.282,143.067 342.762,143.546 343.243,144.024 343.721,144.501 344.2,144.979 
               344.68,145.459 345.159,145.938 345.637,146.417 346.116,146.897 346.596,147.376 347.077,147.855 347.555,148.335 
               348.032,148.813 348.51,149.771 348.989,150.251 349.467,150.729 347.555,150.729 347.077,150.251 346.596,149.771 
               346.116,149.292 345.637,148.335 345.159,147.855 344.68,147.376 344.2,146.897 343.721,146.417 343.243,145.459 
               342.762,144.979 342.282,144.501 341.803,144.024 341.325,143.546 340.846,143.067 340.37,142.587 339.891,142.107 
               339.411,141.149 338.932,140.67 338.454,140.191 337.973,139.712 337.495,139.233 337.014,138.754 336.536,138.275 
               336.057,137.794 335.577,137.315 335.098,136.839 334.62,136.36 334.141,135.881 333.661,135.403 333.186,134.923 
               332.708,134.444 332.225,133.965 331.747,133.485 			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M326.477,136.36"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="328.393,137.794 327.833,137.717 328.393,137.315 			
               "
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M328.872,138.275l-0.479-0.48L328.872,138.275z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M329.35,138.754l-0.479-0.479L329.35,138.754z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M330.309,139.233l-0.959-0.479L330.309,139.233z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M330.79,139.712l-0.48-0.479L330.79,139.712z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M331.268,140.191l-0.479-0.479L331.268,140.191z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M332.225,140.67l0.482,0.479l0.479,0.479l0.475,0.479
               l0.48,0.479l0.957,0.48l0.479,0.479l0.48,0.479l0.479,0.477l0.479,0.479l0.48,0.479l0.479,0.479l0.48,0.479l0.479,0.48
               l0.479,0.479l0.48,0.479l0.479,0.479l0.477,0.479l0.479,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.48,0.479l0.479,0.478
               l0.479,0.479l0.48,0.479c0.275,0.892-1.758,1.101-2.398-0.479l-0.479-0.479l-0.479-0.478l-0.479-0.958l-0.477-0.479l-0.479-0.479
               l-0.48-0.479l-0.479-0.958l-0.479-0.479l-0.48-0.479l-0.479-0.48l-0.48-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479
               l-0.479-0.477l-0.479-0.479l-0.479-0.479l-0.48-0.48l-0.475-0.479l-0.479-0.479l-0.482-0.479l-0.479-0.479l-0.479-0.479
               L332.225,140.67z"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="298.696,162.225 298.776,161.665 299.174,162.225 			
               "
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="183.737,14.215 180.862,14.694 178.945,15.173 
               177.033,15.652 175.594,16.131 173.678,16.612 172.24,17.091 170.326,17.569 168.41,18.049 166.493,18.528 164.576,19.007 
               162.183,19.486 159.309,19.965 156.436,20.442 153.081,20.921 148.771,21.4 130.089,21.4 127.217,21.4 127.217,21.88 
               128.653,22.359 130.568,22.838 132.006,23.317 133.923,23.795 135.36,24.274 137.275,24.753 138.712,25.234 140.149,25.713 
               141.586,26.192 142.546,26.671 143.98,27.15 144.938,27.626 145.897,28.105 146.376,28.584 146.376,29.064 145.897,29.544 
               144.938,30.023 143.98,30.502 143.022,30.981 142.066,31.46 141.107,31.939 139.67,32.418 138.712,32.897 137.275,33.376 
               136.315,33.856 134.881,34.335 133.444,34.812 131.526,35.291 130.089,35.77 127.696,36.249 125.78,36.728 122.904,37.207 
               119.553,37.688 114.282,38.167 101.35,38.167 96.082,37.688 92.251,37.207 89.375,36.728 86.98,36.249 86.022,36.249 
               86.022,37.207 86.98,37.688 88.417,38.167 89.375,38.646 90.814,39.124 91.772,39.603 93.206,40.083 94.645,40.562 
               96.561,41.041 97.998,41.52 99.913,41.997 101.35,42.476 103.746,42.955 105.662,43.434 108.535,43.913 111.409,44.393 
               114.761,44.872 119.553,45.35 138.233,45.35 143.022,44.872 146.376,44.393 149.25,43.913 151.644,43.434 154.04,42.955 
               155.957,42.476 158.349,41.997 159.788,41.52 161.704,41.041 163.143,40.562 165.535,40.083 167.452,39.603 170.805,39.124 
               175.594,38.646 181.821,38.646 187.092,39.124 189.963,39.603 192.36,40.083 194.277,40.562 196.19,41.041 197.628,41.52 
               199.064,41.997 200.503,42.476 201.942,42.955 202.896,43.434 204.334,43.913 205.292,44.393 206.25,44.872 207.208,45.35 
               208.167,45.829 209.125,46.308 210.081,46.789 211.039,47.268 211.998,47.747 212.478,48.226 213.436,48.705 214.394,49.181 
               214.873,49.66 215.831,50.139 216.31,50.619 217.266,51.099 217.745,51.578 218.703,52.057 219.182,52.536 219.662,53.015 
               220.621,53.494 221.1,53.973 221.579,54.451 222.058,54.931 223.016,55.412 223.495,55.89 223.971,56.367 224.45,56.845 
               224.931,57.324 225.41,57.803 225.888,58.282 226.847,58.762 227.326,59.242 227.805,59.721 228.284,60.2 228.763,60.679 
               229.243,61.158 229.722,61.637 230.681,61.637 232.114,61.158 233.072,60.679 234.511,60.2 235.948,59.721 237.385,59.242 
               239.299,58.762 241.696,58.282 245.049,57.803 250.317,57.803 253.668,58.282 256.065,58.762 257.502,59.242 258.94,59.721 
               260.375,60.2 261.333,60.679 262.292,61.158 263.249,61.637 264.209,62.116 264.688,62.595 265.645,63.074 266.604,63.55 
               267.081,64.029 268.04,64.508 268.518,64.989 268.999,65.468 269.956,65.947 272.833,66.426 274.745,66.905 276.663,67.384 
               278.1,67.864 279.057,68.343 280.495,68.822 281.452,69.302 282.887,69.781 283.846,70.26 284.803,70.736 285.764,71.215 
               287.2,71.694 288.159,71.694 288.159,70.736 287.68,70.26 287.2,69.781 286.721,69.302 286.243,68.822 285.764,67.864 
               285.286,67.384 284.803,66.426 284.803,63.55 285.764,63.55 288.159,64.029 290.073,64.508 291.51,64.989 292.948,65.468 
               293.907,65.947 295.344,66.426 296.299,66.905 297.258,67.384 298.217,67.864 299.174,68.343 300.612,68.822 301.571,69.302 
               302.53,69.781 303.485,70.26 304.442,70.736 305.401,71.215 306.358,71.694 306.838,72.172 307.797,72.651 309.235,73.131 
               310.19,73.61 311.147,73.61 311.147,72.651 310.668,72.172 310.19,71.694 309.713,70.736 309.235,70.26 308.754,69.302 
               308.276,68.822 307.797,68.343 307.319,67.384 306.838,66.905 306.358,66.426 305.879,65.468 305.401,64.989 304.922,64.508 
               304.442,64.029 303.963,63.55 303.485,63.074 303.004,62.116 302.049,61.637 301.571,61.158 301.09,60.679 300.612,60.2 
               300.133,59.721 299.174,59.242 298.696,58.762 297.737,58.282 297.258,57.803 296.299,57.324 294.866,56.845 293.907,56.367 
               291.989,55.89 289.594,55.412 287.2,54.931 284.325,54.451 281.93,53.973 279.536,53.494 277.62,53.015 276.182,52.536 
               274.266,52.057 272.833,51.578 271.393,51.099 270.434,50.619 269.477,50.139 268.04,49.66 267.081,49.181 266.604,48.705 
               265.645,48.226 264.688,47.747 263.731,47.268 263.249,46.789 262.292,46.308 261.813,45.829 261.333,45.35 260.375,44.872 
               259.897,44.393 259.42,43.913 258.461,43.434 257.981,42.955 257.502,42.476 257.024,41.997 256.065,41.52 255.586,41.041 
               255.108,40.562 254.149,40.083 253.668,39.603 253.19,39.124 252.711,38.646 251.756,38.167 251.276,37.688 250.797,37.207 
               249.838,36.728 249.36,36.249 248.881,35.77 247.922,35.291 247.444,34.812 246.963,34.335 246.004,33.856 245.526,33.376 
               245.049,32.897 244.092,32.418 243.612,31.939 242.655,31.46 242.176,30.981 241.215,30.502 240.737,30.023 240.258,29.544 
               239.299,29.064 238.821,28.584 237.864,28.105 236.907,27.626 236.427,27.15 235.469,26.671 234.99,26.192 234.032,25.713 
               233.072,25.234 232.594,24.753 231.636,24.274 230.681,23.795 230.201,23.317 229.243,22.838 228.284,22.359 227.326,21.88 
               226.367,21.4 225.41,20.921 224.45,20.442 223.495,19.965 222.537,19.486 221.1,19.007 220.14,18.528 219.182,18.049 
               217.745,17.569 216.31,17.091 214.873,16.612 213.436,16.131 211.998,15.652 210.081,15.173 208.167,14.694 205.771,14.215 
               202.417,13.736 186.613,13.736 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="188.526,103.308 186.134,103.789 183.737,104.267 
               181.821,104.746 180.383,105.225 178.945,105.704 177.511,106.184 176.075,106.663 174.636,107.142 173.199,107.621 
               172.24,108.098 171.284,108.577 169.847,109.056 168.889,109.535 167.931,110.014 166.493,110.493 165.535,110.972 
               164.576,111.451 163.621,111.93 162.662,112.409 161.225,112.89 160.267,113.369 159.309,113.848 158.349,114.327 
               157.391,114.806 156.436,115.285 155.478,115.761 154.52,116.24 153.56,116.72 152.602,117.2 151.166,117.679 150.207,118.158 
               149.25,118.637 148.292,119.116 147.334,119.594 145.897,120.073 144.938,120.553 143.98,121.032 142.546,121.512 
               141.586,121.991 140.628,122.47 139.191,122.946 137.754,123.425 136.794,123.904 135.36,124.383 133.923,124.863 
               132.006,125.343 130.568,125.822 128.653,126.301 126.259,126.78 123.383,127.259 120.512,127.738 116.199,128.217 
               109.493,128.696 92.727,129.175 92.727,133.006 93.686,133.485 95.124,133.965 96.561,134.444 97.998,134.923 99.437,135.403 
               100.871,135.881 102.308,136.36 103.746,136.839 105.183,137.315 106.621,137.794 108.056,138.275 109.493,138.754 
               110.93,139.233 111.89,139.712 113.327,140.191 114.282,140.67 115.72,141.149 116.678,141.628 117.158,142.107 117.637,142.587 
               118.116,143.067 118.594,143.546 118.594,144.501 118.116,145.459 117.637,145.938 117.158,146.417 116.678,146.897 
               115.72,147.376 115.24,147.855 115.24,148.335 115.72,148.813 116.678,149.292 118.116,149.771 119.553,150.251 121.467,150.729 
               123.862,151.209 126.259,151.687 129.61,152.165 134.881,152.645 153.081,152.645 156.915,152.165 159.788,151.687 
               162.183,151.209 164.576,150.729 166.493,150.251 168.889,149.771 170.326,149.292 172.24,148.813 174.157,148.335 
               175.594,147.855 177.511,147.376 178.945,146.897 180.383,146.417 181.821,145.938 183.258,145.459 184.697,144.979 
               186.134,144.501 187.568,144.024 189.005,143.546 189.963,143.067 191.402,142.587 192.839,142.107 193.798,141.628 
               195.232,141.149 196.669,140.67 197.628,140.191 199.064,139.712 200.024,139.233 200.982,138.754 202.417,138.275 
               203.376,137.794 204.813,137.315 205.771,136.839 206.729,136.36 208.167,135.881 209.125,135.403 210.081,134.923 
               211.039,134.444 212.478,133.965 213.436,133.485 214.394,133.006 215.352,132.527 216.31,132.048 217.266,131.568 
               218.703,131.089 219.662,130.61 220.621,130.132 221.579,129.654 222.537,129.175 223.495,128.696 224.45,128.217 
               225.41,127.738 226.367,127.259 227.326,126.78 228.284,126.301 229.243,125.822 230.201,125.343 231.156,124.863 
               232.114,124.383 233.072,123.904 234.511,123.425 235.469,122.946 236.907,122.47 238.821,121.991 240.737,121.512 
               242.655,121.032 245.526,120.553 257.502,120.553 258.461,120.553 258.461,120.073 257.981,119.594 257.502,119.116 
               256.545,118.637 255.586,118.158 254.627,117.679 253.668,117.2 252.711,116.72 251.276,116.24 250.317,115.761 249.36,115.285 
               247.922,114.806 246.485,114.327 245.049,113.848 243.612,113.369 241.696,112.89 239.778,112.409 236.907,111.93 
               227.805,111.93 223.971,112.409 221.1,112.89 218.224,113.369 215.831,113.848 213.915,114.327 211.998,114.806 210.081,115.285 
               208.167,115.761 206.25,116.24 204.334,116.72 202.896,117.2 200.982,117.679 199.545,118.158 197.628,118.637 196.19,119.116 
               194.277,119.594 192.839,120.073 191.402,120.553 189.484,121.032 188.047,121.512 186.613,121.991 185.176,122.47 
               183.737,122.946 181.821,123.425 181.341,123.425 182.3,122.946 182.779,122.47 183.258,121.991 184.216,121.512 
               184.697,121.032 185.655,120.553 186.134,120.073 187.092,119.594 187.568,119.116 188.526,118.637 189.484,118.158 
               189.963,117.679 190.923,117.2 191.881,116.72 192.839,116.24 193.798,115.761 194.756,115.285 196.19,114.806 197.148,114.327 
               198.107,113.848 199.545,113.369 200.982,112.89 201.942,112.409 203.376,111.93 204.813,111.451 206.25,110.972 
               207.687,110.493 209.602,110.014 211.039,109.535 212.957,109.056 214.873,108.577 217.266,108.098 219.182,107.621 
               221.579,107.142 224.45,106.663 227.326,106.184 230.681,105.704 233.553,105.225 233.553,104.746 232.114,104.267 
               226.367,103.789 218.703,103.308 205.771,102.829 196.669,102.829 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="267.081,123.425 266.604,124.383 266.125,124.863 
               265.645,125.822 265.167,126.301 264.688,126.78 264.209,127.259 263.731,128.217 263.249,128.696 262.77,129.175 
               262.292,129.654 261.813,130.132 261.333,130.61 260.854,131.089 260.375,131.568 259.42,132.048 258.94,132.527 
               258.461,133.006 257.981,133.485 257.502,133.965 256.545,134.444 256.065,134.923 255.586,135.403 254.627,135.881 
               254.149,136.36 253.19,136.839 252.711,137.315 251.756,137.794 251.276,138.275 250.317,138.754 249.36,139.233 
               248.401,139.712 247.444,140.191 246.485,140.67 245.526,141.149 244.571,141.628 243.612,142.107 242.176,142.587 
               241.215,143.067 239.778,143.546 238.342,144.024 236.907,144.501 235.469,144.979 234.032,145.459 232.114,145.938 
               230.681,146.417 228.763,146.897 226.847,147.376 224.931,147.855 222.537,148.335 220.068,148.599 221.1,148.335 
               221.579,147.855 222.537,147.376 223.016,146.897 223.495,146.417 224.45,145.938 224.931,145.459 225.41,144.979 
               225.888,144.501 226.367,144.024 226.847,143.546 227.805,143.067 228.284,142.587 228.763,142.107 229.243,141.628 
               229.722,141.149 230.201,140.67 230.681,140.191 231.156,139.712 231.636,139.233 232.114,138.754 232.594,138.275 
               233.072,137.794 233.553,137.315 234.032,136.839 234.511,136.36 234.99,135.881 235.469,135.403 235.948,134.923 
               236.427,134.444 236.907,133.965 237.385,133.485 237.864,133.006 238.342,132.527 238.821,132.048 239.299,131.568 
               239.299,130.61 234.511,130.61 231.636,131.089 229.722,131.568 228.284,132.048 226.847,132.527 225.888,133.006 
               224.931,133.485 223.971,133.965 223.016,134.444 222.537,134.923 221.579,135.403 221.1,135.881 220.14,136.36 219.662,136.839 
               218.703,137.315 218.224,137.794 217.266,138.275 216.787,138.754 215.831,139.233 215.352,139.712 214.394,140.191 
               213.915,140.67 212.957,141.149 212.478,141.628 211.518,142.107 210.56,142.587 210.081,143.067 209.125,143.546 
               208.167,144.024 207.687,144.501 206.729,144.979 205.771,145.459 204.813,145.938 203.854,146.417 202.896,146.897 
               202.417,147.376 201.461,147.855 200.024,148.335 199.064,148.813 198.107,149.292 197.148,149.771 196.19,150.251 
               194.756,150.729 193.798,151.209 192.839,151.687 191.402,152.165 190.444,152.645 189.005,153.123 187.568,153.603 
               186.134,154.081 184.697,154.561 183.258,155.039 181.821,155.52 180.383,155.999 178.47,156.478 176.554,156.957 
               174.636,157.437 172.718,157.915 170.805,158.395 168.41,158.87 166.014,159.349 163.143,159.83 160.267,160.309 
               156.436,160.787 151.644,161.267 143.022,161.746 133.923,161.746 124.822,161.267 120.031,160.787 115.72,160.309 
               112.369,159.83 109.493,159.349 107.098,158.87 104.704,158.395 102.787,158.395 102.787,158.87 103.267,159.349 104.704,159.83 
               105.662,160.309 106.621,160.787 107.577,161.267 109.014,161.746 109.972,162.225 110.93,162.704 112.369,163.183 
               113.327,163.662 114.761,164.143 115.72,164.622 117.158,165.101 118.116,165.579 119.553,166.056 120.991,166.534 
               122.425,167.014 123.383,167.492 124.822,167.972 126.259,168.452 127.696,168.931 129.61,169.41 131.047,169.89 
               132.485,170.368 134.402,170.847 136.315,171.326 138.712,171.806 141.107,172.284 153.081,172.284 155.478,171.806 
               157.391,171.326 158.83,170.847 161.77,170.377 161.225,172.284 160.745,172.764 160.267,173.72 159.788,174.198 
               159.309,174.678 158.83,175.157 158.349,175.636 157.87,176.115 156.915,176.594 156.436,177.074 155.957,177.554 
               155.478,178.032 154.52,178.512 154.04,178.99 153.081,179.47 152.602,179.949 152.123,180.425 151.166,180.904 150.687,181.385 
               149.729,181.863 149.25,182.342 148.292,182.821 147.334,183.301 147.334,184.259 149.729,184.259 157.87,183.779 
               165.535,183.301 173.199,182.821 180.862,182.342 184.697,181.863 187.092,181.385 189.005,180.904 190.444,180.425 
               191.881,179.949 193.319,179.47 194.756,178.99 195.711,178.512 197.148,178.032 198.107,177.554 199.545,177.074 
               200.503,176.594 201.461,176.115 202.417,175.636 203.376,175.157 204.813,174.678 205.771,174.198 206.729,173.72 
               207.687,173.241 208.647,172.764 209.602,172.284 210.56,171.806 211.518,171.326 212.478,170.847 213.436,170.368 
               214.394,169.89 215.352,169.41 216.31,168.931 217.266,168.452 218.224,167.972 219.182,167.492 220.14,167.014 221.1,166.534 
               222.058,166.056 223.016,165.579 223.971,165.101 224.931,164.622 225.888,164.143 226.847,163.662 227.805,163.183 
               228.763,162.704 229.722,162.225 230.681,161.746 232.114,161.267 233.072,160.787 234.032,160.309 235.469,159.83 
               236.427,159.349 237.385,158.87 238.821,158.395 240.258,157.915 241.215,157.437 242.655,156.957 244.092,156.478 
               246.004,155.999 247.444,155.52 249.36,155.039 251.276,154.561 253.668,154.081 256.065,153.603 259.42,153.123 
               263.731,152.645 278.1,152.645 281.93,152.645 281.93,152.165 281.452,151.687 280.975,151.209 280.495,150.729 280.016,150.251 
               279.536,149.771 279.057,149.292 278.579,148.813 278.1,148.335 277.62,147.855 277.141,147.376 276.663,146.897 
               276.182,146.417 275.704,145.938 275.223,145.459 274.745,144.501 274.266,144.024 273.79,143.546 273.311,142.587 
               272.833,142.107 272.352,141.149 271.872,138.275 271.872,137.794 271.872,131.568 271.393,129.175 270.915,127.738 
               270.434,126.78 269.956,125.822 269.477,124.863 268.999,123.904 268.518,123.425 268.04,122.946 267.559,122.946 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="269.477,154.561 266.604,155.039 264.688,155.52 
               262.77,155.999 260.854,156.478 258.94,156.957 257.502,157.437 256.065,157.915 254.149,158.395 252.711,158.87 
               251.756,159.349 250.317,159.83 248.881,160.309 247.444,160.787 246.485,161.267 245.049,161.746 244.092,162.225 
               243.133,162.704 241.696,163.183 240.737,163.662 239.778,164.143 238.342,164.622 237.385,165.101 236.427,165.579 
               235.469,166.056 234.511,166.534 233.553,167.014 232.594,167.492 231.636,167.972 230.681,168.452 229.722,168.931 
               228.763,169.41 227.805,169.89 226.847,170.368 226.367,170.847 225.41,171.326 224.45,171.806 223.971,172.284 223.016,172.764 
               222.058,173.241 221.579,173.72 220.621,174.198 220.14,174.678 219.182,175.157 218.703,175.636 218.224,176.115 
               217.745,176.594 216.787,177.074 216.31,177.554 215.831,178.032 215.352,178.512 214.394,178.99 213.915,179.47 
               212.957,179.949 212.478,180.425 211.518,180.904 210.56,181.385 209.602,181.863 209.125,182.342 207.687,182.821 
               206.729,183.301 205.771,183.779 204.813,184.259 203.376,184.737 201.942,185.217 200.503,185.697 198.585,186.176 
               196.669,186.655 194.756,187.134 192.839,187.61 190.923,188.089 189.963,188.568 189.005,189.047 188.047,189.526 
               187.568,190.007 187.092,190.486 186.613,190.965 186.134,191.444 185.655,191.923 185.176,192.881 184.697,193.839 
               184.216,194.794 183.737,195.754 183.737,197.19 184.216,197.19 184.697,196.712 185.176,196.232 186.134,195.754 
               186.613,195.274 188.047,194.794 189.484,194.319 191.402,193.839 195.232,193.359 196.19,193.359 199.064,193.839 
               200.024,194.319 201.942,194.794 203.854,195.274 206.729,195.754 219.662,195.754 222.537,195.274 224.45,194.794 
               226.367,194.319 227.805,193.839 229.243,193.359 230.201,192.881 231.156,192.401 232.114,191.923 233.072,191.444 
               233.553,190.965 234.511,190.486 234.99,190.007 235.948,189.526 236.427,189.047 236.907,188.568 237.864,188.089 
               238.342,187.61 238.821,187.134 239.299,186.655 239.778,186.176 240.258,185.697 240.737,185.217 241.215,184.737 
               241.696,184.259 242.176,183.779 242.655,183.301 243.133,182.821 243.612,182.342 244.092,181.863 244.571,181.385 
               245.049,180.425 245.526,179.949 246.004,179.47 246.485,178.99 246.963,178.512 247.444,178.032 247.922,177.554 
               248.401,177.074 248.881,176.594 249.36,176.115 249.838,175.636 250.317,175.157 250.797,174.678 251.276,174.198 
               251.756,173.72 252.235,173.241 252.711,172.764 253.19,172.284 253.668,171.806 254.627,171.326 255.108,170.847 
               255.586,170.368 256.545,169.89 257.502,169.41 258.461,168.931 259.42,168.452 260.375,167.972 261.813,167.492 
               263.249,167.014 265.645,166.534 267.559,166.534 269.477,167.014 270.434,167.492 271.393,167.972 271.872,168.452 
               272.833,168.931 273.311,169.41 274.266,169.89 274.745,170.368 275.223,170.847 275.704,171.326 276.182,171.806 
               276.663,172.284 277.141,172.764 277.62,173.241 278.1,173.72 278.579,174.198 279.057,174.678 279.536,175.157 280.016,175.636 
               280.495,176.115 280.975,176.594 281.452,177.074 281.93,177.554 282.409,178.032 282.887,178.512 283.846,178.99 
               284.325,179.47 285.764,179.949 286.721,180.425 288.635,180.904 291.51,181.385 295.344,181.863 299.174,182.342 
               301.09,182.821 302.53,183.301 303.485,183.779 304.442,184.259 305.401,184.737 305.879,185.217 306.358,185.697 
               306.838,186.655 307.319,187.61 307.797,189.047 307.797,191.923 307.319,192.881 306.838,193.359 306.358,193.839 
               305.879,194.319 304.922,194.794 303.485,195.274 302.049,195.754 300.133,196.232 300.133,197.19 301.09,197.67 
               301.571,198.148 302.53,198.629 303.004,199.108 303.963,199.587 305.401,200.066 306.358,200.545 307.797,201.024 
               313.065,201.024 315.461,200.545 316.899,200.066 318.813,199.587 320.249,199.108 321.688,198.629 322.645,198.148 
               324.084,197.67 326.547,197.214 325.997,198.148 325.518,200.545 325.042,201.979 325.042,202.938 325.997,202.938 
               326.477,202.458 327.434,201.979 328.393,201.504 328.872,201.024 329.35,200.545 329.831,200.066 330.309,199.587 
               330.79,199.108 331.268,198.148 331.747,196.712 331.747,188.089 331.268,186.655 330.79,185.217 330.309,184.737 
               329.831,183.779 329.35,183.301 328.872,182.821 328.393,182.342 327.913,181.863 327.434,181.385 326.477,180.904 
               325.042,180.425 322.167,179.949 316.899,179.47 311.147,178.99 307.319,178.512 304.442,178.032 302.049,177.554 
               300.133,177.074 298.696,176.594 297.258,176.115 296.299,175.636 295.344,175.157 294.385,174.678 293.426,174.198 
               292.948,173.72 291.989,173.241 291.51,172.764 291.032,172.284 290.073,171.806 289.594,171.326 289.116,170.847 
               288.635,169.89 288.159,169.41 287.68,168.931 287.2,167.972 286.721,166.534 286.721,164.143 287.2,162.225 287.68,161.267 
               288.159,160.309 288.635,159.83 289.116,159.349 289.594,158.87 290.073,158.395 290.551,157.915 291.032,157.437 
               291.51,156.957 291.51,156.478 289.594,156.478 286.243,156.957 282.887,157.437 281.868,157.905 282.409,155.999 
               282.409,154.561 280.495,154.081 273.311,154.081 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="240.737,63.074 238.821,63.55 237.864,64.029 
               236.427,64.508 235.948,64.989 234.99,65.468 234.511,65.947 234.032,66.426 233.553,66.905 233.072,67.384 232.594,68.343 
               232.114,68.822 231.636,69.781 231.636,71.694 232.114,71.694 236.907,71.694 238.342,72.172 239.299,72.651 240.258,73.131 
               241.215,73.61 242.176,74.09 242.655,74.569 243.612,75.048 244.092,75.527 245.049,76.006 245.526,76.487 246.004,76.966 
               246.963,77.445 247.444,77.924 247.922,78.403 248.401,78.882 248.881,79.358 249.36,79.837 250.317,80.316 250.797,80.795 
               251.276,81.274 251.756,81.753 252.235,82.232 252.711,82.712 253.668,83.191 254.149,83.67 254.627,84.15 255.108,84.629 
               256.065,85.108 256.545,85.588 257.502,86.067 258.461,86.543 259.897,87.022 263.731,87.022 265.167,86.543 265.645,86.067 
               266.125,85.588 266.125,84.15 265.645,83.67 264.688,83.191 264.209,82.712 263.731,82.232 263.249,81.753 262.292,81.274 
               261.813,80.795 261.333,80.316 260.854,79.837 260.375,79.358 259.42,78.882 258.94,78.403 258.461,77.924 257.981,77.445 
               257.502,76.966 257.024,76.487 256.545,76.006 256.065,75.048 255.586,74.569 255.108,74.09 254.627,73.131 254.149,72.172 
               254.149,70.26 254.627,69.302 255.586,68.822 263.249,68.822 264.209,68.822 264.209,67.864 263.731,67.384 262.77,66.905 
               262.292,66.426 261.333,65.947 260.854,65.468 259.42,64.989 258.461,64.508 257.024,64.029 255.108,63.55 252.711,63.074 
               247.922,62.595 245.049,62.595 			"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="369.586,147.855 370.065,148.335 370.543,152.645 
               371.022,153.603 371.502,154.081 372.459,154.561 374.377,155.039 374.856,155.52 374.377,155.999 372.459,156.478 
               371.502,156.957 370.543,157.437 370.065,158.395 370.065,159.349 370.543,160.309 371.022,160.787 373.899,160.787 
               375.815,160.309 376.77,159.83 378.208,159.349 379.167,158.87 380.124,158.395 380.604,157.915 381.083,156.957 
               381.083,154.081 380.604,153.123 380.124,152.165 379.645,151.687 379.167,151.209 378.686,150.729 378.208,150.251 
               377.729,149.771 376.77,149.292 376.293,148.813 375.815,148.335 374.856,147.855 373.899,147.376 372.94,146.897 
               369.586,146.897 			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              d="M77.997,109.537l14.73,3.487v0.345l0.958,0.479l1.917,0.479
               l1.916,0.479l2.394,0.479l5.271,0.476h5.268l6.227-0.476l3.353-0.479l2.873-0.479l2.396-0.479l2.395-0.479l1.915-0.479
               l2.396-0.48l1.917-0.479l1.913-0.479l1.438-0.479l1.917-0.479l1.916-0.479l1.438-0.479l1.913-0.479l1.438-0.479l1.437-0.479
               l1.916-0.478l1.436-0.479l1.437-0.479l1.917-0.479l1.438-0.479l1.437-0.479l1.915-0.479l1.437-0.479l1.437-0.479l1.917-0.48
               l1.435-0.479l1.437-0.479l1.917-0.479l1.437-0.479l1.913-0.479l1.918-0.476l1.437-0.479l1.918-0.48l1.913-0.479l1.917-0.479
               l1.917-0.479l2.397-0.479l1.917-0.479l2.392-0.479l2.396-0.479l2.875-0.479l3.351-0.479l5.269-0.481h8.622l6.226,0.481
               l3.833,0.479l3.352,0.479l2.874,0.479l2.397,0.479l2.392,0.479l2.396,0.479l1.917,0.479l1.914,0.479l1.916,0.479l1.918,0.48
               l1.916,0.479l1.436,0.476l1.916,0.479l1.438,0.479l1.918,0.479l1.434,0.479l1.438,0.479l1.438,0.48l1.438,0.479l1.438,0.479
               l1.436,0.479l1.438,0.479l1.436,0.479l1.439,0.479l1.438,0.479l1.434,0.479l0.959,0.478l1.438,0.479l1.438,0.479l0.959,0.479
               l1.438,0.479l0.955,0.479l1.438,0.479l0.959,0.479l1.438,0.479l0.957,0.479l1.439,0.48l0.955,0.479l0.957,0.479l1.438,0.479
               l0.961,0.479l0.957,0.479l0.957,0.476l1.436,0.479l0.959,0.48l0.957,0.479l0.959,0.479l0.957,0.479h0.959v-0.479l-0.479-0.479
               l-0.48-0.479l-0.479-0.48l-0.479-0.479l-0.959-0.476l-0.479-0.479l-0.479-0.479l-0.959-0.479l-0.477-0.479l-0.479-0.479
               l-0.959-0.48l-0.479-0.479l-0.957-0.479l-0.482-0.479l-0.479-0.479l-0.957-0.479l-0.48-0.479l-0.479-0.479l-0.957-0.479
               l-0.477-0.479l-0.959-0.478l-0.48-0.479l-0.479-0.479l-0.957-0.479l-0.48-0.479l-0.957-0.479l-0.48-0.479l-0.959-0.479
               l-0.479-0.479l-0.955-0.48l-0.479-0.479l-0.479-0.479l-0.961-0.479l-0.479-0.479l-0.959-0.479l-0.479-0.476l-0.957-0.479
               l-0.48-0.48l-0.959-0.479l-0.479-0.479l-0.955-0.479l-0.959-0.479l-0.479-0.479l-0.957-0.479l-0.482-0.479l-0.957-0.479
               l-0.959-0.479l-0.957-0.481l-0.479-0.479l-0.957-0.479l-0.959-0.476l-0.957-0.479l-1.438-0.479l-0.959-0.479l-0.959-0.479
               l-1.434-0.481l-0.959-0.479l-1.438-0.479l-1.438-0.479l-1.438-0.479l-1.438-0.479l-1.914-0.479l-1.916-0.48l-1.438-0.479
               l-2.395-0.479l-2.395-0.476l-2.396-0.479l-2.871-0.48l-3.354-0.479l-3.831-0.479l-5.75-0.479l-9.099-0.479h-5.75v-0.959h0.959
               l0.958-0.479l0.958-0.479l0.479-0.479l0.958-0.479l1.438-0.479l1.435-0.479l1.437-0.476l2.876-0.479h14.848h5.27v-0.479
               l-0.479-0.479l-0.48-0.479l-0.479-0.479l-0.479-0.96l-0.479-0.479l-0.479-0.479l-0.476-0.479l-0.479-0.48l-0.479-0.479
               l-0.479-0.479l-0.48-0.958l-0.479-0.479l-0.479-0.479l-0.479-0.476l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479
               l-0.479-0.479l-0.48-0.48l-0.479-0.479l-0.476-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.48l-0.479-0.479
               l-0.479-0.479l-0.48-0.476l-0.479-0.479l-0.48-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479
               l-0.957-0.479l-0.479-0.48l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.958-0.479l-0.479-0.479l-0.479-0.477l-0.959-0.479
               l-0.479-0.48l-0.958-0.48l-0.477-0.479l-0.958-0.479l-0.48-0.479l-0.958-0.479l-0.479-0.479l-0.958-0.479l-0.958-0.479
               l-0.958-0.479l-0.48-0.48l-0.954-0.479l-0.959-0.479l-1.438-0.476l-0.959-0.479l-0.958-0.479l-1.438-0.479l-1.434-0.479
               l-1.437-0.48l-1.917-0.479l-2.397-0.479l-3.35-0.479h-9.58l-3.356,0.479l-2.392,0.479l-1.916,0.479l-1.439,0.48l-1.437,0.479
               l-1.435,0.479l-1.438,0.479l-1.437,0.479l-1.438,0.476l-1.438,0.479l-1.435,0.479l-1.437,0.48l-1.438,0.479l-1.917,0.479
               l-1.437,0.479l-1.915,0.479l-1.437,0.479l-1.918,0.479l-1.916,0.479l-2.394,0.479l-2.396,0.48l-2.875,0.48l-3.831,0.479h-14.848
               l-3.831-0.479l-2.396-0.48l-2.396-0.48l-1.914-0.479l-1.917-0.479l-1.437-0.479l-1.439-0.479l-1.437-0.479l-0.955-0.479
               l-1.439-0.479l-0.958-0.479l-1.437-0.48l-1.438-0.479l-1.438-0.479l-1.913-0.476l-2.875-0.479h-9.102l-2.394,0.479l-1.916,0.476
               L73.09,49.66l-1.438,0.479l-0.955,0.48l-0.958,0.479l-0.958,0.479v0.479l1.438,0.479l3.352,0.479l1.916,0.479l1.918,0.479
               l1.434,0.479l1.437,0.48l0.958,0.48l1.438,0.479l0.958,0.477l0.958,0.479l0.957,0.479l0.958,0.479l0.958,0.479l0.958,0.479
               l0.479,0.48l0.958,0.479l0.48,0.479l0.479,0.479l0.958,0.479l0.479,0.479l0.476,0.479l0.479,0.479l0.479,0.479l0.48,0.955v0.479
               H82.67l-4.313-0.479l-3.352-0.479l-2.875-0.476l-2.392-0.479l-2.397-0.479l-1.916-0.479l-1.913-0.479l-1.918-0.479L60.158,60.2
               l-1.917-0.479l-1.437-0.479l-1.434-0.48l-1.439-0.479l-1.437-0.479l-0.958-0.479l-1.438-0.479h-0.479v0.958l0.958,0.479
               l0.479,0.479l0.481,0.48l0.958,0.479l0.479,0.479l0.479,0.479l0.959,0.479l0.479,0.479l0.958,0.479l0.479,0.479l0.476,0.479
               l0.479,0.476l0.958,0.479l0.479,0.479l0.479,0.48l0.479,0.479l0.96,0.479l0.479,0.479l0.479,0.479l0.479,0.479l0.479,0.48
               l0.48,0.479l0.479,0.479l0.476,0.479l0.47,1.023l-1.906-0.544l-4.313-0.479l-3.35-0.479l-2.396-0.479l-1.918-0.479l-1.913-0.48
               l-1.917-0.479l-1.917-0.479l-1.438-0.479l-1.438-0.479l-1.437-0.479l-1.434-0.48l-1.437-0.479l-1.439-0.479l-1.437-0.476
               l-0.958-0.479l-1.436-0.479l-0.958-0.479l-1.437-0.479l-0.958-0.479L27.584,60.2h-0.958v0.479l0.479,0.479l0.479,0.958
               l0.48,0.479l0.479,0.479l0.479,0.476l0.479,0.479l0.479,0.479l0.479,0.48l0.479,0.479l0.479,0.479l0.958,0.479l0.48,0.479
               l0.475,0.479l0.48,0.48l0.479,0.479l0.479,0.479l0.958,0.479l0.479,0.479l0.481,0.479l0.958,0.476l0.479,0.479l0.479,0.479
               l0.958,0.479l0.479,0.479l0.476,0.479l0.958,0.479l0.479,0.48l0.959,0.479l0.958,0.479l0.479,0.479l0.958,0.479l0.959,0.481
               l0.479,0.479l0.958,0.479l0.958,0.479l0.955,0.479l0.958,0.479l0.96,0.476l0.958,0.479l0.958,0.479l0.959,0.479l1.438,0.479
               l0.955,0.479l1.437,0.479l0.958,0.48l1.438,0.479l1.437,0.479l1.438,0.479l1.913,0.479l1.438,0.479l1.917,0.48
               c5.102-0.425,16.097-7.186,21.074-7.186h2.361l0.512,9.098h3.355l4.789-0.479l3.833-0.479l2.873-0.476l2.874-0.479l2.875-0.48
               l2.394-0.479l2.396-0.479l1.916-0.479l2.394-0.479l1.918-0.479l1.916-0.48l1.917-0.479l1.914-0.479l1.917-0.479l1.917-0.479
               l1.913-0.479l1.438-0.479l1.917-0.476l1.916-0.479l1.438-0.479l1.434-0.479l1.917-0.479l1.437-0.479l1.437-0.481l-0.479,0.481
               l-0.479,0.479l-0.958,0.479l-0.479,0.479l-0.479,0.479l-0.481,0.479l-0.958,0.476l-0.479,0.479l-0.479,0.479l-0.955,0.479
               l-0.479,0.479l-0.959,0.479l-0.479,0.479l-0.479,0.48l-0.958,0.479l-0.958,0.479l-0.479,0.479l-0.959,0.479l-0.479,0.479
               l-0.955,0.48l-0.958,0.479l-0.958,0.476l-0.959,0.479l-0.958,0.479l-0.958,0.48l-0.958,0.479l-0.958,0.479l-1.436,0.479
               l-0.958,0.479l-1.437,0.479l-1.917,0.479l-1.913,0.481l-2.397,0.479l-2.874,0.479l-4.79,0.479l-11.018,0.479l-7.186,0.476v0.085
               l-15.263,0.873h-1.979l-2.395,0.481l-2.394,0.479l-1.917,0.479l-1.438,0.479l-1.916,0.479l-1.437,0.479l-1.436,0.479
               l-1.438,0.479l-1.438,0.479l-1.437,0.479l-0.958,0.48l-1.434,0.479l-0.958,0.476l-1.438,0.479l-0.958,0.479l-0.958,0.479
               l-1.438,0.479l-0.955,0.479l-0.958,0.48l-0.958,0.479l-0.958,0.479l-0.959,0.479l-0.958,0.479l-0.958,0.479l-0.479,0.479v0.479
               h4.792h7.665l8.143,0.479l4.308,0.478l3.831,0.479l2.875,0.479l2.876,0.479h0.594V109.537z"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFCB05"
              points="148.771,76.006 148.849,75.446 149.25,76.006 			"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#5D0000"
            d="M152.135,72.138l0.078-0.56l0.401,0.56H152.135L152.135,72.138z
            M81.36,105.668l14.73,3.488v0.344l0.958,0.479l1.917,0.479l1.916,0.479l2.394,0.479l5.271,0.476h5.268l6.228-0.476l3.354-0.479
           l2.872-0.479l2.397-0.479l2.396-0.479l1.914-0.479l2.395-0.48l1.917-0.479l1.914-0.479l1.438-0.479l1.917-0.48l1.916-0.479
           l1.438-0.479l1.913-0.479l1.439-0.479l1.437-0.479l1.917-0.477l1.436-0.479l1.437-0.479l1.916-0.479l1.438-0.479l1.438-0.479
           l1.914-0.479l1.438-0.479l1.437-0.479l1.918-0.481l1.434-0.479l1.437-0.479l1.918-0.479l1.437-0.479l1.913-0.479l1.917-0.477
           l1.437-0.479l1.917-0.481l1.914-0.479l1.916-0.479l1.917-0.479l2.396-0.479l1.916-0.479l2.393-0.479l2.396-0.479l2.875-0.479
           l3.351-0.479l5.269-0.48h8.623l6.225,0.48l3.834,0.479l3.351,0.479l2.875,0.479l2.396,0.479l2.392,0.479l2.397,0.479l1.916,0.479
           l1.914,0.479l1.918,0.479l1.916,0.481l1.916,0.479l1.436,0.477l1.916,0.479l1.438,0.479l1.918,0.479l1.434,0.479l1.438,0.479
           l1.439,0.481l1.436,0.479l1.438,0.479l1.436,0.479l1.438,0.479l1.438,0.479l1.438,0.479l1.438,0.479l1.434,0.479l0.959,0.477
           l1.438,0.479l1.438,0.479l0.959,0.479l1.438,0.479l0.955,0.479l1.438,0.48l0.959,0.479l1.438,0.479l0.957,0.479l1.439,0.48
           l0.955,0.479l0.959,0.479l1.436,0.479l0.961,0.479l0.957,0.479l0.959,0.476l1.434,0.479l0.959,0.48l0.959,0.479l0.957,0.479
           l0.959,0.479h0.957v-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.48l-0.48-0.479l-0.957-0.476l-0.48-0.479l-0.479-0.479
           l-0.959-0.479l-0.477-0.479l-0.479-0.479l-0.959-0.48l-0.479-0.479l-0.957-0.479l-0.48-0.479l-0.48-0.48l-0.957-0.479
           l-0.479-0.479l-0.48-0.479l-0.957-0.479l-0.477-0.479l-0.959-0.477l-0.48-0.479l-0.479-0.479l-0.957-0.479l-0.479-0.479
           l-0.959-0.479l-0.48-0.479l-0.959-0.479l-0.479-0.479l-0.955-0.481l-0.479-0.479l-0.479-0.479l-0.961-0.479l-0.479-0.479
           l-0.957-0.479l-0.48-0.477l-0.957-0.479l-0.48-0.481l-0.959-0.479l-0.479-0.479l-0.955-0.479l-0.959-0.479l-0.479-0.479
           l-0.957-0.479l-0.48-0.479l-0.959-0.479l-0.957-0.479l-0.959-0.48l-0.479-0.479l-0.957-0.479l-0.957-0.476l-0.959-0.479
           l-1.438-0.479l-0.959-0.48l-0.959-0.479l-1.434-0.48l-0.957-0.479l-1.439-0.479l-1.438-0.479l-1.438-0.479l-1.438-0.479
           l-1.914-0.479l-1.916-0.479l-1.438-0.479l-2.395-0.479l-2.395-0.476l-2.397-0.479l-2.871-0.48l-3.354-0.479l-3.83-0.479
           l-5.75-0.479l-9.098-0.479h-5.751v-0.959h0.959l0.958-0.479l0.958-0.479l0.479-0.479l0.958-0.479l1.438-0.479l1.434-0.479
           l1.437-0.477l2.876-0.479h14.848h5.269v-0.479l-0.479-0.479l-0.481-0.479l-0.479-0.479l-0.479-0.959l-0.479-0.479l-0.479-0.479
           l-0.476-0.479l-0.479-0.481l-0.479-0.479l-0.479-0.479l-0.481-0.958l-0.479-0.479l-0.479-0.479l-0.479-0.476l-0.479-0.479
           l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.48l-0.479-0.479l-0.476-0.479l-0.479-0.479L225.9,61.6
           l-0.479-0.479l-0.479-0.48l-0.479-0.479l-0.479-0.479l-0.48-0.477l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479
           l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.957-0.479l-0.479-0.48l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.958-0.479
           l-0.479-0.479l-0.479-0.476l-0.96-0.479l-0.479-0.48l-0.958-0.479l-0.476-0.479l-0.958-0.479l-0.48-0.479l-0.958-0.479
           l-0.479-0.479l-0.958-0.479l-0.958-0.479l-0.958-0.479l-0.48-0.48l-0.953-0.479l-0.96-0.479l-1.437-0.476l-0.959-0.479
           l-0.958-0.479l-1.438-0.479l-1.434-0.479l-1.437-0.48l-1.918-0.479l-2.396-0.479l-3.35-0.479h-9.581l-3.356,0.479l-2.393,0.479
           l-1.916,0.479l-1.439,0.48l-1.437,0.479l-1.434,0.479l-1.438,0.479l-1.437,0.479l-1.437,0.476l-1.439,0.479l-1.434,0.479
           l-1.438,0.48l-1.438,0.479l-1.916,0.479l-1.437,0.479l-1.915,0.479l-1.438,0.479l-1.917,0.479l-1.917,0.479l-2.394,0.479
           l-2.395,0.479l-2.875,0.48l-3.831,0.479h-14.848l-3.831-0.479l-2.396-0.48l-2.396-0.479l-1.915-0.479l-1.916-0.479l-1.437-0.479
           l-1.438-0.479l-1.438-0.479l-0.955-0.479l-1.438-0.479l-0.958-0.479l-1.437-0.48l-1.437-0.479l-1.439-0.479l-1.913-0.476
           l-2.875-0.479H82.2l-2.393,0.479l-1.917,0.476l-1.437,0.479l-1.438,0.479l-0.955,0.48l-0.958,0.479l-0.958,0.479v0.479
           l1.437,0.479l3.352,0.479l1.916,0.479l1.918,0.479l1.434,0.479l1.438,0.479l0.958,0.48l1.438,0.479l0.958,0.476l0.958,0.479
           l0.957,0.479l0.958,0.479l0.958,0.479l0.958,0.479l0.479,0.48l0.958,0.479l0.481,0.479l0.479,0.479l0.958,0.479l0.479,0.479
           l0.476,0.479l0.479,0.479l0.479,0.479l0.481,0.955v0.479H86.034l-4.313-0.479l-3.352-0.479l-2.875-0.477l-2.392-0.479
           l-2.396-0.479l-1.916-0.479l-1.914-0.479l-1.917-0.479l-1.438-0.479l-1.917-0.479l-1.438-0.479l-1.434-0.48l-1.438-0.479
           l-1.437-0.479l-0.958-0.479l-1.438-0.479h-0.479v0.958l0.958,0.479l0.479,0.479l0.48,0.48l0.958,0.479l0.479,0.479l0.479,0.479
           l0.96,0.479l0.479,0.479l0.958,0.479l0.479,0.479l0.476,0.479l0.479,0.477l0.958,0.479l0.479,0.479l0.479,0.48l0.479,0.479
           L64,62.079l0.479,0.479l0.479,0.479l0.479,0.479l0.479,0.48l0.48,0.479l0.479,0.479l0.476,0.479l0.47,1.023l-1.905-0.545
           l-4.313-0.479l-3.35-0.479l-2.396-0.479l-1.917-0.479l-1.914-0.48l-1.916-0.479l-1.917-0.479l-1.437-0.479L45.317,61.6
           l-1.437-0.479l-1.434-0.48l-1.438-0.479l-1.438-0.479l-1.438-0.477l-0.958-0.479l-1.436-0.479l-0.958-0.479l-1.437-0.479
           l-0.958-0.479l-1.439-0.479H29.99v0.479l0.479,0.479l0.479,0.958l0.48,0.479l0.479,0.479l0.479,0.477l0.479,0.479l0.479,0.479
           l0.479,0.48l0.479,0.479l0.479,0.479l0.958,0.479l0.48,0.479l0.475,0.479l0.48,0.48l0.479,0.479l0.479,0.479l0.958,0.479
           l0.479,0.479l0.48,0.479l0.958,0.476l0.479,0.479l0.479,0.479l0.958,0.479l0.479,0.479l0.477,0.479l0.958,0.479l0.479,0.481
           l0.959,0.479l0.958,0.479l0.479,0.479l0.958,0.479l0.959,0.48l0.479,0.479l0.958,0.479l0.958,0.479l0.955,0.479l0.958,0.479
           l0.959,0.477l0.958,0.479l0.958,0.479l0.96,0.479l1.437,0.479l0.955,0.479l1.438,0.479l0.958,0.48L64,79.324l1.437,0.479
           l1.438,0.479l1.914,0.479l1.437,0.479l1.917,0.48c5.102-0.426,16.097-7.185,21.074-7.185h2.361l0.512,9.098h3.354l4.789-0.479
           l3.833-0.479l2.873-0.476l2.874-0.479l2.876-0.48l2.394-0.479l2.395-0.479l1.917-0.479l2.394-0.479l1.917-0.479l1.916-0.48
           l1.916-0.479l1.915-0.479l1.916-0.479l1.917-0.479l1.914-0.479l1.438-0.479l1.917-0.477l1.916-0.479l1.438-0.479l1.434-0.479
           l1.918-0.479l1.437-0.479l1.438-0.48l-0.479,0.48l-0.479,0.479l-0.958,0.479l-0.479,0.479l-0.479,0.479l-0.48,0.479l-0.958,0.477
           l-0.479,0.479l-0.479,0.479l-0.955,0.479l-0.479,0.479l-0.959,0.479l-0.479,0.479l-0.479,0.48l-0.958,0.479l-0.958,0.479
           l-0.479,0.479l-0.959,0.479l-0.479,0.479l-0.955,0.48l-0.958,0.479l-0.958,0.476l-0.959,0.479l-0.958,0.479l-0.959,0.479
           l-0.958,0.479l-0.958,0.479l-1.436,0.479l-0.958,0.479l-1.437,0.479l-1.918,0.479l-1.913,0.48l-2.396,0.479l-2.874,0.48
           l-4.79,0.479l-11.018,0.479l-7.185,0.476v0.085l-15.263,0.873h-1.979l-2.395,0.48l-2.394,0.479l-1.916,0.479l-1.438,0.479
           l-1.916,0.479l-1.438,0.479l-1.435,0.479l-1.437,0.479l-1.439,0.479l-1.437,0.479l-0.958,0.481l-1.434,0.479l-0.958,0.477
           l-1.439,0.479l-0.958,0.479l-0.958,0.479L53.46,98.96l-0.955,0.479l-0.958,0.481l-0.958,0.479l-0.958,0.479l-0.959,0.479
           l-0.958,0.479l-0.958,0.479l-0.479,0.479v0.479h4.792h7.665l8.143,0.479l4.309,0.477l3.831,0.479l2.875,0.479l2.876,0.479h0.594
           V105.668L81.36,105.668z M372.95,143.987l0.479,0.479l0.479,4.31l0.479,0.958l0.48,0.479l0.957,0.479l1.918,0.479l0.479,0.48
           l-0.479,0.479l-1.918,0.479l-0.957,0.479l-0.959,0.479l-0.479,0.958v0.955l0.479,0.959l0.479,0.479h2.877l1.916-0.479l0.957-0.479
           l1.436-0.48l0.959-0.479l0.959-0.476l0.479-0.479l0.479-0.958v-2.876l-0.479-0.958l-0.479-0.957l-0.48-0.479l-0.479-0.478
           l-0.479-0.479l-0.48-0.479l-0.479-0.479l-0.957-0.479l-0.479-0.479l-0.479-0.479l-0.959-0.479l-0.957-0.479l-0.959-0.479h-3.354
           V143.987L372.95,143.987z M244.1,59.206l4.313-0.479h2.873l4.789,0.479l2.396,0.477l1.916,0.479l1.438,0.479l0.959,0.48
           l1.434,0.479l0.48,0.479l0.957,0.479l0.48,0.479l0.959,0.479l0.479,0.48v0.958h-0.959h-7.664l-0.959,0.479l-0.479,0.958v1.913
           l0.479,0.958l0.48,0.96l0.479,0.479l0.48,0.479l0.479,0.958l0.479,0.48l0.479,0.479l0.48,0.479l0.479,0.479l0.479,0.479
           l0.48,0.479l0.955,0.477l0.479,0.479l0.48,0.479l0.479,0.479l0.479,0.479l0.959,0.479l0.48,0.479l0.479,0.48l0.479,0.479
           l0.959,0.479l0.479,0.479v1.438l-0.479,0.479l-0.48,0.476l-1.436,0.479h-3.834l-1.436-0.479l-0.959-0.476l-0.957-0.479
           l-0.479-0.48l-0.959-0.479l-0.48-0.479l-0.479-0.479l-0.48-0.479l-0.957-0.479l-0.477-0.48l-0.479-0.479l-0.479-0.479l-0.48-0.479
           l-0.48-0.479l-0.957-0.479l-0.479-0.479l-0.48-0.477l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.959-0.479l-0.479-0.479
           l-0.477-0.48l-0.957-0.479l-0.48-0.479l-0.957-0.479l-0.479-0.479l-0.959-0.481l-0.959-0.479l-0.959-0.479l-0.957-0.479
           l-1.436-0.479h-4.792h-0.479v-1.914l0.479-0.958l0.479-0.479l0.479-0.959l0.481-0.479l0.479-0.479l0.48-0.479l0.479-0.479
           l0.957-0.479l0.48-0.48l1.436-0.479l0.957-0.479L244.1,59.206L244.1,59.206z M272.84,150.692l-2.873,0.479l-1.916,0.48
           l-1.916,0.479l-1.918,0.479l-1.914,0.479l-1.438,0.479l-1.436,0.479l-1.918,0.479l-1.438,0.476l-0.955,0.479l-1.439,0.48
           l-1.436,0.479l-1.438,0.479l-0.959,0.479l-1.436,0.479l-0.957,0.48l-0.959,0.479l-1.438,0.479l-0.959,0.479l-0.957,0.48
           l-1.438,0.479l-0.957,0.479l-0.957,0.479l-0.959,0.476l-0.957,0.479l-0.959,0.479l-0.959,0.479l-0.958,0.479l-0.955,0.48
           l-0.958,0.479l-0.96,0.479l-0.958,0.479l-0.958,0.479l-0.479,0.479l-0.958,0.479l-0.959,0.479l-0.479,0.479l-0.955,0.479
           l-0.958,0.478l-0.479,0.479l-0.958,0.479l-0.48,0.479l-0.958,0.479l-0.479,0.479l-0.479,0.479l-0.479,0.479l-0.958,0.48
           l-0.478,0.479l-0.479,0.479l-0.479,0.479l-0.958,0.479l-0.479,0.479l-0.958,0.479l-0.479,0.477l-0.959,0.479l-0.958,0.48
           l-0.958,0.479l-0.476,0.479l-1.438,0.479l-0.958,0.479l-0.958,0.48l-0.958,0.479l-1.438,0.479l-1.434,0.479l-1.438,0.48
           l-1.918,0.479l-1.916,0.479l-1.914,0.479l-1.917,0.477l-1.917,0.479l-0.959,0.479l-0.958,0.479l-0.958,0.479l-0.479,0.48
           l-0.476,0.479l-0.479,0.479l-0.479,0.479l-0.479,0.479l-0.479,0.958l-0.479,0.958l-0.48,0.955l-0.479,0.96v1.437h0.479l0.48-0.479
           l0.479-0.479l0.958-0.479l0.479-0.479l1.434-0.48l1.438-0.475l1.917-0.48l3.831-0.479h0.958l2.875,0.479l0.96,0.48l1.917,0.475
           l1.913,0.48l2.874,0.479h12.932l2.875-0.479l1.913-0.48l1.918-0.475l1.437-0.48l1.439-0.479l0.957-0.479l0.955-0.479l0.958-0.479
           l0.958-0.479l0.481-0.479l0.959-0.479l0.479-0.479l0.957-0.48l0.48-0.479l0.479-0.479l0.957-0.479l0.479-0.479l0.479-0.477
           l0.479-0.479l0.48-0.479l0.479-0.479l0.479-0.48l0.48-0.479l0.479-0.479l0.48-0.479l0.479-0.48l0.479-0.479l0.479-0.479
           l0.48-0.479l0.479-0.479l0.479-0.96l0.477-0.477l0.479-0.479l0.48-0.479l0.479-0.479l0.48-0.479l0.479-0.479l0.479-0.479
           l0.48-0.48l0.479-0.479l0.479-0.479l0.479-0.479l0.48-0.479l0.48-0.479l0.479-0.479l0.479-0.479l0.477-0.478l0.479-0.479
           l0.479-0.479l0.959-0.479l0.48-0.479l0.479-0.479l0.959-0.479l0.957-0.479l0.959-0.479l0.959-0.479l0.955-0.48l1.438-0.479
           l1.438-0.479l2.396-0.479h1.912l1.918,0.479l0.959,0.479l0.957,0.479l0.479,0.48l0.961,0.479l0.479,0.479l0.955,0.479l0.479,0.479
           l0.479,0.479l0.48,0.479l0.479,0.479l0.48,0.479l0.479,0.479l0.48,0.478l0.479,0.479l0.479,0.479l0.479,0.479l0.479,0.479
           l0.48,0.479l0.479,0.479l0.48,0.479l0.477,0.48l0.479,0.479l0.479,0.479l0.48,0.479l0.957,0.479l0.479,0.479l1.439,0.479
           l0.957,0.477l1.914,0.479l2.875,0.48l3.834,0.479l3.83,0.479l1.916,0.479l1.439,0.479l0.955,0.48l0.959,0.479l0.957,0.479
           l0.479,0.479l0.48,0.48l0.48,0.958l0.479,0.955l0.479,1.438v2.875l-0.479,0.958l-0.479,0.479l-0.48,0.479l-0.48,0.48l-0.957,0.475
           l-1.438,0.48l-1.436,0.479l-1.916,0.479v0.958l0.957,0.479l0.48,0.479l0.959,0.48l0.475,0.479l0.959,0.479l1.438,0.479
           l0.959,0.479l1.438,0.479h5.268l2.396-0.479l1.438-0.479l1.914-0.479l1.436-0.479l1.439-0.479l0.957-0.48l1.439-0.479l2.463-0.455
           l-0.549,0.934l-0.48,2.396l-0.475,1.435v0.958h0.955l0.479-0.479l0.957-0.479l0.959-0.477l0.479-0.479l0.479-0.479l0.48-0.479
           l0.48-0.479l0.479-0.479l0.479-0.96l0.48-1.437v-8.622l-0.48-1.435l-0.479-1.438l-0.479-0.479l-0.48-0.957l-0.48-0.48
           l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.957-0.48l-1.434-0.479l-2.877-0.477l-5.268-0.479l-5.75-0.479
           l-3.83-0.479l-2.875-0.479l-2.395-0.479l-1.916-0.479l-1.438-0.48l-1.438-0.479l-0.959-0.479l-0.955-0.479l-0.959-0.479
           l-0.959-0.479l-0.479-0.479l-0.957-0.479l-0.48-0.478l-0.479-0.479l-0.959-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.958
           l-0.477-0.479l-0.479-0.479l-0.479-0.959l-0.48-1.437v-2.393l0.48-1.917l0.479-0.959l0.479-0.958l0.477-0.479l0.48-0.48
           l0.479-0.479l0.479-0.476l0.48-0.479l0.479-0.479l0.479-0.479v-0.479h-1.916l-3.352,0.479l-3.354,0.479l-1.021,0.47l0.541-1.907
           v-1.438l-1.914-0.479h-7.184L272.84,150.692L272.84,150.692z M270.444,119.557l0.479-0.479h0.48l0.479,0.479l0.48,0.479
           l0.479,0.958l0.479,0.96l0.48,0.958l0.479,0.958l0.479,1.437l0.479,2.394v6.226v0.48l0.48,2.874l0.48,0.958l0.479,0.479
           l0.479,0.959l0.477,0.479l0.479,0.476l0.479,0.958l0.48,0.479l0.479,0.479l0.48,0.48l0.479,0.479l0.48,0.479l0.479,0.479
           l0.479,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.479l0.48,0.479l0.477,0.478l0.479,0.479v0.479h-3.83h-14.369
           l-4.311,0.479l-3.354,0.479l-2.398,0.479l-2.391,0.479l-1.918,0.479l-1.916,0.48l-1.439,0.479l-1.912,0.479l-1.438,0.479
           l-1.438,0.479l-0.959,0.479l-1.438,0.479l-1.436,0.476l-0.957,0.479l-0.959,0.48l-1.438,0.479l-0.959,0.479l-0.958,0.479
           l-1.434,0.479l-0.958,0.48l-0.96,0.479l-0.958,0.479l-0.958,0.479l-0.958,0.48l-0.958,0.479l-0.959,0.479l-0.955,0.479
           l-0.958,0.476l-0.958,0.479l-0.959,0.479l-0.958,0.479l-0.958,0.479l-0.958,0.48l-0.956,0.479l-0.958,0.479l-0.958,0.479
           l-0.958,0.479l-0.958,0.479l-0.959,0.479l-0.958,0.479l-0.958,0.479l-0.955,0.479l-0.959,0.478l-0.958,0.479l-0.958,0.479
           l-0.958,0.479l-1.438,0.479l-0.959,0.479l-0.955,0.479l-0.958,0.479l-0.958,0.48l-1.438,0.479l-0.958,0.479l-1.438,0.479
           l-0.955,0.479l-1.437,0.479l-1.439,0.479l-1.437,0.477l-1.438,0.479l-1.913,0.48l-2.396,0.479l-3.834,0.479l-7.663,0.479
           l-7.665,0.479l-7.664,0.48l-8.141,0.479h-2.396v-0.959l0.958-0.479l0.958-0.479l0.479-0.479l0.957-0.479l0.479-0.48l0.958-0.479
           l0.479-0.477l0.479-0.479l0.958-0.479l0.481-0.479l0.958-0.479l0.479-0.479l0.479-0.479l0.479-0.48l0.955-0.479l0.479-0.479
           l0.48-0.479l0.479-0.479l0.479-0.479l0.479-0.479l0.479-0.957l0.479-0.479l0.544-1.907l-2.94,0.471l-1.438,0.479l-1.914,0.479
           l-2.396,0.479h-11.975l-2.395-0.479l-2.396-0.479l-1.913-0.479l-1.918-0.479l-1.437-0.479l-1.438-0.479l-1.914-0.479l-1.438-0.479
           l-1.437-0.48l-1.438-0.479l-0.958-0.479l-1.435-0.479l-1.438-0.479l-1.438-0.476l-0.958-0.479l-1.437-0.479l-0.959-0.479
           l-1.434-0.48l-0.958-0.479l-1.438-0.479l-0.958-0.479l-0.958-0.48l-1.438-0.479l-0.957-0.479l-0.958-0.479l-0.958-0.479
           l-1.437-0.48l-0.48-0.479v-0.476h1.917l2.394,0.476l2.396,0.479l2.875,0.48l3.352,0.479l4.312,0.479l4.79,0.479l9.101,0.479h9.099
           l8.623-0.479l4.792-0.479l3.831-0.479l2.875-0.479l2.871-0.48l2.397-0.479l2.395-0.476l1.913-0.479l1.918-0.479l1.917-0.479
           l1.917-0.479l1.913-0.479l1.439-0.479l1.437-0.48l1.438-0.479l1.438-0.479l1.434-0.479l1.437-0.479l1.438-0.479l0.958-0.479
           l1.438-0.479l0.959-0.478l0.958-0.479l1.434-0.479l0.958-0.479l0.958-0.479l0.958-0.479l0.96-0.479l1.437-0.479l0.955-0.479
           l0.479-0.479l0.96-0.48l0.958-0.479l0.958-0.479l0.958-0.479l0.958-0.479l0.48-0.476l0.958-0.479l0.955-0.479l0.479-0.48
           l0.958-0.479l0.959-0.479l0.479-0.479l0.958-0.479l0.479-0.479l0.958-0.479l0.479-0.479l0.957-0.479l0.479-0.479l0.958-0.48
           l0.479-0.479l0.958-0.476l0.479-0.479l0.959-0.479l0.479-0.479l0.958-0.479l0.479-0.479l0.955-0.479l0.959-0.48l0.958-0.479
           l0.958-0.479l1.438-0.479l1.438-0.479l1.913-0.479l2.876-0.479h4.787v0.958l-0.479,0.479l-0.479,0.479l-0.479,0.479l-0.479,0.479
           l-0.479,0.48l-0.479,0.479l-0.48,0.479l-0.479,0.479l-0.479,0.479l-0.479,0.479l-0.48,0.479l-0.479,0.476l-0.481,0.479
           l-0.479,0.48l-0.479,0.479l-0.479,0.479l-0.479,0.479l-0.476,0.479l-0.479,0.479l-0.479,0.479l-0.479,0.479l-0.481,0.479
           l-0.479,0.479l-0.479,0.48l-0.958,0.479l-0.479,0.479l-0.479,0.476l-0.479,0.479l-0.479,0.479l-0.48,0.479l-0.955,0.479
           l-0.479,0.48l-0.479,0.479l-0.958,0.479l-0.479,0.479l-1.031,0.264l2.468-0.264l2.394-0.479l1.916-0.479l1.916-0.479l1.918-0.48
           l1.434-0.479l1.917-0.479l1.438-0.479l1.438-0.479l1.436-0.476l1.438-0.479l1.438-0.479l0.959-0.48l1.436-0.479l0.959-0.479
           l0.955-0.479l0.959-0.479l0.959-0.479l0.957-0.479l0.959-0.479l0.957-0.479l0.961-0.479l0.479-0.48l0.955-0.479l0.479-0.476
           l0.959-0.479l0.479-0.479l0.959-0.479l0.48-0.479l0.479-0.479l0.957-0.479l0.48-0.48l0.479-0.479l0.479-0.479l0.48-0.479
           l0.955-0.479l0.479-0.479l0.48-0.479l0.479-0.479l0.479-0.478l0.48-0.479l0.479-0.479l0.48-0.479l0.479-0.958l0.479-0.479
           l0.479-0.479l0.48-0.479l0.479-0.96l0.479-0.479L270.444,119.557L270.444,119.557z M191.89,99.439l8.144-0.479h9.101l12.933,0.479
           l7.665,0.481l5.746,0.479l1.439,0.479v0.479l-2.873,0.479l-3.354,0.479l-2.876,0.479l-2.871,0.479l-2.396,0.479l-1.917,0.477
           l-2.393,0.479l-1.917,0.479l-1.918,0.479l-1.437,0.479l-1.915,0.479l-1.438,0.48l-1.437,0.479l-1.438,0.479l-1.434,0.479
           l-0.96,0.48l-1.437,0.479l-1.438,0.479l-0.958,0.479l-0.958,0.479l-1.434,0.479l-0.958,0.476l-0.959,0.479l-0.958,0.48
           l-0.958,0.479l-0.959,0.479l-0.479,0.479l-0.958,0.479l-0.958,0.479l-0.476,0.479l-0.958,0.479l-0.479,0.479l-0.958,0.479
           l-0.48,0.481l-0.958,0.479l-0.479,0.479l-0.479,0.476l-0.96,0.479h0.481l1.916-0.479l1.438-0.476l1.438-0.479l1.434-0.479
           l1.438-0.481l1.917-0.479l1.438-0.479l1.438-0.479l1.913-0.479l1.438-0.479l1.917-0.479l1.437-0.479l1.913-0.479l1.439-0.479
           l1.916-0.48l1.918-0.479l1.913-0.476l1.918-0.479l1.916-0.479l1.917-0.479l2.393-0.479l2.876-0.479l2.871-0.48l3.833-0.479h9.102
           l2.873,0.479l1.916,0.48l1.916,0.479l1.438,0.479l1.436,0.479l1.438,0.479l1.438,0.479l0.957,0.476l0.961,0.479l1.434,0.48
           l0.957,0.479l0.959,0.479l0.959,0.479l0.959,0.479l0.957,0.479l0.48,0.479l0.479,0.479v0.479h-0.959h-11.977l-2.871,0.479
           l-1.918,0.481l-1.916,0.479l-1.914,0.479l-1.438,0.476l-0.957,0.479l-1.44,0.479l-0.958,0.479l-0.958,0.479l-0.955,0.481
           l-0.957,0.479l-0.96,0.479l-0.958,0.479l-0.958,0.479l-0.958,0.479l-0.959,0.479l-0.955,0.479l-0.958,0.479l-0.958,0.479
           l-0.958,0.478l-0.959,0.479l-0.958,0.479l-1.438,0.479l-0.956,0.479l-0.958,0.479l-0.958,0.479l-0.958,0.479l-0.958,0.48
           l-1.439,0.479l-0.958,0.479l-0.955,0.479l-0.958,0.479l-1.438,0.479l-0.958,0.479l-0.958,0.476l-1.438,0.479l-0.959,0.48
           l-1.434,0.479l-0.958,0.479l-0.96,0.479l-1.437,0.479l-0.958,0.479l-1.437,0.479l-1.434,0.479l-0.959,0.479l-1.438,0.479
           l-1.438,0.48l-0.958,0.479l-1.437,0.479l-1.434,0.476l-1.438,0.479l-1.438,0.479l-1.437,0.479l-1.439,0.479l-1.437,0.48
           l-1.435,0.479l-1.917,0.479l-1.438,0.479l-1.917,0.479l-1.914,0.479l-1.437,0.479l-2.396,0.479l-1.916,0.479l-2.394,0.479
           l-2.395,0.478l-2.873,0.479l-3.833,0.479h-18.2l-5.271-0.479l-3.352-0.479l-2.396-0.478l-2.396-0.479l-1.914-0.479l-1.438-0.479
           l-1.437-0.479l-0.958-0.479l-0.481-0.479v-0.479l0.481-0.479l0.958-0.479l0.479-0.48l0.479-0.479l0.479-0.479l0.479-0.958v-0.955
           l-0.479-0.479l-0.479-0.48l-0.479-0.479l-0.479-0.479l-0.958-0.479l-1.438-0.479l-0.955-0.479l-1.438-0.479l-0.959-0.479
           l-1.437-0.479l-1.438-0.479l-1.436-0.48l-1.437-0.479l-1.437-0.476l-1.438-0.479l-1.437-0.479l-1.434-0.479l-1.438-0.479
           l-1.438-0.479l-1.437-0.479l-1.438-0.48l-0.958-0.479v-3.831l16.766-0.479l6.707-0.479l4.313-0.479l2.871-0.479l2.875-0.479
           l2.394-0.479l1.916-0.479l1.438-0.479l1.917-0.481l1.438-0.479l1.434-0.479l0.959-0.479l1.438-0.479l1.437-0.476l0.958-0.479
           l0.959-0.479l1.434-0.481l0.958-0.479l0.96-0.479l1.437-0.479l0.958-0.479l0.958-0.479l0.957-0.479l0.958-0.479l1.438-0.479
           l0.958-0.479l0.96-0.48l0.958-0.479l0.958-0.476l0.955-0.479l0.958-0.479l0.959-0.479l0.958-0.479l0.958-0.479l1.437-0.48
           l0.96-0.479l0.955-0.479l0.958-0.479l0.958-0.48l1.439-0.479l0.958-0.479l0.958-0.479l1.437-0.479l0.955-0.479l0.959-0.477
           l1.438-0.479l1.438-0.479l1.437-0.479l1.435-0.479l1.437-0.479l1.439-0.479l1.916-0.479l2.396-0.479L191.89,99.439L191.89,99.439z
            M187.101,10.347l2.876-0.479h15.803l3.354,0.479l2.397,0.479l1.913,0.479l1.918,0.479l1.437,0.479l1.438,0.48l1.437,0.479
           l1.435,0.479l1.438,0.479l0.958,0.479l0.959,0.479l1.437,0.479l0.958,0.479l0.955,0.478l0.959,0.479l0.958,0.479l0.958,0.479
           l0.958,0.479l0.96,0.479l0.957,0.479l0.479,0.479l0.955,0.479l0.958,0.479l0.479,0.48l0.959,0.479l0.959,0.479l0.479,0.479
           l0.959,0.479l0.479,0.476l0.957,0.479l0.957,0.479l0.479,0.48l0.959,0.479l0.479,0.479l0.48,0.479l0.959,0.479l0.479,0.479
           l0.957,0.479l0.48,0.479l0.957,0.479l0.477,0.479l0.479,0.481l0.959,0.479l0.48,0.476l0.479,0.479l0.959,0.479l0.479,0.479
           l0.479,0.479l0.959,0.479l0.48,0.48l0.479,0.479l0.955,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.959,0.479l0.479,0.479
           l0.48,0.479l0.957,0.478l0.479,0.479l0.48,0.479l0.479,0.479l0.959,0.479l0.477,0.479l0.479,0.479l0.959,0.479l0.479,0.479
           l0.479,0.479l0.959,0.48l0.48,0.479l0.957,0.479l0.959,0.479l0.957,0.479l0.477,0.476l0.959,0.479l1.438,0.479l0.959,0.48
           l0.957,0.479l1.439,0.479l1.434,0.479l1.916,0.479l1.439,0.479l1.914,0.479l2.395,0.479l2.395,0.479l2.877,0.479l2.393,0.48
           l2.396,0.479l1.916,0.476l0.959,0.479l1.434,0.479l0.959,0.479l0.479,0.479l0.959,0.479l0.479,0.48l0.959,0.479l0.479,0.479
           l0.479,0.479l0.48,0.479l0.479,0.479l0.955,0.479l0.48,0.958l0.479,0.477l0.48,0.479l0.479,0.479l0.479,0.48l0.479,0.479
           l0.48,0.958l0.48,0.479l0.479,0.479l0.479,0.959l0.479,0.479l0.48,0.479l0.479,0.958l0.479,0.476l0.477,0.958l0.479,0.479
           l0.48,0.479v0.958h-0.959l-0.955-0.479l-1.438-0.479l-0.957-0.479l-0.48-0.479l-0.959-0.479l-0.957-0.479l-0.959-0.476
           l-0.955-0.479l-0.959-0.479l-0.959-0.479l-1.438-0.479l-0.957-0.479l-0.959-0.48l-0.959-0.479l-0.955-0.479l-1.438-0.479
           l-0.959-0.479l-1.438-0.479l-1.438-0.48l-1.914-0.479l-2.395-0.479h-0.959v2.875l0.48,0.958l0.479,0.48l0.479,0.958l0.479,0.479
           l0.48,0.479l0.479,0.479l0.479,0.476v0.958h-0.957l-1.438-0.479l-0.959-0.479l-0.959-0.476l-0.957-0.479l-1.438-0.479
           l-0.957-0.479l-1.438-0.479l-0.957-0.479l-1.438-0.48l-1.918-0.479l-1.912-0.479l-2.877-0.479l-0.957-0.479l-0.48-0.479
           l-0.479-0.48l-0.959-0.479l-0.477-0.479l-0.957-0.477l-0.959-0.479l-0.479-0.479l-0.959-0.479l-0.959-0.479l-0.957-0.479
           l-0.959-0.479l-1.436-0.479l-1.438-0.479l-1.436-0.48l-2.398-0.479l-3.352-0.479h-5.268l-3.354,0.479l-2.396,0.479l-1.914,0.48
           l-1.438,0.479l-1.436,0.479l-1.44,0.479l-0.958,0.479l-1.434,0.479h-0.958l-0.479-0.479l-0.481-0.479l-0.479-0.479l-0.479-0.479
           l-0.479-0.479l-0.479-0.48l-0.958-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.479l-0.476-0.476l-0.479-0.479
           l-0.958-0.48l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.959-0.479l-0.479-0.479l-0.479-0.479l-0.958-0.479l-0.479-0.479
           l-0.956-0.479l-0.479-0.48l-0.958-0.479l-0.479-0.479l-0.958-0.476l-0.958-0.479l-0.479-0.479l-0.96-0.479l-0.958-0.479
           l-0.955-0.48l-0.958-0.479l-0.96-0.479l-0.958-0.479l-0.958-0.479l-0.958-0.479l-1.439-0.479l-0.953-0.479l-1.438-0.479
           l-1.439-0.479l-1.437-0.478l-1.438-0.479l-1.913-0.479l-1.918-0.479l-2.396-0.479l-2.872-0.479l-5.271-0.479h-6.228l-4.789,0.479
           l-3.353,0.479l-1.918,0.479l-2.392,0.479l-1.438,0.479l-1.916,0.479l-1.439,0.478l-2.392,0.479l-1.917,0.479l-2.395,0.479
           l-2.394,0.479l-2.874,0.479l-3.355,0.479l-4.789,0.479h-18.68l-4.792-0.479l-3.352-0.479l-2.874-0.479l-2.872-0.479l-1.917-0.479
           l-2.397-0.479l-1.437-0.479l-1.914-0.478l-1.438-0.479l-1.916-0.479l-1.438-0.479l-1.434-0.479l-0.958-0.479l-1.438-0.479
           l-0.958-0.479l-1.437-0.479l-0.958-0.48V32.38h0.958l2.395,0.479l2.875,0.479l3.831,0.48l5.268,0.479h12.933l5.271-0.479
           l3.351-0.48l2.876-0.479l1.917-0.479l2.393-0.479l1.438-0.479l1.917-0.479l1.438-0.476l1.434-0.479l0.959-0.481l1.438-0.479
           l0.958-0.479l1.437-0.479l0.959-0.479l0.955-0.479l0.958-0.479l0.958-0.479l0.96-0.479l0.479-0.479v-0.48l-0.479-0.479
           l-0.96-0.479l-0.958-0.476l-1.434-0.479l-0.959-0.479l-1.438-0.479l-1.437-0.479l-1.438-0.48l-1.914-0.479l-1.438-0.479
           l-1.917-0.479l-1.438-0.479l-1.916-0.479l-1.436-0.479v-0.479h2.873h18.682l4.31-0.479l3.354-0.479l2.872-0.478l2.875-0.479
           l2.394-0.479l1.916-0.479l1.918-0.479l1.916-0.479l1.914-0.479l1.438-0.479l1.916-0.48l1.438-0.479l1.914-0.479l1.916-0.479
           L187.101,10.347L187.101,10.347z M302.059,158.357l0.08-0.561l0.398,0.561H302.059L302.059,158.357z M335.59,136.802l0.48,0.479
           l0.479,0.479l0.475,0.479l0.48,0.479l0.957,0.48l0.48,0.479l0.479,0.479l0.479,0.476l0.48,0.479l0.479,0.479l0.479,0.479
           l0.48,0.479l0.479,0.48l0.48,0.479l0.479,0.479l0.479,0.479l0.477,0.479l0.479,0.479l0.479,0.479l0.479,0.479l0.48,0.479
           l0.48,0.479l0.479,0.478l0.479,0.479l0.48,0.479c0.275,0.893-1.758,1.102-2.398-0.479l-0.479-0.479l-0.479-0.478l-0.479-0.959
           l-0.477-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.958l-0.479-0.479l-0.48-0.479l-0.479-0.48l-0.479-0.479l-0.48-0.479
           l-0.479-0.479l-0.479-0.479l-0.48-0.476l-0.479-0.479l-0.479-0.479l-0.48-0.48l-0.475-0.479l-0.479-0.479l-0.48-0.479
           l-0.479-0.479l-0.48-0.479L335.59,136.802L335.59,136.802z M334.631,136.323l-0.479-0.479L334.631,136.323L334.631,136.323z
            M334.153,135.844l-0.479-0.479L334.153,135.844L334.153,135.844z M333.674,135.365l-0.961-0.479L333.674,135.365L333.674,135.365
           z M332.713,134.886l-0.479-0.479L332.713,134.886L332.713,134.886z M332.235,134.407l-0.479-0.48L332.235,134.407L332.235,134.407
           z M331.756,133.927l-0.561-0.078l0.561-0.401V133.927L331.756,133.927z M329.84,132.493
           C330.694,131.639,328.989,133.343,329.84,132.493L329.84,132.493z M336.071,130.097l-0.959-0.48l0.479,0.48l0.48,0.479
           l0.479,0.479l0.475,0.479l0.48,0.479l0.479,0.479l0.479,0.479l0.48,0.476l0.479,0.479l0.479,0.48l0.48,0.479l0.479,0.479
           l0.479,0.479l0.48,0.479l0.479,0.479l0.48,0.479l0.479,0.958l0.479,0.479l0.477,0.48l0.479,0.479l0.479,0.479l0.479,0.476
           l0.48,0.479l0.48,0.479l0.479,0.958l0.479,0.48l0.48,0.479l0.479,0.479l0.479,0.479l0.479,0.958l0.48,0.479l0.48,0.479
           l0.479,0.479h1.912l-0.479-0.479l-0.479-0.479l-0.479-0.958l-0.477-0.479l-0.479-0.479l-0.48-0.479l-0.48-0.479l-0.479-0.48
           l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.479l-0.479-0.476l-0.48-0.479l-0.48-0.479l-0.479-0.48l-0.479-0.479
           l-0.479-0.479l-0.477-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.479l-0.48-0.479l-0.957-0.479l-0.48-0.48
           l-0.479-0.479l-0.479-0.476l-0.48-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.475-0.479L336.071,130.097L336.071,130.097z
            M335.112,129.617l-0.48-0.479L335.112,129.617L335.112,129.617z M334.631,129.138l-0.479-0.479L334.631,129.138L334.631,129.138z
            M334.153,128.658l-0.479-0.479L334.153,128.658L334.153,128.658z M333.674,128.179l-0.48-0.479L333.674,128.179L333.674,128.179z
            M333.194,127.701l-0.959-0.479L333.194,127.701L333.194,127.701z M332.235,127.222l-0.479-0.479L332.235,127.222L332.235,127.222
           z M331.756,126.743l-0.479-0.479L331.756,126.743L331.756,126.743z M331.278,126.264l-0.561-0.079l0.561-0.398V126.264
           L331.278,126.264z M328.881,124.349C329.735,123.496,328.032,125.2,328.881,124.349L328.881,124.349z M337.024,123.391
           l-0.953-0.479l0.479,0.479l0.475,0.479l0.48,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.478l0.479,0.479l0.48,0.479
           l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.479l0.48,0.479l0.479,0.48l0.479,0.479l0.477,0.479l0.479,0.479l0.479,0.479
           l0.479,0.479l0.48,0.479l0.48,0.476l0.479,0.959l0.479,0.479l0.48,0.479l0.479,0.479l0.479,0.479l0.479,0.479l0.48,0.479
           l0.48,0.479l0.479,0.479l0.477,0.959l0.479,0.479l0.479,0.479l0.479,0.476l0.48,0.479l0.479,0.479l0.479,0.479l0.48,0.96
           l0.48,0.479l0.479,0.479l0.479,0.479l0.479,0.479l0.48,0.958h1.436l0.479-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479
           l-0.479-0.479l-0.48-0.479l-0.479-0.48l-0.479-0.958l-0.479-0.479l-0.48-0.479l-0.48-0.476l-0.479-0.479l-0.479-0.479l-0.48-0.48
           l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.477-0.479l-0.479-0.479l-0.48-0.479l-0.48-0.479L349,134.886l-0.479-0.479
           l-0.479-0.48l-0.48-0.479l-0.479-0.476l-0.479-0.479l-0.48-0.479l-0.48-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.477-0.48
           l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.959-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.478l-0.479-0.479
           l-0.48-0.479l-0.479-0.479l-0.479-0.479L337.024,123.391L337.024,123.391z M336.071,122.912l-0.48-0.479L336.071,122.912
           L336.071,122.912z M335.59,122.433l-0.479-0.479L335.59,122.433L335.59,122.433z M335.112,121.954l-0.48-0.479L335.112,121.954
           L335.112,121.954z M334.631,121.475l-0.479-0.481L334.631,121.475L334.631,121.475z M334.153,120.994l-0.479-0.479
           L334.153,120.994L334.153,120.994z M333.674,120.515l-0.561-0.077l0.561-0.402V120.515L333.674,120.515z M331.756,119.078
           l0.561,0.081l-0.561,0.398V119.078z M331.756,119.078v-0.476L331.196,119L331.756,119.078L331.756,119.078z M319.784,87.467
           l-0.563-0.081l0.563-0.4V87.467L319.784,87.467z M273.799,68.305v-0.958h1.436l2.395,0.479l0.957,0.479l1.439,0.479l0.959,0.479
           l0.957,0.479l0.479,0.481l0.959,0.479l0.959,0.479l0.955,0.479l0.479,0.479l0.959,0.48l0.479,0.479l0.959,0.479l0.959,0.479
           l0.479,0.479l0.959,0.479l0.479,0.477l0.955,0.479l0.48,0.479l0.479,0.479l0.959,0.479l0.479,0.479l0.959,0.479l0.479,0.48
           l0.957,0.479l0.48,0.479l0.959,0.479l0.479,0.479l0.955,0.479l0.479,0.48l0.959,0.479l0.959,0.476l0.934,0.551l-0.455-1.986
           l-0.479-1.437l-0.479-0.958l-0.48-0.959l-0.479-1.438l-0.48-0.958l-0.479-0.955l-0.479-0.958l-0.477-0.958l-0.479-0.48
           l-0.48-0.958l-0.479-0.958l-0.48-0.96v-1.437h0.48l1.914,0.479l1.438,0.479l1.438,0.479l1.916,0.479l1.438,0.481l0.955,0.479
           l1.439,0.479l1.436,0.479l0.961,0.479l1.436,0.48l0.959,0.479l0.955,0.479l0.959,0.479l1.438,0.479l0.957,0.479l0.48,0.477
           l0.957,0.479l0.959,0.479l0.959,0.479l0.477,0.479l0.957,0.479l0.48,0.479l0.957,0.48l0.479,0.479l0.479,0.479l0.961,0.479
           l0.479,0.479l0.479,0.479l0.48,0.48l0.479,0.479l0.48,0.476l0.479,0.479l0.48,0.479l0.475,0.479l0.48,0.479l0.479,0.958
           l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.48l0.479,0.479l0.479,0.48l0.48,0.958l0.48,0.476l0.479,0.479l0.479,0.479l0.48,0.48
           l0.479,0.479l0.48,0.479l0.479,0.479l0.475,0.479l0.48,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.479l0.479,0.481
           l0.48,0.479l0.957,0.477l0.48,0.479l0.479,0.479l0.48,0.479l0.957,0.479l0.477,0.479l0.479,0.481l0.957,0.479l0.48,0.479
           l0.959,0.479l0.479,0.479l0.959,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.959,0.477l0.477,0.479l0.479,0.958l0.479,0.479
           l0.479,1.437v1.438l-0.479,0.958l-0.479,0.479l-0.479,0.479l-0.477,0.479h-5.273l-1.436-0.479l-0.477-0.479l-0.957-0.479
           l-0.48-0.479l-0.479-0.479l-0.48-0.48l-0.479-0.479l-0.479-0.479l-0.48-0.958l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.958
           l-0.479-0.477l-0.48-0.479l-0.475-0.958l-0.479-0.479l-0.48-0.479l-0.479-0.958l-0.48-0.479l-0.479-0.481l-0.479-0.479
           l-0.48-0.958l-0.48-0.479l-0.479-0.479l-0.957-0.477l-0.48-0.479l-0.479-0.481l-0.479-0.479l-0.479-0.479l-0.48-0.479
           l-0.475-0.479l-0.959-0.479l-0.48-0.479l-0.479-0.479l-0.48-0.479l-0.957-0.479l-0.479-0.48l-0.482-0.479l-0.957-0.479
           l-0.479-0.476l-0.479-0.479l-0.959-0.479l-0.955-0.48l-0.479-0.479l0.479,0.958l0.477,1.434l0.479,1.438l0.479,1.917l0.48,2.876
           v3.831h-1.438l-0.955-0.481l-1.439-0.479l-0.957-0.479l-1.438-0.479l-0.959-0.479l-1.438-0.479l-1.434-0.477l-1.438-0.479
           l-0.959-0.481l-1.438-0.479l-1.438-0.479l-1.914-0.479l-1.916-0.479l-1.918-0.479l-3.83-0.479h-4.791l-1.029,0.263l2.467,0.216
           l2.395,0.479l1.918,0.479l2.393,0.479l1.916,0.479l1.438,0.479l1.918,0.481l1.434,0.479l1.916,0.477l1.439,0.479l1.438,0.479
           l0.957,0.479l1.436,0.479l1.438,0.479l0.957,0.481l0.959,0.479l1.438,0.479l0.959,0.479l0.477,0.479l0.957,0.479l0.48,0.479
           l0.479,0.479l0.479,0.479l0.479,0.477l0.479,0.479l0.482,0.479l0.479,0.479l0.479,0.479l0.479,0.958l0.48,0.479l0.479,0.958
           l0.48,0.48l0.479,0.479l0.48,0.479l0.955,0.479h0.957l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.475-0.48l-0.48-0.958
           l-0.479-0.479l-0.48-0.958l-0.479-1.437l-0.48-1.436l-0.479-2.395l-0.479-3.833l-0.479-1.915v-1.916h0.479l0.479,0.479
           l0.479,0.958l0.48,0.479l0.479,0.479l0.48,0.481l0.479,0.479l0.48,0.477l0.475,0.479l0.48,0.479l0.479,0.479l0.479,0.479
           l0.959,0.479l0.479,0.481v0.958l-2.395,0.479l-0.48,0.479v2.873l0.48,1.437l0.479,0.958l0.479,0.479l0.479,0.479l0.48,0.479
           l0.957,0.48l0.959,0.479l1.438,0.479h1.439l-0.48-0.479l-1.438-0.479l-1.439-0.48l-0.479-0.479l-0.479-0.479l-0.479-0.958v-1.916
           l0.479-0.479l0.479-0.477h1.439l1.438,0.477l0.479,0.479l0.48,0.479l0.479,0.479l0.475,0.479l0.48,0.479l0.479,0.958l0.479,0.479
           l0.48,0.479l0.479,0.48l0.479,0.958l0.48,0.479l0.957,0.479l0.48,0.479l0.479,0.476l0.959,0.479l0.955,0.48l1.436,0.479
           l1.439,0.479L349,114.29l0.959,0.479l1.436,0.479l0.957,0.479l0.959,0.479l0.479,0.479l0.959,0.479l0.48,0.481l0.479,0.479
           l0.479,0.479l0.479,0.476l0.48,0.479l0.479,0.479l0.479,0.479l0.479,0.479l0.479,0.481l0.479,0.479l0.479,0.479l0.48,0.479
           l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.478l0.48,0.479l0.479,0.479l0.479,0.479
           l0.477,0.479l0.479,0.479l0.479,0.479l0.479,0.479l0.48,0.48l0.48,0.479l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.479
           l0.479,0.479l0.479,0.476l0.479,0.479l0.482,0.48l0.479,0.479l0.477,0.479l0.479,0.479l0.479,0.958l0.479,0.479l0.48,0.479
           l0.479,0.479l0.479,0.479l0.48,0.48l0.48,0.479l0.479,0.479l0.479,0.476l0.479,0.479l0.48,0.479v0.958h-1.438l-0.959-0.479
           l-0.959-0.479l-1.438-0.479l-1.434-0.479l-1.439-0.476h-2.395v0.955l0.479,0.479l0.48,0.958l0.479,0.48l0.479,0.958l0.479,1.438
           l0.479,2.873v6.704l-0.479,1.918l-0.479,0.957l-0.479,0.48l-0.959,0.479l-0.479,0.479l-1.439,0.479l-1.436,0.48l-1.914,0.479
           l-1.438,0.479l-1.438,0.479l-0.959,0.476l-0.479,0.479l-0.957,0.479l-0.479,0.479l-0.479,0.479l-0.959,0.48l-0.479,0.479
           l-0.957,0.479l-0.48,0.479l-0.959,0.479l-0.959,0.479H349l-0.957-0.479l-0.959-0.479l-0.479-0.479l-0.961-0.479l-0.479-0.479
           l-0.479-0.48l-0.955-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.476l-0.48-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.48
           l-0.479-0.479l-0.479-0.957l-0.48-0.48l-0.479-0.479l-0.479-0.958l-0.48-0.479l-0.475-0.96l-0.479-0.955l-0.48-1.438l-0.479-1.917
           l-0.48-0.479l-1.918-0.479h-2.395l-2.393,0.479l-1.918,0.479l-0.957,0.479l-1.439,0.48l-0.957,0.479l-0.959,0.479l-0.479,0.479
           l-0.955,0.479l-0.479,0.479l-0.48,0.479l-0.479,0.476l-0.48,0.479l-0.479,0.48l-0.479,0.479l-0.48,0.958l-0.479,1.438
           l-0.479,1.438v3.351l0.479,0.479l0.959,0.48l2.396,0.479l14.848,0.479l3.352,0.479l1.916,0.479l1.918,0.479l0.959,0.479
           l1.434,0.479l0.957,0.479l0.959,0.479l0.48,0.478l0.479,0.479l0.479,0.479l0.48,0.479l0.479,0.958v1.918l-0.479,0.958l-0.48,0.958
           l-0.479,0.479l-0.479,0.956l-0.48,0.48l-0.48,0.479l-0.479,0.479l-0.479,0.479l-0.479,0.479l-0.477,0.959l-0.479,0.479
           l-0.479,0.96l-0.48,0.958l-0.479,1.435l-0.48,0.958l-0.479,1.438l-0.479,0.959h-0.48l-0.479-0.479l-0.479-2.396l-0.48-3.831
           l-0.479-1.438l-0.479-0.957l-0.48-0.479l-0.475-0.479l-0.479-0.48l-0.48-0.479l-0.479-0.477l-0.959-0.479l-0.479-0.479
           l-0.961-0.479l-1.436-0.479l-1.916-0.479l-8.145-0.479l-4.789-0.48l-3.352-0.479l-2.395-0.479l-2.396-0.479l-1.918-0.479
           l-1.434-0.479l-1.438-0.479l-0.959-0.479l-0.957-0.478l-0.48-0.479l-0.959-0.479l-0.479-0.958v-2.396l0.479-1.917l0.48-0.955
           l0.479-0.959l0.48-0.959l0.479-1.437l-1.918,0.479h-2.393v-0.959l0.48-0.957l0.479-0.479l0.955-0.479l0.479-0.48l0.48-0.479
           l0.479-0.476l0.48-0.479l0.957-0.479l0.48-0.479l0.479-0.479l0.479-0.479l-0.479-0.479l-1.438-0.48l-1.438-0.479l-1.438-0.479
           l-1.436-0.479l-0.959-0.479l-1.436-0.479l-0.959-0.479l-0.959-0.479l-0.957-0.478l-0.957-0.479l-0.479-0.479l-0.959-0.479
           l-0.479-0.479l-0.479-0.479l-0.479-0.479l-0.48-0.479l-0.48-0.479l-0.479-0.479l-0.479-0.96l-0.479-1.437l-0.48-2.394v-10.539
           v-2.394l-0.479-2.874l-0.479-0.958l-0.477-0.96l-0.48-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.476l-0.479-0.479l-0.479-0.479
           l-0.479-0.481l-0.48-0.479l-0.959-0.479l-0.479-0.479l-0.959-0.479l-0.479-0.479l-0.955-0.479l-0.479-0.479l-0.961-0.479
           l-0.957-0.479l-0.959-0.48l-0.479-0.479l-0.959-0.476l-0.959-0.479l-0.479-0.479V109.5h0.959l1.916,0.479l1.438,0.479l0.959,0.479
           l1.438,0.479l1.434,0.476l1.439,0.479l0.959,0.48l1.436,0.479l0.959,0.479l1.436,0.479l1.438,0.479l0.957,0.479l1.439,0.479
           l0.957,0.479l1.438,0.479l1.436,0.479l0.957,0.481l1.438,0.479l0.959,0.479l1.438,0.476l1.438,0.479l0.955,0.479l1.438,0.479
           l1.438,0.479l1.438,0.481l1.438,0.479l0.955,0.479l1.439,0.479l1.916,0.479l1.424,0.545l-0.465-1.023l-0.479-0.479l-0.48-0.958
           l-0.48-0.96l-0.479-0.479l-0.479-0.958l-0.479-0.476l-0.48-0.958l-0.479-0.481l-0.48-0.479l-0.475-0.479l-0.48-0.958l-0.479-0.479
           l-0.48-0.479l-0.479-0.479l-0.479-0.479l-0.479-0.959l-0.48-0.479l-0.479-0.476l-0.479-0.479l-0.48-0.479l-0.479-0.479
           l-0.48-0.479l-0.479-0.479l-0.479-0.48l-0.477-0.479l-0.479-0.479l-0.48-0.479l-0.479-0.48l-0.959-0.479l-0.479-0.479
           l-0.479-0.479l-0.48-0.479l-0.479-0.479l-0.479-0.477l-0.48-0.479l-0.957-0.479l-0.48-0.479l-0.477-0.479l-0.479-0.479
           l-0.959-0.479l-0.479-0.479l-0.479-0.479l-0.959-0.481l-0.48-0.479l-0.479-0.479l-0.957-0.479l-0.48-0.479l-0.479-0.479
           l-0.955-0.477l-0.48-0.479l-0.479-0.481l-0.959-0.479l-0.479-0.479l-0.957-0.479l-0.48-0.479l-0.959-0.479l-0.479-0.479
           l-0.959-0.479l-0.479-0.479l-0.955-0.479l-0.479-0.48l-0.961-0.479l-0.957-0.479l-0.479-0.476l-0.959-0.479l-0.479-0.479
           l-0.48-0.48l-0.479-0.479v-0.48h3.354l1.918,0.48l2.867,0.539l0.004-2.456v-4.789l-0.479-2.875l-0.48-1.917l-0.479-1.434
           l-0.479-0.958l-0.477-0.958l-0.479-0.48l-0.479-0.958l-0.48-0.479l-0.48-0.479l-0.479-0.481l-0.479-0.479L273.799,68.305z"
          />
        </g>
        <g>
          <path
            d="M0,267.283h8.342v-14.68h18.092v-7.583H8.342v-8.071h20.529v-7.583H0V267.283L0,267.283z M35.978,267.283h28.871v-7.421
           H44.266v-7.963h17.875v-7.421H44.266v-7.691h20.313v-7.421h-28.6V267.283L35.978,267.283z M72.715,267.283h14.788
           c2.393-0.009,4.613-0.325,6.672-0.948c2.058-0.623,3.918-1.508,5.57-2.654c1.652-1.146,3.069-2.5,4.252-4.071
           c1.174-1.571,2.077-3.313,2.709-5.218c0.623-1.905,0.938-3.928,0.948-6.066v-0.108c-0.009-2.682-0.506-5.164-1.472-7.448
           s-2.347-4.279-4.135-5.977c-1.788-1.706-3.909-3.033-6.365-3.981c-2.464-0.948-5.191-1.427-8.179-1.444H72.715V267.283
           L72.715,267.283z M81.057,259.754v-22.858h6.446c1.715,0.009,3.268,0.289,4.667,0.84s2.6,1.336,3.611,2.355
           c1.002,1.012,1.778,2.222,2.329,3.611c0.542,1.399,0.813,2.935,0.822,4.623v0.107c-0.009,1.688-0.28,3.224-0.822,4.604
           c-0.551,1.381-1.327,2.573-2.329,3.575c-1.011,1.002-2.212,1.778-3.611,2.32c-1.399,0.541-2.952,0.813-4.667,0.821H81.057
           L81.057,259.754z M115.519,267.283h28.871v-7.421h-20.583v-7.963h17.875v-7.421h-17.875v-7.691h20.313v-7.421h-28.6V267.283
           L115.519,267.283z M152.255,267.283h8.342v-12.134h6.555l8.125,12.134h9.75l-9.262-13.542c1.616-0.596,3.033-1.426,4.252-2.482
           c1.21-1.057,2.158-2.356,2.835-3.9c0.686-1.544,1.029-3.331,1.038-5.371v-0.108c0-1.797-0.28-3.422-0.831-4.884
           c-0.551-1.463-1.363-2.727-2.419-3.783c-1.246-1.255-2.799-2.202-4.649-2.861c-1.851-0.65-3.99-0.984-6.4-0.984h-17.334V267.283
           L152.255,267.283z M160.597,247.783v-10.888h8.288c1.363,0,2.528,0.198,3.503,0.604c0.984,0.406,1.733,1.012,2.257,1.814
           c0.524,0.804,0.794,1.806,0.794,2.998v0.108c0,1.074-0.252,2.013-0.749,2.808c-0.506,0.803-1.228,1.426-2.176,1.877
           c-0.957,0.442-2.104,0.669-3.467,0.678H160.597L160.597,247.783z M188.612,267.283h8.504l3.467-8.504h16.033l3.467,8.504h8.721
           l-16.25-38.188h-7.691L188.612,267.283L188.612,267.283z M203.563,251.412l5.038-12.296l5.038,12.296H203.563L203.563,251.412z
            M239.053,267.283v-30.226h-11.538v-7.691h31.417v7.691h-11.537v30.226H239.053L239.053,267.283z M265.768,267.283h28.873v-7.421
           h-20.584v-7.963h17.875v-7.421h-17.875v-7.691h20.313v-7.421h-28.602V267.283L265.768,267.283z M337.172,267.934
           c2.313-0.01,4.461-0.353,6.445-1.038c1.986-0.678,3.775-1.635,5.363-2.854c1.59-1.228,2.953-2.654,4.08-4.297
           c1.129-1.644,1.996-3.431,2.592-5.363c0.605-1.94,0.902-3.954,0.912-6.057v-0.108c-0.01-2.104-0.307-4.117-0.895-6.04
           c-0.596-1.932-1.453-3.71-2.572-5.345c-1.119-1.625-2.465-3.052-4.045-4.261c-1.58-1.219-3.357-2.158-5.336-2.835
           c-1.977-0.668-4.125-1.011-6.436-1.02c-2.313,0.009-4.461,0.352-6.447,1.038c-1.984,0.677-3.773,1.625-5.361,2.853
           c-1.59,1.228-2.953,2.654-4.08,4.297c-1.129,1.644-1.996,3.431-2.592,5.363c-0.605,1.94-0.902,3.953-0.912,6.058v0.107
           c0.01,2.104,0.307,4.117,0.902,6.04c0.588,1.932,1.445,3.71,2.564,5.345c1.119,1.625,2.465,3.051,4.045,4.261
           c1.58,1.219,3.357,2.158,5.336,2.826C332.713,267.581,334.862,267.924,337.172,267.934L337.172,267.934z M337.282,263.979
           c-2.176-0.019-4.172-0.425-5.994-1.237c-1.816-0.804-3.395-1.932-4.732-3.358c-1.336-1.436-2.373-3.097-3.105-4.983
           c-0.74-1.878-1.109-3.909-1.119-6.075v-0.108c0.01-2.167,0.379-4.189,1.102-6.067c0.723-1.868,1.742-3.521,3.07-4.938
           c1.326-1.418,2.889-2.528,4.693-3.322c1.807-0.804,3.801-1.21,5.977-1.219c2.176,0.009,4.172,0.424,5.994,1.236
           c1.814,0.804,3.395,1.923,4.73,3.358c1.338,1.436,2.375,3.097,3.105,4.975c0.742,1.887,1.111,3.918,1.121,6.085v0.107
           c-0.01,2.167-0.379,4.189-1.102,6.067c-0.723,1.868-1.742,3.521-3.07,4.938c-1.318,1.418-2.889,2.528-4.693,3.322
           C341.452,263.563,339.458,263.97,337.282,263.979L337.282,263.979z M366.055,267.283h4.172v-31.092l24.482,31.092h3.412v-37.917
           h-4.17v30.388l-23.889-30.388h-4.008V267.283L366.055,267.283z M409.292,267.283h27.68v-3.9h-23.4v-13.271h20.691v-3.9h-20.691
           v-12.945H436.7v-3.9h-27.408V267.283z"
          />
          <path
            d="M452.661,238.669c1.477-0.01,2.848-0.29,4.123-0.84c1.275-0.541,2.385-1.304,3.342-2.261
           c0.955-0.966,1.699-2.076,2.23-3.341c0.541-1.266,0.811-2.607,0.82-4.047v-0.058c-0.01-1.438-0.279-2.781-0.811-4.036
           c-0.521-1.256-1.266-2.356-2.211-3.322c-0.947-0.956-2.057-1.7-3.322-2.241c-1.266-0.54-2.637-0.82-4.115-0.83
           c-1.477,0.01-2.857,0.29-4.123,0.84c-1.273,0.541-2.385,1.304-3.342,2.26c-0.955,0.966-1.699,2.077-2.23,3.342
           c-0.541,1.265-0.811,2.607-0.82,4.046v0.059c0.01,1.438,0.279,2.781,0.811,4.036c0.521,1.256,1.266,2.366,2.213,3.322
           c0.945,0.956,2.057,1.7,3.32,2.24C449.811,238.379,451.182,238.659,452.661,238.669L452.661,238.669z M452.661,237.51
           c-1.324-0.01-2.549-0.251-3.67-0.724c-1.121-0.483-2.096-1.14-2.926-1.989c-0.84-0.851-1.488-1.826-1.941-2.946
           c-0.463-1.12-0.695-2.317-0.705-3.611v-0.059c0.01-1.294,0.242-2.501,0.705-3.621s1.119-2.115,1.961-2.965
           c0.83-0.859,1.814-1.525,2.945-2.009c1.129-0.482,2.355-0.724,3.688-0.734c1.324,0.011,2.551,0.252,3.67,0.725
           c1.121,0.483,2.096,1.14,2.928,1.99c0.83,0.85,1.477,1.825,1.939,2.945c0.465,1.12,0.695,2.317,0.705,3.611v0.058
           c-0.01,1.295-0.24,2.502-0.705,3.622c-0.463,1.12-1.119,2.114-1.959,2.965c-0.832,0.859-1.816,1.525-2.945,2.009
           C455.219,237.259,453.993,237.5,452.661,237.51L452.661,237.51z M452.805,233.802c-1.102-0.01-2.076-0.271-2.936-0.772
           c-0.85-0.503-1.525-1.179-2.02-2.038c-0.492-0.859-0.742-1.825-0.752-2.897v-0.058c0.01-1.063,0.26-2.028,0.744-2.897
           c0.482-0.869,1.158-1.555,2.027-2.076c0.869-0.512,1.873-0.782,3.023-0.792c1.041,0.01,1.91,0.164,2.605,0.474
           c0.688,0.309,1.285,0.725,1.797,1.236l-1.594,1.854c-0.414-0.386-0.859-0.686-1.322-0.907c-0.453-0.223-0.957-0.338-1.486-0.338
           c-0.629,0.009-1.18,0.164-1.652,0.463c-0.473,0.31-0.85,0.715-1.121,1.236c-0.27,0.512-0.414,1.082-0.414,1.719v0.059
           c0,0.627,0.135,1.197,0.406,1.709c0.27,0.512,0.637,0.928,1.119,1.236c0.473,0.309,1.023,0.464,1.662,0.473
           c0.607,0,1.139-0.125,1.572-0.356c0.445-0.232,0.869-0.551,1.295-0.947l1.623,1.623c-0.561,0.628-1.207,1.12-1.922,1.468
           S453.858,233.792,452.805,233.802z"
          />
        </g>
      </g>
    </svg>
  );
}
