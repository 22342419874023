import React from "react";

export default function LinkedinIcon() {
  return (
    <svg viewBox="0 0 47 47" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M28.6626 17.5544C27.6668 17.5544 26.9295 17.8056 26.3523 18.1228C26.2598 17.7938 25.9557 17.553 25.5974 17.6249H21.2146C20.7813 17.553 20.4303 17.904 20.564 18.3593V34.5156C20.4303 34.9268 20.7813 35.2793 21.2146 35.2499H25.5974C26.0306 35.2793 26.3817 34.9268 26.3817 34.4935L26.3802 24.7293C26.3787 24.7249 26.3626 24.2578 26.6578 23.9362C26.8943 23.6806 27.3011 23.5499 27.8636 23.5499C28.8007 23.5499 29.2178 23.9332 29.3735 24.791V34.4935C29.2193 34.9268 29.5703 35.2793 30.0036 35.2499H34.6228C35.0561 35.2793 35.4071 34.9268 35.2515 34.4935V24.4649C35.4057 19.3654 31.7735 17.5544 28.6626 17.5544ZM33.837 33.7812H30.7879V24.791C30.7879 23.0564 29.6673 21.9813 27.8651 21.9813C26.8458 21.9813 26.0527 22.2809 25.5063 22.8713C24.747 23.6938 24.8057 24.7366 24.9687 24.9687V33.7812H21.9989V19.1231H24.813V19.6166C24.8119 19.7636 24.8522 19.9079 24.9295 20.033C25.0067 20.1582 25.1176 20.2589 25.2495 20.3238C25.3815 20.3887 25.529 20.4151 25.6752 20.3999C25.8215 20.3846 25.9604 20.3285 26.0762 20.2379L26.2818 20.0792C26.8766 19.6107 27.492 19.1245 28.6641 19.1245C29.8773 19.1245 33.8385 19.5108 33.8385 24.4664V33.7812H33.837Z" />
        <path d="M16.1564 10.2312C14.5084 10.2312 13.1689 11.5707 13.1689 13.2186C13.1689 14.8666 14.5084 16.2061 16.1564 16.2061C17.8043 16.2061 19.1438 14.8666 19.1438 13.2186C19.1438 11.5707 17.8043 10.2312 16.1564 10.2312ZM16.1564 14.6375C15.375 14.6375 14.7376 14 14.7376 13.2186C14.7376 12.4373 15.375 11.7998 16.1564 11.7998C16.9378 11.7998 17.5752 12.4373 17.5752 13.2186C17.5752 14 16.9378 14.6375 16.1564 14.6375Z" />
        <path d="M18.2536 17.553H13.8943C13.461 17.553 13.11 17.904 13.2187 18.3593V34.5156C13.11 34.9268 13.461 35.2793 13.8943 35.25H18.2536C18.6868 35.2793 19.0379 34.9268 19.0379 34.4935V18.3373C19.0379 18.1293 18.9552 17.9298 18.8081 17.7827C18.6611 17.6356 18.4616 17.553 18.2536 17.553ZM17.6249 33.7812H14.6786V19.1231H17.6249V33.7812Z" />
        <path d="M23.5001 -0.0500488C10.5134 -0.0500488 -0.0498047 10.5132 -0.0498047 23.4999C-0.0498047 36.4866 10.5134 47.0498 23.5001 47.0498C36.4868 47.0498 47.0501 36.4866 47.0501 23.4999C47.0501 10.5132 36.4868 -0.0500488 23.5001 -0.0500488ZM23.5001 45.4812C11.38 45.4812 1.51882 35.62 1.51882 23.4999C1.51882 11.3798 11.38 1.51858 23.5001 1.51858C35.6203 1.51858 45.4814 11.3798 45.4814 23.4999C45.4814 35.62 35.6203 45.4812 23.5001 45.4812Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="47" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
