import { createGlobalStyle } from "styled-components";

const BaseStyles = createGlobalStyle`
    :root{
            --background:#F5F5FB;
            --text: #000;
            --primary: #BE6F72;
            --secondary:#F1ECE0;
        }

  html {
    width: 100%;
  }

  *,
  *:before,
  *:after {
    font-family:'montserrat-regular';
    box-sizing: border-box;
  }

  body {
    font-family:'montserrat-regular';
    margin: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background-color: var(--background);
    color: var(--text);
  }

  body, h1, h2, h3, h4, h5, h6, hr, p, blockquote,dl, dt, dd, ul, ol, li,pre,fieldset,legend, button, input, textarea, th, td {
        margin: 0;
        padding: 0;
    };
    ul, ol { 
        list-style: none; 
    }
    a {
      color:vaR(--text);
        text-decoration: none;  
        &:hover{
          color:var(--primary);
        }
    }
    input{
        /* -webkit-appearance: none; */
        border: 0;
        outline: 0;
        background: transparent;
        color: inherit;
    }
    ::-webkit-input-placeholder {
        color: inherit;
        opacity: 0.4;
    }

    ::placeholder {
        color: inherit;
        opacity: 0.4;
    }
    textarea{ 
        -webkit-appearance: none;
        border: 0;
        outline: 0;
        background: transparent;
        color: inherit;
    }
    ::-webkit-textarea-placeholder {
        color: inherit;
        opacity: 0.4;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,  
    input:-webkit-autofill:hover, 
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--text);
    transition: background-color 5000s ease-in-out 0s;
}

.thermometer .thermometer__bg-color {
  background: linear-gradient(#f44336, #8bc34a, #36f4eb);
}

.thermometer .thermometer__draw-b:after {
  background-color: #36f4eb;
}
.thermometer .thermometer__draw-b:before {
  background-color: #36f4eb;
}



`;

export default BaseStyles;
