import React from "react";

import BaseStyles from "./base";
import GlobalFonts from "./fonts";

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <GlobalFonts />
  </>
);

export default GlobalStyles;
